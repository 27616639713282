import styled from 'styled-components';
import { IonContent } from '@ionic/react';
import { SPACE } from '$gstyles';

export const Wrapper = styled(IonContent)`
  .footer-content {
    border-top: 1px solid var(--ion-border-color);
    padding: ${SPACE.MEDIUM};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.85em;
  }
`;
