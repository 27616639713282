import UserModel from '$gbusiness/models/user';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const CLEAN_USER = 'CLEAN_USER';
export const RESET_FINISHED = 'USER_RESET_FINISHED';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';

// Action Types
export interface FetchUserSuccessType {
  type: typeof FETCH_USER_SUCCESS;
  user: UserModel | null;
}

export interface FetchUsersSuccessType {
  type: typeof FETCH_USERS_SUCCESS;
  users: Array<UserModel>;
}

export interface UserFailureType {
  type: typeof USER_FAILURE;
  err: string;
}

export interface SaveUserSuccessType {
  type: typeof SAVE_USER_SUCCESS;
  user: UserModel;
}

export interface CleanUserType {
  type: typeof CLEAN_USER | typeof RESET_FINISHED;
}

export interface UserReducerType {
  user: UserModel | null;
  users: Array<UserModel>;
  isFinished: boolean;
}

export type UserActionTypes =
  | FetchUserSuccessType
  | FetchUsersSuccessType
  | UserFailureType
  | SaveUserSuccessType
  | CleanUserType;

export const USER_INIT_STATE: UserReducerType = {
  user: null,
  users: [],
  isFinished: false,
};
