import styled from 'styled-components';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';
import { SPACE, WEIGHT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const IonContentWrapper = styled(IonContent)`
  .item-container {
    flex-direction: column;
    align-items: center;
    padding-top: ${SPACE.LARGE};
    height: 100%;

    .image-section {
      /* height: ${(p) => p.imageSize || 280}px; */
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
      .main-image {
        margin: 0 ${SPACE.LARGE};
        /* box-shadow: 2px 2px 4px #666; */
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        margin-right: -10px;
        min-width: ${(p) => p.thumbSize + 20 + 'px' || '64px'};
        height: ${(p) => p.imageSize || '280px'};
        padding-left: ${SPACE.MEDIUM};
        overflow: auto;
      }
      .thumb {
        margin-bottom: 5px;
        border: 5px solid transparent;
        box-shadow: 1px 1px 2px #999;
        box-sizing: border-box;
      }
      .thumb.selected {
        border-color: var(--ion-color-);
        border-width: 5px;
      }
    }

    .content-section {
      font-size: 16px;
      width: 100%;
      min-height: 110px;
      max-height: 240px;
      padding: ${SPACE.LARGE} ${SPACE.XLARGE};
      .item-name {
        font-size: 1.4em;
        font-weight: ${WEIGHT.SEMI_BOLD};
        text-transform: uppercase;
        margin-bottom: ${SPACE.MEDIUM};
        text-align: center;
      }
      .description {
        margin-bottom: ${SPACE.MEDIUM};
        align-items: flex-start;
        .value {
          margin-bottom: ${SPACE.MEDIUM};
        }
      }
      .sku,
      .skid,
      .packing,
      .lotNo,
      .binNo {
        display: inline-block;
        margin-right: ${SPACE.LARGE};
        text-transform: uppercase;
        font-weight: ${WEIGHT.SEMI_BOLD};
        > span {
          opacity: 0.8em;
          font-weight: ${WEIGHT.NORMAL};
        }
      }
      .quantity {
        margin: ${SPACE.LARGE} 0;
        justify-content: flex-start;
        .label {
          margin-right: ${SPACE.LARGE};
        }
      }
      .price-section {
        display: flex;
        align-items: center;
        .price {
          font-size: 36px;
          font-weight: ${WEIGHT.BOLD};
          margin-right: ${SPACE.LARGE};
        }
        .tag {
        }
      }
    }
  }

  ${mobileStyle(`
    .content-section {
      padding: ${SPACE.LARGE};
    }
  `)}
`;

export const IonFooterWrapper = styled(Footer)``;
