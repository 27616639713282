import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface OrderSummaryProps {
  price: any;
  isHidden?: boolean;
  includeTitle?: boolean;
  showDetails?: boolean;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  price,
  showDetails = false,
  isHidden = false,
  includeTitle,
}) => {
  if (isHidden) return null;
  const {
    subtotal,
    itemDiscount,
    totalDiscount,
    discount: passedDiscount,
    itemTaxable,
    total,
    tax,
    delivery,
    shipping,
    taxable,
    refund = null,
    refundAmt,
    tip,
    invoiceTotal,
  } = price;

  const discount = showDetails
    ? totalDiscount - itemDiscount || passedDiscount || 0
    : totalDiscount || passedDiscount || 0;
  return (
    <OrderSummaryWrapper>
      {includeTitle && <div className="title">{intl('SCREEN.ORDER.SUMMARY.TITLE')}</div>}
      <div className="box">
        <div className="line">
          <div className="label">{intl('SCREEN.ORDER.SUMMARY.SUBTOTAL')}</div>
          <div className="value">{currency(subtotal || 0)}</div>
        </div>
        {showDetails && itemDiscount > 0 && (
          <>
            <div className="line">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.ITEM_DISCOUNT')}</div>
              <div className="value success outlined">-{currency(itemDiscount || 0)}</div>
            </div>
            <div className="line">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.ITEM_TAXABLE')}</div>
              <div className="value">{currency(itemTaxable || 0)}</div>
            </div>
          </>
        )}
        {discount > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.DISCOUNT')}</div>
            <div className="value success outlined">-{currency(discount || 0)}</div>
          </div>
        )}
        {shipping > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.SHIPPING')}</div>
            <div className="value">{currency(shipping || 0)}</div>
          </div>
        )}
        {(discount > 0 || shipping > 0) && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TAXABLE')}</div>
            <div className="value">{currency(taxable || 0)}</div>
          </div>
        )}
        {delivery > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.DELIVERY')}</div>
            <div className="value">{currency(delivery || 0)}</div>
          </div>
        )}
        {tip > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TIP')}</div>
            <div className="value">{currency(tip || 0)}</div>
          </div>
        )}
        <div className="line">
          <div className="label">{intl('SCREEN.ORDER.SUMMARY.TAX')}</div>
          <div className="value">{currency(tax || 0)}</div>
        </div>
        {refundAmt > 0 ? (
          <>
            <div className="line">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND')}</div>
              <div className="value success outlined">-{currency(refundAmt || 0)}</div>
            </div>
            <div className="line total">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.TOTAL')}</div>
              <div className="value">{currency(total - refundAmt || 0)}</div>
            </div>
          </>
        ) : (
          <div className="line total">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TOTAL')}</div>
            <div className="value">{currency(invoiceTotal || total || 0)}</div>
          </div>
        )}
        {refund && (
          <div className="line" style={{ marginTop: '20px' }}>
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND')}</div>
            <div className="value danger outlined">{currency(refund.total || 0)}</div>
          </div>
        )}
      </div>
    </OrderSummaryWrapper>
  );
};

export default OrderSummary;
