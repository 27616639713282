import styled from 'styled-components';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { desktopStyle, mobileStyle } from '$gstyles/utils';

export const Wrapper = styled(Div)`
  .MuiTab-root {
    background-color: #f6f6f6;
  }

  .MuiTableRow-head {
    height: 54px;
    .MuiTableCell-head {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }

  .MuiTable-root {
    .MuiSelect-select {
      padding-top: ${SPACE.MEDIUM};
      padding-bottom: ${SPACE.MEDIUM};
      font-size: ${FONT.SMALL};
    }
  }
`;

export const FilterWrapper = styled(Div)`
  max-width: 1000px;
  display: block;

  .report-type-input {
    .MuiInputBase-root {
      padding: 12px 14px;
    }
  }
  .submit-button {
    min-width: 32px;
    margin: auto;
    position: relative;
    top: ${SPACE.MEDIUM};
    padding: 12px ${SPACE.MEDIUM} !important;
    .MuiButton-label {
      font-size: ${FONT.SMALL};
    }
    ${mobileStyle(` min-width: 100px; `)}
  }
  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }

  ${desktopStyle(`
    .submit-button {
      margin-left: 0;
    }
  `)}
`;
