import { Dispatch } from 'redux';

import UserModel, { defaultUser, deriveRawToUser } from '$gbusiness/models/user';
import { SET_CUSER, CLEAN_CUSER, CuserActionTypes, CUSTOMER_FAILURE } from './types';
import { LOADING } from '../loading/types';
import { fetchApi } from '$gbusiness/services/api';
import { configs } from '$configs';
import { toastDanger, toastSuccess } from '../toaster/actions';

export function handleFail(dispatch, err, key, toastStyle = '') {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: CUSTOMER_FAILURE,
    err: errorKey,
  });

  if (toastStyle) {
    dispatch(toastDanger({ text: err, key: errorKey, className: toastStyle }));
  }
}

export function setCuser(user: UserModel): CuserActionTypes {
  return {
    type: SET_CUSER,
    user,
  };
}

export function editProfile(param): any {
  return async (dispatch: Dispatch) => {
    const { userId } = param;
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SAVING',
    });

    const response = await fetchApi({
      url: configs.api.users.general + (userId ? '/' + userId : ''),
      param,
      method: userId ? 'PUT' : 'POST',
    });

    if (!response?.success) {
      handleFail(dispatch, response?.message, 'MESSAGE.SAVE_FAIL', 'medium');
      return;
    }

    const user = deriveRawToUser(response.user) || defaultUser;
    dispatch({
      type: SET_CUSER,
      user,
    });
    dispatch(toastSuccess({ text: 'MESSAGE.SAVE_SUCCESS', className: 'small' }));
  };
}

export function cleanUser(): CuserActionTypes {
  return {
    type: CLEAN_CUSER,
  };
}
