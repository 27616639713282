import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import { deriveRawToCredit } from '$fbusiness/models/credit';
import { format } from '$gbusiness/helpers/date';

export const CREDIT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.store.credit,
    deriveToModel: deriveRawToCredit,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 10,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 800,
    maxWidth: 1200,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
    setRowClass: (row) => (row.amount < 'DISCOUNT' ? 'negative-row' : ''),
  },
};

export const CREDIT_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.CREDIT.COLS.DATE',
    value: 'creditDate',
    component: (row) => <>{format(row.creditDate, DATE_FORMATS.SLASH) || ''}</>,
    width: 200,
  },
  {
    label: 'SCREEN.CREDIT.COLS.AMOUNT',
    value: 'amount',
    align: 'center',
    component: (row) => (
      <span className={`price semi-bold outlined ${row.amount < 0 ? 'danger' : 'success'}`}>
        {currency(row.amount)}
      </span>
    ),
    alwaysShow: true,
    width: 180,
  },
  {
    label: 'SCREEN.CREDIT.COLS.BALANCE',
    value: 'balance',
    align: 'center',
    component: (row) => <>{currency(row.balance + row.amount)}</>,
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.CREDIT.COLS.MEMO',
    value: 'memo',
    alwaysShow: true,
    width: 400,
  },
];
