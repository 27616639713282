import FileModel from '../../models/file';

export const FILE_FAILURE = 'FILE_FAILURE';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const RESET_FINISHED = 'FILE_RESET_FINISHED';
export const CLEAN_FILE = 'CLEAN_FILE';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_FILE_SUCCESS
    | typeof UPDATE_FILE_SUCCESS
    | typeof CLEAN_FILE
    | typeof RESET_FINISHED
    | typeof DELETE_FILE_SUCCESS;
}

export interface failureType {
  type: typeof FILE_FAILURE;
  err: string;
}

export interface FetchFileSuccessType {
  type: typeof FETCH_FILE_SUCCESS;
  file: FileModel;
}

export interface FileReducerType {
  file: FileModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type FileActionTypes = typeOnlyType | failureType | FetchFileSuccessType;

export const FILE_INIT_STATE: FileReducerType = {
  file: null,
  isFinished: false,
  refresh: true,
};
