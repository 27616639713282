import React from 'react';
import CategoryModel from '$fbusiness/models/category';
import Img from '$gcomponents/primitives/img';
import { SubcategoryWrapper } from './styles';
import { Link } from '$gcomponents/primitives';
import PATH from '$business/enums/paths';

interface SubcategoryProps {
  category: CategoryModel;
  height?: number;
}

const Subcategory: React.FC<SubcategoryProps> = ({ category, height = 90 }) => {
  const { name, image, id } = category;
  return (
    <SubcategoryWrapper height={height}>
      <Link route={`${PATH.MENU}/${id}`} className="cat-link">
        <div className="image-section">
          <Img
            src={image?.sizes?.small || ''}
            borderRadius="50%"
            overflow="hidden"
            showShadow
            placeholder="No Image"
            width={height + 'px'}
            height={height + 'px'}
          />
        </div>
        <div className="text-section">{name}</div>
      </Link>
    </SubcategoryWrapper>
  );
};

export default Subcategory;
