import { Dispatch } from 'redux';
import { setStorageObj } from '../../services/local.storage';

export function setObj(key, value, actionName = 'OBJ_ITEM'): any {
  return async (dispatch: Dispatch, getState) => {
    await setStorageObj(key, value, actionName);
  };
}

export function updateColumn(key, value): any {
  return async (dispatch: Dispatch, getState) => {
    const columns = getState().localStorage.columns;
    const newColumns = {
      ...columns,
      [key]: value,
    };
    setStorageObj('columns', newColumns, 'UPDATE_COLUMN');
  };
}

export function updatePageSize(key, value): any {
  return async (dispatch: Dispatch, getState) => {
    const pageSize = getState().localStorage.pageSize;
    const newPageSize = {
      ...pageSize,
      [key]: value,
    };
    setStorageObj('pageSize', newPageSize, 'UPDATE_COLUMN');
  };
}
