import React, { ReactNode, useEffect, useState } from 'react';

import CellModel from '$gbusiness/models/cell';
import { TableModel } from '$gbusiness/models/table';
import { ApiTable, Table, TabSimple } from '$gcomponents/reusables';
import { Table2Wrapper } from './styles';
import OptionModel from '$gbusiness/models/option';
import { Button, TabsProps } from '@mui/material';
import { PopoverWrapper } from '$gcomponents/reusables/popOver/styles';
import ItemActionModel from '$gbusiness/models/itemAction';
import { convertToItemAction } from '$gbusiness/helpers/table';
import intl from '$gintl';
import { SORT } from '$gbusiness/enums';
import { sortArray } from '$gbusiness/helpers/util';
import { Settings } from '@mui/icons-material';
import { table } from '$gbusiness/helpers';

import ColumnModal from './columnModal';

interface TableViewProps {
  TABLE: Array<CellModel>;
  tableConfig: TableModel;
  INNER_TABLE?: Array<CellModel>;
  header?: ReactNode;
  children?: ReactNode;
  data?: Array<any>;
  onDrag?: Function;
  filter?: any;
  pageSize?: number;
  columns?: Array<any>;
  columnKey?: string;
  onSelection?: Function;
  itemActions?: Array<ItemActionModel>;
  onTableLoad?: Function;
  className?: string;
  tabs?: {
    TABS: Array<OptionModel>;
    index: number;
    onChange: Function;
    hasBadge?: boolean;
    color?: TabsProps['indicatorColor'];
    itemWidth?: string;
    height?: string;
    variant?: string;
    className?: string;
    fullWidth?: boolean;
  };
}

const TableView2: React.FC<TableViewProps> = ({
  TABLE,
  INNER_TABLE,
  data,
  className,
  tableConfig,
  header,
  onDrag,
  children,
  filter,
  pageSize,
  onTableLoad,
  itemActions,
  tabs,
  columns,
  columnKey,
  onSelection,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [row, setRow] = useState<any>(null);
  const [fixedData, setFixedData] = useState<Array<any>>(data || []);
  const [sorting, setSorting] = useState(['', '']);
  const [showColumnModal, setShowColumnModal] = useState(false);

  useEffect(() => {
    if (!sorting[0] || !sorting[1]) {
      setFixedData(data || []);
      return;
    }
    setFixedData(sortArray(data, sorting[0], sorting[1]));
  }, [data, sorting]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, row) => {
    setRow(row);
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setRow(null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };
  const onChangeSort = (key, order) => {
    setSorting([key, sorting[1] === SORT.ASC ? SORT.DSC : SORT.ASC]);
  };
  const open = Boolean(anchorEl);
  const newTable = columns ? table.getActiveColumnTable(TABLE, columns) : TABLE;

  const adjustedTableConfig = pageSize
    ? {
        ...tableConfig,
        dataSource: {
          ...tableConfig.dataSource,
          pageSize,
        },
      }
    : tableConfig;

  return (
    <Table2Wrapper className={className || ''} maxWidth={adjustedTableConfig.styles?.maxWidth}>
      <div className="children">{!!children && children}</div>
      {!!tabs && (
        <TabSimple
          TABS={tabs?.TABS}
          index={tabs?.index}
          onChangeIndex={(newIndex) => tabs.onChange(newIndex)}
          color={tabs.color || 'primary'}
          itemWidth={tabs.itemWidth || '100px'}
          hasBadge={tabs.hasBadge}
          height={tabs.height || '40px'}
          variant={tabs.variant || 'filled'}
          className={tabs.className || 'table-tabs'}
          fullWidth={tabs.fullWidth}
        />
      )}
      <div className="main-table-wrapper">
        {header && header}
        {((columns && columnKey) || pageSize) && (
          <>
            <span className="col-settings">
              <Settings className="pointer" onClick={() => setShowColumnModal(true)} />
            </span>
            <ColumnModal
              pageSize={pageSize || 0}
              columns={columns || []}
              columnKey={columnKey || ''}
              TABLE={TABLE}
              show={showColumnModal}
              onClose={() => setShowColumnModal(false)}
            />
          </>
        )}
        {!data ? (
          <ApiTable
            TABLE={newTable}
            dataSource={adjustedTableConfig.dataSource}
            styles={tableConfig.styles}
            filters={filter}
            onDrag={onDrag}
            INNER_TABLE={INNER_TABLE}
            innerStyles={tableConfig.innerConfig?.styles}
            columns={columns}
            onSelection={onSelection}
            onTableLoad={onTableLoad}
            resetSelection={filter?.resetSelection}
            itemActions={convertToItemAction(itemActions, handlePopoverOpen)}
          />
        ) : (
          <Table
            TABLE={newTable}
            styles={tableConfig.styles}
            onSelection={onSelection}
            onDrag={onDrag}
            columns={columns}
            innerKey={tableConfig.dataSource?.innerKey || ''}
            INNER_TABLE={INNER_TABLE}
            innerStyles={tableConfig.innerConfig?.styles}
            data={fixedData}
            onChangeSort={onChangeSort}
            resetSelection={filter?.resetSelection}
            itemActions={convertToItemAction(itemActions, handlePopoverOpen)}
          />
        )}
      </div>

      {itemActions && (
        <PopoverWrapper
          className={'backdrop'}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}>
          <div className="pop-container">
            {itemActions.map((a, i) => {
              if (a.hide && row && a.hide(row)) return null;
              return (
                <Button
                  key={i}
                  onClick={(e) => {
                    a.onClick(row);
                    handlePopoverClose(e);
                  }}>
                  {a.labelText || intl(a.label)}
                </Button>
              );
            })}
          </div>
        </PopoverWrapper>
      )}
    </Table2Wrapper>
  );
};

export default TableView2;
