import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import DATE_RANGE from '$fbusiness/enums/options/dateRange';
import { util } from '$fbusiness/helpers';
import { searchSharp } from 'ionicons/icons';
import { addMonths } from 'date-fns';
import REPORT_TYPE_OPTIONS from '$fbusiness/enums/options/reportType';

export const DATE_FILTER_ITEMS = [
  {
    label: 'INPUT.LABEL.DATE_RANGE',
    input: INPUT.SELECT,
    options: DATE_RANGE,
    gridSize: 2,
    value: 'datePeriod',
    onChange: ({ value, setFieldValue }) => {
      const [startDate, endDate] = util.getDateRangeFromPeriod(value);
      setFieldValue('dateStart', startDate);
      setFieldValue('dateEnd', endDate);
    },
  },
  {
    label: 'INPUT.LABEL.START_DATE',
    value: 'dateStart',
    input: INPUT.DATE,
    maxDate: addMonths(new Date(), 1),
    onChange: ({ value, setFieldValue }) => {
      setFieldValue('dateEnd', value);
    },
    gridSize: 3,
  },
  {
    label: 'INPUT.LABEL.END_DATE',
    value: 'dateEnd',
    input: INPUT.DATE,
    maxDate: addMonths(new Date(), 1),
    gridSize: 3,
  },
];

export const FILTER_FORM = ({
  placeholder,
  excludeDates,
  onChangeStore,
  stores,
  includeReportType = false,
  onChangeReportType,
}): Array<InputRowModel> => {
  const hasStores = stores.length > 0;
  return [
    {
      items: [
        ...(includeReportType
          ? [
              {
                label: 'SCREEN.REPORT.REPORT_TYPE',
                input: INPUT.RADIO,
                gridSize: 5,
                options: REPORT_TYPE_OPTIONS,
                className: 'report-type-input',
                value: 'reportType',
                onChange: ({ formValues, value }) => {
                  onChangeReportType({
                    ...formValues,
                    reportType: value,
                  });
                },
              },
              {
                label: '',
                input: INPUT.HIDDEN,
                gridSize: 7,
                value: 'nothing',
              },
            ]
          : []),
        ...(stores.length > 0
          ? [
              {
                input: INPUT.AUTOCOMPLETE,
                label: 'SCREEN.STORES.COLS.STORE',
                disableFastField: true,
                gridSize: excludeDates ? 4 : 3,
                value: 'store',
                config: {
                  initialList: stores.slice(0, 20),
                  optionProp: {
                    label: 'name',
                    value: 'id',
                  },
                  data: stores,
                },
                onChange: ({ newValue }) => {
                  if (onChangeStore) onChangeStore(newValue);
                },
              },
            ]
          : []),
        {
          label: 'INPUT.LABEL.SEARCH',
          placeholder,
          input: INPUT.TEXT,
          gridSize: hasStores && !excludeDates ? 2 : !hasStores && excludeDates ? 5 : 3,
          icon: searchSharp,
          enterToSubmit: true,
          value: 'query',
        },
        ...(!excludeDates
          ? DATE_FILTER_ITEMS.map((m, i) => {
              if (i > 0)
                return {
                  ...m,
                  gridSize: hasStores ? 2 : 3,
                };
              return m;
            })
          : []),
      ],
    },
  ];
};

export const DATE_FILTER_FORM = (): Array<InputRowModel> => [
  {
    items: [...DATE_FILTER_ITEMS],
  },
];
