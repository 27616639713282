import React from 'react';
import { GetApp } from '@mui/icons-material';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToFile } from '$fbusiness/models/file';
import { toFileSize } from '$gbusiness/helpers/util';

export const FILE_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.file.general,
    method: 'GET',
    deriveToModel: deriveRawToFile,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 900,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const FILE_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.FILES.COLS.ID',
    value: 'id',
    className: 'desktop',
    align: 'center',
    alwaysShow: true,
    width: 80,
  },
  {
    label: 'SCREEN.FILES.COLS.NAME',
    value: 'name',
    className: 'metadata',
    component: (row) => (
      <div>
        <div className="title">{row.name}</div>
        <div className="memo">{row.description}</div>
      </div>
    ),
    alwaysShow: true,
    width: 250,
  },
  {
    label: 'SCREEN.FILES.COLS.FILE_NAME',
    value: 'fileName',
    component: (row) =>
      row.fileName ? <>{row.fileName}</> : <a href={row.youtubeLink}>{row.youtubeLink}</a>,
    className: 'word-break',
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.FILES.COLS.FILE_TYPE',
    value: 'fileType',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.FILES.COLS.FILE_SIZE',
    value: 'fileSize',
    className: 'action',
    sortable: SORT.ASC,
    component: (row) => <>{toFileSize(row.fileSize)}</>,
    alwaysShow: true,
    width: 120,
  },
  {
    label: '',
    value: 'download',
    className: 'action',
    component: (row) => (
      <a href={row.fileName ? row.fileUrl : row.youtubeLink} download>
        <GetApp color="secondary" />
      </a>
    ),
    alwaysShow: true,
    width: 80,
  },
  // {
  //   label: 'SCREEN.FILES.COLS.ACTION',
  //   value: '',
  //   className: 'action',
  //   align: 'center',
  //   component: (row, actions) => {
  //     return (
  //       <Button className="left" fullWidth onClick={e => actions.handlePopoverOpen(e, row)}>
  //         <MoreHoriz />
  //       </Button>
  //     );
  //   },
  //   width: 80,
  // },
];

export default FILE_TABLE;
