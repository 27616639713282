import LEVEL_OPTIONS from '$fbusiness/enums/options/userLevel';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { requiredValidation, validateEmail, validatePassword } from '$gbusiness/helpers/input';

export const USER_FORM = (isMe): Array<InputRowModel> => [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        validation: [requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 6,
        disabled: isMe,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        gridSize: 6,
        validation: [
          {
            condition: (v) => !validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
      },
      {
        input: INPUT.SELECT,
        label: 'INPUT.LABEL.USER_LEVEL',
        value: 'userLevel',
        disabled: isMe,
        options: LEVEL_OPTIONS.map((l, i) => ({ label: l.label, value: i + 1 })),
        gridSize: 6,
      },
    ],
  },
];

export default USER_FORM;
