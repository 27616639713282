import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { Header, TableView2 } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import { dailyReportActions } from '$fbusiness/redux/dailyReport';
import { dateToString, format, today } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import intl from '$gintl';
import { dialog } from '$gcomponents/reusables';
import { DAILY_REPORT_ITEM_TABLE, DAILY_REPORT_ITEM_TABLE_CONFIG } from './dailyReportItemTable';
import DailyReportItemModal from './dailyReportItemModal';
import { PageWrapper } from '$gstyles/wrapper';
import { Print } from '@mui/icons-material';
import { initialDailyReportItem } from '$fbusiness/models/dailyReportItem';
import DateFilter from './dateFilter';
import { parseISO } from 'date-fns';
import { updatedFilter } from '$fbusiness/helpers/util';
import FabButton from '$fcomponents/fabButton';
import { toNumber } from '$gbusiness/helpers/util';

interface DailyReportScreenProps {
  match;
  history;
  reset: Function;
  isFactory: boolean;
  isFinished: boolean;
  onHydrate;
  onDehydrate;
  currentState;
  stores: Array<any>;
  regions: Array<any>;
  saveItem: Function;
  deleteItem: Function;
}

const DailyReportScreen: React.FC<DailyReportScreenProps> = ({
  match,
  reset,
  history,
  isFactory,
  isFinished,
  saveItem,
  stores,
  regions,
  currentState,
  deleteItem,
}) => {
  const {
    params: { reportDate = today() },
  } = match;
  const { store } = currentState;
  const [filter, setFilter] = useState<any>({ forceRefresh: true, reportDate });
  const [item, setItem] = useState<any>(null);
  const [selections, setSelections] = useState<any>([]);
  const [dateInput, setDateInput] = useState(parseISO(reportDate));

  useEffect(() => {
    if (!isFinished) return;
    setItem(null);
    reset();
    updateFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const onEditItem = (row) => {
    setItem(row);
  };

  const updateFilter = () => {
    setFilter(updatedFilter(filter));
  };

  const onPrint = () => {
    window.print();
  };

  const onDeleteItem = (row) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: async () => {
        await deleteItem(row.id);
        updateFilter();
      },
    });
  };

  const onSaveItem = async (values) => {
    const { author, customer, regionName, settings, ...param } = values;
    await saveItem({
      ...param,
      total: toNumber(values.checkAmount) + toNumber(values.cashAmount),
      reportDate,
    });
  };

  const onChangeDate = (value) => {
    setDateInput(value);
    const newDate = dateToString(value);
    history.replace('/dailyreport/' + newDate);
    setFilter({ ...filter, reportDate: newDate });
  };

  const handleSelections = (arr) => {
    setSelections(arr.reduce((acc, a) => (a ? [...acc, a.id] : acc), []));
  };

  const titleText = intl('SCREEN.DAILY_REPORT.TITLE_DATE', {
    date: format(reportDate || today(), DATE_FORMATS.SLASH),
  });

  return (
    <IonPageWrapper>
      <Header titleText={titleText} />
      <IonContent>
        <PageWrapper>
          <DateFilter value={dateInput} onChange={onChangeDate} />
          <TableView2
            filter={filter}
            onSelection={isFactory ? handleSelections : undefined}
            TABLE={DAILY_REPORT_ITEM_TABLE({ onEditItem, onDeleteItem, isFactory })}
            tableConfig={DAILY_REPORT_ITEM_TABLE_CONFIG(reportDate)}
          />
        </PageWrapper>
        {selections.length > 0 && <FabButton onClick={onPrint} icon={<Print />} />}
        {!isFactory && <FabButton onClick={() => onEditItem(initialDailyReportItem)} />}
      </IonContent>
      <DailyReportItemModal
        show={!!item}
        onSaveItem={onSaveItem}
        item={item}
        regionOptions={regions}
        storeOptions={stores}
        store={store}
        onClose={() => setItem(null)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  regions: state.factory.factory.regions.map((s) => ({ labelText: s.name, value: s.id })),
  stores: state.store.stores.map((s) => ({ labelText: s.name, value: s.id, count: s.regionId })),
  isFinished: state.dailyReport.isFinished,
});

const mapDispatchToProps = {
  saveItem: dailyReportActions.saveDailyReport,
  deleteItem: dailyReportActions.deleteDailyReport,
  reset: dailyReportActions.resetFinished,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DailyReportScreen));
