import React from 'react';
import intl from '$intl';
import OrderModel from '$fbusiness/models/order';
import { Flex } from '$gstyles/wrapper';
import { Col, Div, Row, SPACE } from '$gstyles';
import FactoryModel from '$fbusiness/models/factory';
import { joinAddress, parseAddress } from '$gbusiness/helpers/util';
import { ORDER_DETAILS_TABLE, ORDER_DETAILS_TABLE_CONFIG } from './orderDetailsTable';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { Table } from '$gcomponents/reusables';

import OrderSummary from '../orderSummary';
import { Wrapper } from './styles';
import InvoiceModel, { calculatePriceFromInvoice } from '$fbusiness/models/invoice';
import { KEYS } from '$fbusiness/enums/options/orderStatus';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoicePaymentSummary from '../orderSummary/invoicePaymentSummary';

interface OrderDetailsProps {
  factory: FactoryModel;
  invoice?: InvoiceModel;
  currentState: CurrentStateModel;
  order?: OrderModel;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ currentState, order, invoice, factory }) => {
  // if (!order) return null;
  // const { orderId, storeName, deliveryAddress, note, status, deliveryDate } = order;

  const store = invoice?.store;
  const items = invoice ? invoice.items : order?.items || [];
  const { name: factoryName, formatted, phone: fromPhone, settings } = factory;
  const { hidePrice } = currentState;
  const fromAddress = parseAddress(formatted);
  const toAddress = parseAddress(order?.deliveryAddress) || store?.address;
  const price = calculatePriceFromInvoice(invoice || order);

  const isSalesman = !!settings?.salesmen && order?.userName;
  const invoicePayment = invoice
    ? {
        total: invoice.total,
        paidAmount: invoice.paidAmount,
        balance: invoice.total - invoice.paidAmount,
      }
    : null;

  const isInvoice = !!invoice;
  const isFlagged = order?.status === KEYS.FLAGGED;
  const isRegenerated = !!invoice?.revision;

  const showVendor = factory.settings?.showVendor || false;

  const itemsTable = ORDER_DETAILS_TABLE({ isInvoice, isFlagged, isRegenerated, hidePrice, showVendor });
  const tableProps = {
    styles: ORDER_DETAILS_TABLE_CONFIG.styles,
    TABLE: itemsTable,
    data: items,
  };

  const isPickup = factory.settings?.pickupOnly ? true : false;

  return (
    <Wrapper>
      <div className="invoice-mode"></div>
      <Flex alignItems="flex-start" className="invoice-print" marginTop={SPACE.XLARGE}>
        <Col gridSize={7}>
          <Row className="row">
            <Col gridSize={6} className="fromto from col">
              <div className="title">{factoryName}</div>
              <div className="info">
                <div>{fromAddress.street}</div>
                <div>{joinAddress(fromAddress)}</div>
                <div>{fromPhone}</div>
              </div>
            </Col>
            <Col gridSize={6} className="fromto to">
              <div className="title">{intl('SCREEN.ORDER.SHIP_TO')}</div>
              <div className="info">
                <div>{order?.storeName || store?.name}</div>
                <div>{toAddress.street}</div>
                <div>{joinAddress(toAddress)}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col gridSize={5} className="order-section">
          <Div width="100%">
            {order && (
              <>
                <div className="line">
                  <div className="label">{intl('SCREEN.ORDER.ORDERNO')}</div>
                  <div className="value">{order.orderNumber}</div>
                </div>
                <div className="line">
                  <div className="label">{intl('SCREEN.ORDER.DATE')}</div>
                  <div className="value">{format(order.createdAt, DATE_FORMATS.SLASH)}</div>
                </div>
                <div className="line">
                  <div className="label">
                    {intl('SCREEN.ORDER.' + (isPickup ? 'PICKUP_DATE' : 'DELIVERY_DATE'))}
                  </div>
                  <div className="value">{format(order.deliveryDate, DATE_FORMATS.SLASH)}</div>
                </div>
              </>
            )}
            {invoice?.confirmedAt && (
              <div className="line">
                <div className="label">
                  {intl('SCREEN.ORDER.' + (isPickup ? 'PICKEDUP_DATE' : 'DELIVERED_DATE'))}
                </div>
                <div className="value">{format(invoice?.confirmedAt, DATE_FORMATS.SLASH)}</div>
              </div>
            )}
            {invoice?.term && (
              <div className="line">
                <div className="label">{intl('SCREEN.INVOICES.COLS.TERM')}</div>
                <div className="value">{invoice?.term?.name || ''}</div>
              </div>
            )}
            {isSalesman && (
              <div className="line">
                <div className="label">{intl('SCREEN.ORDER.SALESPERSON')}</div>
                <div className="value">{order?.userName}</div>
              </div>
            )}
          </Div>
        </Col>
      </Flex>

      <Div marginTop={SPACE.XLARGE}>
        <Table {...tableProps} />
      </Div>

      <Flex className="invoice-print">
        <div className="note-wrapper">
          <>{order && order?.note && !invoice?.noteFactory && <div>{order?.note}</div>}</>
          <>{invoice && invoice?.noteFactory && <div>{invoice?.noteFactory}</div>}</>
        </div>
        <div className="summary-wrapper">
          <OrderSummary price={invoice ? price : { ...price, refund: order?.refund }} isHidden={hidePrice} />
          {invoice && !hidePrice && <InvoicePaymentSummary invoicePayment={invoicePayment} />}
        </div>
      </Flex>
      {isFlagged && invoice?.noteStore && (
        <div className="flag-message no-print">
          <div>{intl('SCREEN.INVOICES.NOTE_FROM_STORE')}</div>
          <div className="error">{invoice?.noteStore}</div>
        </div>
      )}
    </Wrapper>
  );
};

export default OrderDetails;
