import styled from 'styled-components';
import { Flex } from '$gstyles/wrapper';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';

export const IonPageWrapper = styled(IonPage)`
  .row {
    > div {
      padding: ${SPACE.MEDIUM};
    }
  }
`;

export const MenuBoxWrapper = styled(Flex)`
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: ${SPACE.MEDIUM};
  height: 100px;
  cursor: pointer;

  .icon-section {
    width: 48px;
    height: 48px;
    .icon {
      width: 100%;
      height: 100%;
      opacity: 0.8;
      padding: ${SPACE.MEDIUM};
      background-color: #ddf5ff;
      border-radius: 999px;
    }
  }
  .text-section {
    padding: ${SPACE.MEDIUM};
    .title {
      font-weight: ${WEIGHT.BOLD};
      font-size: ${FONT.MEDIUM};
      padding: ${SPACE.XSMALL};
    }
    .subtitle {
      opacity: 0.7;
      padding: ${SPACE.XSMALL};
    }
  }
`;
