import React from 'react';
import intl from '$intl';

import { Wrapper } from './styles';
import { Link } from '$gcomponents/primitives';

interface ComponentProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  [x: string]: any;
}

const IonContentWrapper: React.FC<ComponentProps> = ({ children, footer, ...rest }) => {
  const defaultFooter = (
    <div className="footer-content">
      <Link route="tos">{intl('COMMON.TOS')}</Link>
    </div>
  );
  return (
    <Wrapper {...rest} className="">
      {children}
      {footer || defaultFooter}
    </Wrapper>
  );
};

export default IonContentWrapper;
