import React, { useRef, useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import intl from '$gintl';

import { useDropzone } from 'react-dropzone';

import { Button } from '$gcomponents/primitives';
import MuiModal from '../modal/material';
import { Wrapper, ImgWrapper, ImageWrapper } from './styles';
import { dialog } from '..';
import { randomInt } from '$gbusiness/helpers/util';

interface ImageInputProps {
  cache?: string;
  cacheField?: string;
  label?: string;
  formik?: any;
  name: string;
  height?: string;
  className?: string;
  width?: string;
  ratio?: boolean;
  size?: string;
  maxModalWidth?: string;
  optimizeImage?: boolean;
}

const ImageInput: React.FC<ImageInputProps> = ({
  cacheField,
  cache = '',
  label,
  formik = null,
  name,
  height,
  className = '',
  width,
  ratio,
  size = 'cover',
  maxModalWidth = '600px',
  optimizeImage = true,
}) => {
  const initialValue = formik.values[name];

  const imgRef = useRef(null);
  const [blob, setBlob] = useState<any>(null);
  const [inputMode, setInputMode] = useState(!initialValue);
  const [showModal, setShowModal] = useState(false);
  const [imageType, setImageType] = useState('image/jpeg');
  const [croppedImage, setCroppedImage] = useState<any>('');
  const [crop, setCrop] = useState<any>({
    ...(ratio && { aspect: ratio }),
    width: 100,
    height: 100,
    unit: '%',
    x: 0,
    y: 0,
  });

  const maxSize = 10000000;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop: (acceptedFiles: any) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.INVALID_IMAGE', { maxSize: maxSize / 1000000 + 'MB' }),
        });
        return;
      }
      setImageType(acceptedFiles[0].type);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const binaryStr = reader.result;
        setBlob(binaryStr);

        if (optimizeImage) {
          setShowModal(true);
        } else {
          if (formik) formik.setFieldValue(name, binaryStr);
          setInputMode(false);
        }
      };
    },
  });

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    if (!crop.width) crop.width = image.width;
    if (!crop.height) crop.height = image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    // As Base64 string
    const base64Image = canvas.toDataURL(imageType);
    return base64Image;
  };

  const onLoadImage = useCallback((img) => {
    setCrop({ ...crop, width: img.target.width || 100, height: img.target.height || 100, unit: 'px' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCrop = (newCrop) => {
    setCrop(newCrop);
  };

  const completeCrop = () => {
    setShowModal(false);
    const image = imgRef.current;
    const croppedImg = getCroppedImg(image, crop);
    setCroppedImage(croppedImg);
    setInputMode(false);
    if (formik) formik.setFieldValue(name, croppedImg);
  };

  const handleReplaceButton = () => {
    setInputMode(true);
  };
  const handleCancelButton = (e) => {
    e.stopPropagation();
    setInputMode(false);
  };

  const number = cacheField ? formik.values[cacheField] : cache || randomInt(0, 9999);
  const src = croppedImage || initialValue + '?' + number;

  return (
    <Wrapper height={height} width={width} className={className}>
      {!inputMode && (
        <div className="inputmode-wrapper">
          {croppedImage ? (
            <ImgWrapper className="thumbs-container img" src={src} size={size} />
          ) : (
            <ImageWrapper className="thumbs-container image" src={src} size={size} />
          )}
          <Button className="replace-button" size="small" color="info" onClick={handleReplaceButton}>
            {intl('INPUT.LABEL.IMAGE_REPLACE')}
          </Button>
        </div>
      )}
      {inputMode && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{intl(label || 'INPUT.LABEL.IMAGE')}</p>
          {src && (
            <Button className="replace-button" size="small" color="info" onClick={handleCancelButton}>
              {intl('BUTTON.CANCEL')}
            </Button>
          )}
        </div>
      )}
      {/* <Modal show={showModal} onClose={() => {}} footer={<Button onClick={completeCrop}>Complete</Button>}>
        <div className="crop-container">
          <ReactCrop
            src={blob}
            onImageLoaded={onLoadImage}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
          />
        </div>
      </Modal> */}
      <MuiModal
        open={showModal}
        footer={<Button onClick={completeCrop}>{intl('BUTTON.CONFIRM')}</Button>}
        title={intl('INPUT.LABEL.IMAGE_CROP_TITLE')}
        width={maxModalWidth}
        onClose={() => setShowModal(false)}>
        <div className="crop-container">
          <ReactCrop crop={crop} onChange={onChangeCrop}>
            <img ref={imgRef} src={blob} onLoad={onLoadImage} alt="" />
          </ReactCrop>
        </div>
      </MuiModal>
    </Wrapper>
  );
};

export default ImageInput;
