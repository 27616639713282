import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface InvoicePaymentSummaryProps {
  invoicePayment: any;
}

const InvoicePaymentSummary: React.FC<InvoicePaymentSummaryProps> = ({ invoicePayment }) => {
  const { balance, paidAmount } = invoicePayment;
  return (
    <OrderSummaryWrapper>
      <div className="box">
        {/* <div className="line">
          <div className="label">{intl('SCREEN.INVOICES.COLS.TOTAL')}</div>
          <div className="value">{currency(total || 0)}</div>
        </div> */}
        <div className="line">
          <div className="label">{intl('SCREEN.INVOICES.COLS.PAID_AMOUNT')}</div>
          <div className="value">-{currency(paidAmount || 0)}</div>
        </div>
        <div className="line total">
          <div className="label">{intl('SCREEN.INVOICES.COLS.BALANCE')}</div>
          <div className="value">{currency(balance || 0)}</div>
        </div>
      </div>
    </OrderSummaryWrapper>
  );
};

export default InvoicePaymentSummary;
