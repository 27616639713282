import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import { INPUT } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import Footer from '../footer';
import { PageWrapper } from '$gstyles/wrapper';
import { generateColsToFilter } from '$gbusiness/helpers/table';
import { useDispatch } from 'react-redux';
import { localStorageActions } from '$gbusiness/redux/localStorage';
import { configs, features } from '$configs';

interface ColumnModalProps {
  show: boolean;
  onClose: Function;
  TABLE: Array<CellModel>;
  columns: Array<any>;
  columnKey: string;
  pageSize?: number;
}
export const COLUMNS_FORM = (columns) => [
  {
    items: [
      ...(features.pageSize
        ? [
            {
              label: 'TABLE.FILTER.PAGE_SIZE',
              value: 'pageSize',
              input: INPUT.SELECT,
              native: true,
              options: [5, 10, 20, 50, 100, 200].map((o) => ({
                labelText: o,
                value: o,
              })),
              gridSize: 6,
            },
          ]
        : []),
      {
        label: 'COMMON.COLUMNS',
        value: 'columns',
        input: INPUT.MULTI_SELECT,
        isMultiline: true,
        options: columns.map((col) => ({
          labelText: intl(col.label),
          value: col.value,
        })),
        gridSize: 12,
      },
    ],
  },
];

const ColumnModal: React.FC<ColumnModalProps> = ({
  show,
  columnKey = 'general',
  columns,
  pageSize: originalPageSize = configs.display?.pageSize || 20,
  TABLE,
  onClose,
}) => {
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    await dispatch(localStorageActions.updateColumn(columnKey, values.columns));
    if (features.pageSize) {
      await dispatch(localStorageActions.updatePageSize(columnKey, parseInt(values.pageSize)));
    }
    onClose();
  };

  const pageSize = originalPageSize || configs.display?.pageSize || 20;

  const initialValues = {
    pageSize,
    columns,
  };

  return (
    <Modal title="TABLE.FILTER.COLUMN_FIELD" show={show} onClose={() => onClose()} useCustom>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <FormSection formik={formik} FORM={COLUMNS_FORM(generateColsToFilter(TABLE))} />
              </PageWrapper>
            </IonContent>
            <Footer justifyContent="center">
              <Button
                className="half"
                onClick={formik.handleSubmit}
                disabled={!(formik.isValid && formik.dirty)}>
                {intl('BUTTON.SUBMIT')}
              </Button>
            </Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ColumnModal;
