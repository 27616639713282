import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import { CART_TABLE, CART_TABLE_CONFIG } from './cartTable';
import { cartActions } from '$fbusiness/redux/cart';
import { dialog } from '$gcomponents/reusables';
import { sleep } from '$gbusiness/helpers/util';
import { useHistory } from 'react-router';
import { DEFAULT_APP_PATH } from '$business/enums/routes';
import { configs } from '$configs';

interface CartsScreenProps {
  onHydrate;
  onDehydrate;
  deleteCart: Function;
  loadSavedCart: Function;
  cartCount: Number;
  user;
}

const CartsScreen: React.FC<CartsScreenProps> = ({ cartCount, deleteCart, loadSavedCart }) => {
  const [filter, setFilter] = useState({ forceRefresh: true });
  const history = useHistory();
  const loadCart = async (row) => {
    await loadSavedCart(row.details);
    history.push(DEFAULT_APP_PATH);
  };
  const onApplyCart = async (row) => {
    if (!cartCount) {
      loadCart(row);
      return;
    }
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.CART_APPLY_WARNING',
      onPress: async () => {
        await loadCart(row);
      },
    });
  };

  const onDeleteCart = (id) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        await deleteCart(id);
        await sleep(100);
        updateFilter();
      },
    });
  };

  const updateFilter = () => {
    setFilter({
      forceRefresh: !filter.forceRefresh,
    });
  };

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.CART.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.CART.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <TableView2
          filter={filter}
          TABLE={CART_TABLE(onApplyCart, onDeleteCart)}
          tableConfig={CART_TABLE_CONFIG}
        />
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  cartCount: state.localStorage.cart?.products?.length || 0,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUser,
  onDehydrate: userActions.dehydrate,
  loadSavedCart: cartActions.loadSavedCart,
  deleteCart: cartActions.deleteCart,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CartsScreen));
