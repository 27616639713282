import {
  CLEAN_CART,
  CartActionTypes,
  CartReducerType,
  CART_INIT_STATE,
  FETCH_CARTS_SUCCESS,
  FETCH_CART_SUCCESS,
  UPDATE_CART_SUCCESS,
} from './types';

export default function cartReducer(
  state: CartReducerType = CART_INIT_STATE,
  action: CartActionTypes,
): CartReducerType {
  switch (action.type) {
    case FETCH_CART_SUCCESS:
      return {
        ...state,
        cart: action.cart,
      };
    case FETCH_CARTS_SUCCESS:
      return {
        ...state,
        carts: action.carts,
      };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case CLEAN_CART:
      return {
        ...state,
        isFinished: false,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
