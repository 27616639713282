import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import {
  ADD_INVOICE_PAYMENT,
  CLEAN_INVOICE,
  DELETE_INVOICE_PAYMENT,
  FETCH_INVOICE_PAYMENTS,
  FETCH_INVOICE_SUCCESS,
  invoiceActionTypes,
  INVOICE_FAILURE,
  UPDATE_INVOICE_PAYMENT,
  UPDATE_INVOICE_SUCCESS,
} from './types';
import { deriveRawToInvoice } from '../../models/invoice';
import { KEYS } from '../../enums/options/orderStatus';
import { deriveRawToinvoicePayment } from '../../models/invoicePayment';

export function fetchInvoice(id): any {
  return async (dispatch: Dispatch) => {
    if (!id) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.general + '/' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_INVOICE_SUCCESS,
      invoice: deriveRawToInvoice(response.data),
    });
  };
}

export function adjustItemQuantities(items): any {
  return async (dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.itemsUpdate,
      param: {
        items,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function updateInvoice(invoice): any {
  return async (dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.general + '/' + invoice.id,
      param: {
        ...invoice,
        ...(invoice.status && { status: invoice.status }),
        ...(invoice.statusFactory && { statusFactory: invoice.statusFactory }),
        ...(invoice.note && { note: invoice.note }),
        ...(invoice.noteFactory && { noteFactory: invoice.noteFactory }),
        ...(invoice.totalAdjusted && { totalAdjusted: invoice.totalAdjusted }),
        ...(invoice.detailedData && { detailedData: invoice.detailedData }),
      },
      method: 'PUT',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function createInvoice(param, finish = false): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.general,
      param,
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (finish) handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function closeInvoice(id, items, isFlagged, noteStore): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.close,
      param: {
        id,
        noteStore,
        items,
        status: isFlagged ? KEYS.FLAGGED : KEYS.CLOSED,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function editInvoicePayment(invoicePayment, index) {
  if (index < 0) {
    return {
      type: ADD_INVOICE_PAYMENT,
      invoicePayment,
    };
  }
  return {
    type: UPDATE_INVOICE_PAYMENT,
    invoicePayment,
    index,
  };
}

export function deleteInvoicePayment(index) {
  return {
    type: DELETE_INVOICE_PAYMENT,
    index,
  };
}

export function fetchInvoicePayments(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.payment + '?invoiceId=' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_INVOICE_PAYMENTS,
      invoicePayments: response.list.map(deriveRawToinvoicePayment),
    });
  };
}

export function submitInvoicePayments(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.submitPayment,
      method: 'POST',
      param,
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function applyRefund(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.applyRefund,
      method: 'POST',
      param: { id },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.REFUND_APPLIED', 'small');
    }
  };
}

export function dehydrate(): invoiceActionTypes {
  return { type: CLEAN_INVOICE };
}
