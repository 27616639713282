import React from 'react';
import { useHistory } from 'react-router';
import intl from '$intl';

import { MenuBoxWrapper } from './styles';

interface MenuBoxProps {
  title: string;
  subtitle: string;
  Icon: any;
  route: string;
}

const MenuBox: React.FC<MenuBoxProps> = ({ title, subtitle, route, Icon }) => {
  const history = useHistory();
  return (
    <MenuBoxWrapper onClick={() => history.push(route)}>
      <div className="icon-section">
        <Icon className="icon" />
      </div>
      <div className="text-section">
        <div className="title">{intl(title)}</div>
        <div className="subtitle">{intl(subtitle)}</div>
      </div>
    </MenuBoxWrapper>
  );
};

export default MenuBox;
