import React from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import VendorModel from '$fbusiness/models/vendor';

import { ModalWrapper } from './styles';
import { List } from '@mui/material';
import { formatAddress } from '$gbusiness/helpers/util';
import { InfoItem } from '$gcomponents/reusables';

interface VendorDetailsModalProps {
  vendor: VendorModel;
  show: boolean;
  onClose: Function;
}

const VendorDetailsModal: React.FC<VendorDetailsModalProps> = ({ vendor, show, onClose }) => {
  const renderVendor = (vendor) => (
    <>
      <IonContent>
        <PageWrapper>
          <List className="list">
            <InfoItem label="SCREEN.VENDORS.COLS.NAME" value={vendor.name} />
            <InfoItem label="SCREEN.VENDORS.COLS.CODE" value={vendor.code || ''} />
            <InfoItem
              label="SCREEN.VENDORS.COLS.ADDRESS"
              value={formatAddress(vendor.settings?.formatted || '')}
            />
            <InfoItem label="SCREEN.VENDORS.COLS.PHONE" value={vendor.settings.phone} />
            <InfoItem label="SCREEN.VENDORS.COLS.EMAIL" value={vendor.settings?.email} />
            <InfoItem label="SCREEN.VENDORS.COLS.SALES_REP" value={vendor.settings?.salesRep} />
            <InfoItem label="SCREEN.VENDORS.COLS.SALES_EMAIL" value={vendor.settings?.salesEmail} />
            <InfoItem label="SCREEN.VENDORS.COLS.NOTE" value={vendor.settings?.notes} />
          </List>
        </PageWrapper>
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button color="info" className="half" onClick={() => onClose()}>
            {intl('BUTTON.CLOSE')}
          </Button>
        </Flex>
      </IonFooter>
    </>
  );

  return (
    <ModalWrapper title="SCREEN.VENDORS.DETAILS" show={show} onClose={() => onClose()} useCustom>
      {vendor && renderVendor(vendor)}
    </ModalWrapper>
  );
};

export default VendorDetailsModal;
