import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { currency } from '$gbusiness/helpers/util';
import { deriveRawToDailyReportItem } from '$fbusiness/models/dailyReportItem';
import { Edit, Delete } from '@mui/icons-material';
import { format } from '$gbusiness/helpers/date';
import { Link } from '$gcomponents/primitives';
import { validateEmail, validatePhone } from '$gbusiness/helpers/input';

export const DAILY_REPORT_ITEM_TABLE_CONFIG = ({ reportDate }): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.dailyReport,
    defaultParam: {
      reportDate,
    },
    method: 'GET',
    deriveToModel: deriveRawToDailyReportItem,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 1400,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

export const DAILY_REPORT_ITEM_TABLE = ({ onEditItem, onDeleteItem, isFactory }): Array<CellModel> => [
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.AUTHOR',
    value: 'author',
    alwaysShow: true,
    sortable: SORT.ASC,
    sortKey: 'userId',
    width: 180,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.DATE',
    value: 'reportDate',
    component: (row) => <span>{format(row.reportDate, DATE_FORMATS.SLASH) || ''}</span>,
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 130,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.CUSTOMER',
    value: 'customer',
    alwaysShow: true,
    component: (row) => (
      <div>
        {isFactory ? (
          <Link route={`/stores/${row.storeId}`}>{row.customer}</Link>
        ) : (
          <span>{row.customer}</span>
        )}
        {validatePhone(row.store?.phone) && (
          <div>
            <a href={`tel:${row.store?.phone}`}>{row.store?.phone}</a>
          </div>
        )}
        {validateEmail(row.store?.email) && (
          <div>
            <a href={`mailto:${row.store?.email}`}>{row.store?.email}</a>
          </div>
        )}
      </div>
    ),
    sortable: SORT.ASC,
    sortKey: 'storeId',
    width: 220,
  },
  {
    label: 'INPUT.LABEL.ADDRESS',
    value: 'address',
    className: 'desktop',
    component: (row) => {
      const address = row?.store?.address;
      return (
        <>
          {address && (
            <div>
              <div>{address.street}</div>
              <div>{`${address.city}, ${address.state} ${address.zip}`}</div>
            </div>
          )}
        </>
      );
    },
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.YESNO',
    value: 'yes',
    className: 'desktop',
    component: (row) => <>{row.yes ? 'Yes' : 'No'}</>,
    sortable: SORT.ASC,
    width: 100,
  },

  {
    label: 'SCREEN.DAILY_REPORT.ITEM.CHECKNO',
    value: 'checkNo',
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.INVOICENO',
    value: 'invoiceNo',
    sortable: SORT.ASC,
    width: 130,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.CHECK_AMOUNT',
    value: 'checkAmount',
    component: (row) => <>{currency(row.checkAmount)}</>,
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.CASH_AMOUNT',
    value: 'cashAmount',
    component: (row) => <>{currency(row.cashAmount)}</>,
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.DAILY_REPORT.ITEM.MEMO',
    value: 'note',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: isFactory,
    label: '',
    value: 'checkNo',
    className: 'action',
    component: (row) => (
      <div className="button-group">
        <div>
          <Edit onClick={() => onEditItem(row)} className="pointer" />
          <Delete onClick={() => onDeleteItem(row)} className="pointer" />
        </div>
      </div>
    ),
    width: 80,
    minWidth: 80,
  },
];
