import {
  CLEAN_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  DepartmentActionTypes,
  DepartmentReducerType,
  DEPARTMENT_INIT_STATE,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENT_SUCCESS,
  REORDER_DEPARTMENT,
} from './types';

const reorderDepts = (departments, deptId, rank) => {
  const newDepartments = departments.map((d) => {
    if (d.id !== deptId) return d;
    return {
      ...d,
      rank,
    };
  });
  newDepartments.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return newDepartments;
};

export default function departmentReducer(
  state: DepartmentReducerType = DEPARTMENT_INIT_STATE,
  action: DepartmentActionTypes,
): DepartmentReducerType {
  switch (action.type) {
    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department: action.department,
      };
    case FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.departments,
      };
    case UPDATE_DEPARTMENT_SUCCESS:
    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case REORDER_DEPARTMENT:
      return {
        ...state,
        departments: reorderDepts(state.departments, action.deptId, action.rank),
      };
    case CLEAN_DEPARTMENT:
      return {
        ...DEPARTMENT_INIT_STATE,
        departments: state.departments,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
