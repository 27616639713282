import { parseAddress } from '$gbusiness/helpers/util';

export default interface VendorModel {
  id: number;
  name: string;
  code?: string;
  storeIds?: Array<number>;
  settings?: any;
}

export const deriveRawToVendor = raw => {
  return {
    id: raw.id,
    name: raw.name,
    code: raw.settings?.code || '',
    ...(raw.storeIds && { storeIds: raw.storeIds }),
    settings: {
      ...raw.settings,
      ...(raw.settings?.address && {
        address: parseAddress(raw.settings?.address),
        formatted: raw.settings?.address,
      }),
    },
  };
};

export const initialVendor = {
  id: 0,
  name: '',
  code: '',
  settings: {
    phone: '',
    email: '',
    formatted: '',
    salesRep: '',
    salesEmail: '',
  },
};
