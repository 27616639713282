import DailyReportModel, { initialDailyReport } from '../../models/dailyReport';

export const DAILY_REPORT_FAILURE = 'DAILY_REPORT_FAILURE';
export const FETCH_DAILY_REPORT_SUCCESS = 'FETCH_DAILY_REPORT_SUCCESS';
export const DAILY_REPORT_UPDATE_SUCCESS = 'DAILY_REPORT_UPDATE_SUCCESS';
export const RESET_FINISHED = 'DAILY_REPORT_RESET_FINISHED';
export const CLEAN_DAILY_REPORT = 'CLEAN_DAILY_REPORT';

// Action Types
export interface typeOnlyType {
  type: typeof DAILY_REPORT_UPDATE_SUCCESS | typeof RESET_FINISHED | typeof CLEAN_DAILY_REPORT;
}

export interface dailyReportFetchType {
  type: typeof FETCH_DAILY_REPORT_SUCCESS;
  dailyReport: DailyReportModel;
}

export interface failureType {
  type: typeof DAILY_REPORT_FAILURE;
  err: string;
}

export interface DailyReportReducerType {
  dailyReport: DailyReportModel;
  isFinished: boolean;
}

export type DailyReportActionTypes = typeOnlyType | failureType | dailyReportFetchType;

export const DAILY_REPORT_INIT_STATE: DailyReportReducerType = {
  dailyReport: initialDailyReport,
  isFinished: false,
};
