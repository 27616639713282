import CurrentStateModel from '$fbusiness/models/currentState';
import UserModel from '$gbusiness/models/user';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface PublicRouteProps extends RouteProps {
  currentState: CurrentStateModel;
  component: React.FC;
  user?: UserModel;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ currentState, user, component, ...rest }) => {
  const routeComponent = (props: any) => React.createElement(component, { ...props, currentState, user });

  return <Route {...rest} render={routeComponent} />;
};

export default PublicRoute;
