import React from 'react';

import QuantityEdit from '$gcomponents/primitives/quantityEdit';
import { Flex } from '$gstyles/wrapper';
import { currency } from '$gbusiness/helpers/util';

import { CartListWrapper } from './styles';
import intl from '$intl';
import Img from '$gcomponents/primitives/img';
import { timestamp } from '$gbusiness/helpers/date';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Cancel } from '@mui/icons-material';

interface CartListProps {
  items?: Array<any>;
  pointerIndex?: number;
  onUpdateQuantity?: Function;
  includeHeader?: boolean;
  hidePrice?: boolean;
  hideImage?: boolean;
}

const CartList: React.FC<CartListProps> = ({
  items,
  pointerIndex,
  onUpdateQuantity,
  includeHeader,
  hidePrice,
  hideImage,
}) => {
  if (!items) return null;

  const HIGHLIGHT_COLOR = '#ffff99';
  const HIGHLIGHT_DURATION = 3000;
  const FADE_DURATION = 500;

  return (
    <CartListWrapper
      highlightColor={HIGHLIGHT_COLOR}
      highlightDuration={HIGHLIGHT_DURATION}
      fadeDuration={FADE_DURATION}>
      {includeHeader && (
        <Flex className="cart-item header">
          <div>{intl('SCREEN.ORDERS.COLS.ITEMS')}</div>
          {/* <div>{intl('SCREEN.ORDERS.COLS.QUANTITY')}</div> */}
        </Flex>
      )}
      <TransitionGroup className="cart-list">
        {items.map((item, i) => {
          const { modifiers, itemName, name, price, qty, image, sku, settings } = item;
          const instruction = item.instruction || item.note || '';
          const shouldHighlight = pointerIndex === i;
          const thumbNail = item.image?.sizes?.small || '';
          return (
            <CSSTransition
              key={item.id}
              timeout={FADE_DURATION}
              classNames="item"
              className={`cart-item ${shouldHighlight ? 'highlight' : ''}`}>
              <Flex className={`${modifiers.length > 0 ? 'with-mod' : ''}`}>
                {!hideImage && (
                  <div className="image-section">
                    <Img
                      src={thumbNail}
                      cache={timestamp(image?.updatedAt || 0)}
                      className="flex"
                      placeholder="No Image"
                      width="64px"
                      showShadow
                      shadowSize={1}
                      height="64px"
                    />
                  </div>
                )}
                <div className="text-section">
                  <div className="item-text">
                    <div>
                      <div className="name">
                        <div>{itemName || name}</div>
                        {sku && <span className="sku">#{sku}</span>}
                        {settings?.packing && <span className="packing">{settings?.packing}</span>}
                      </div>
                      {modifiers.length > 0 && (
                        <div className="mod">({modifiers.map((i) => i.name).join(', ')})</div>
                      )}
                      {instruction && <div className="instruction">{`"${instruction}"`}</div>}
                    </div>
                    {onUpdateQuantity && (
                      <Cancel className="cancel-icon" onClick={() => onUpdateQuantity(i, 0)} />
                    )}
                  </div>
                  <div className="price-section">
                    {!onUpdateQuantity ? (
                      <div className="quantity">x {qty}</div>
                    ) : (
                      <CSSTransition in={shouldHighlight} timeout={HIGHLIGHT_DURATION} classNames="qty">
                        <div className="qty-item">
                          <span className="qty-label">QTY: </span>
                          <QuantityEdit
                            value={qty}
                            onChange={(q) => onUpdateQuantity(i, q)}
                            inputSize="small"
                            x={false}
                            name={`item_${i}`}
                          />
                        </div>
                      </CSSTransition>
                    )}
                    {!hidePrice && (
                      <div className="price">{currency(price?.subtotalSum || price?.totalSum)}</div>
                    )}
                  </div>
                </div>
              </Flex>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </CartListWrapper>
  );
};

export default CartList;
