import styled from 'styled-components';
import { Div, FONT, Mixins, WEIGHT } from '$gstyles';

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${p => p.height || 240}px;

  .image-section {
    ${Mixins.background};
    background-size: contain;
    flex: 1;
    max-width: ${p => p.width}px;
    cursor: pointer;
    padding: 0 10px;
  }
  .title {
    height: 30px;
    max-width: 280px;
    font-size: ${FONT.SMALL};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 15px;
  }
  .content-section {
    text-align: center;
    height: 60px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content-section.no-price {
    height: 40px;
  }
  .price {
    margin: 2px;
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.MEDIUM};
  }
  button {
    border-radius: 999px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;
