export const KEYS = {
  STORE: 'STORE',
  UNIVERSAL: 'UNIVERSAL',
  OTHER: 'OTHER',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `TAX_TYPE.${item}`,
  value: item,
}));

export type TAX_TYPE_TYPE = typeof LIST[number] | undefined;
