import { toLocal } from '$gbusiness/helpers/date';

export default interface CreditModel {
  id: number;
  storeId: number;
  memo: string;
  creditDate: string | null;
  invoiceId: number;
  amount: number;
  balance: number;
  settings?: any;
  createdAt?: string | null;
}

export const deriveRawToCredit = (raw) => {
  return {
    id: raw.id,
    storeId: raw.storeId,
    memo: raw.memo,
    creditDate: raw.creditDate,
    invoiceId: raw.invoiceId,
    amount: raw.amount,
    balance: raw.balance,
    createdAt: toLocal(raw.createdAt),
    settings: {
      ...raw.settings,
    },
  };
};

export const initialCredit: CreditModel = {
  id: 0,
  invoiceId: 0,
  storeId: 0,
  creditDate: null,
  memo: '',
  amount: 0,
  balance: 0,
  createdAt: null,
};
