import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { Header as FixedHeader } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';
import { configs } from '$configs';

import { IonPageWrapper } from './styles';

interface SettingsScreenProps {
  onHydrate;
  onDehydrate;
  user;
}

const SettingsScreen: React.FC<SettingsScreenProps> = ({ user }) => {
  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.SETTINGS.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.SETTINGS.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <IonContent></IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUser,
  onDehydrate: userActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SettingsScreen));
