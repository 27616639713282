import {
  UserActionTypes,
  UserReducerType,
  FETCH_USER_SUCCESS,
  USER_FAILURE,
  CLEAN_USER,
  USER_INIT_STATE,
  FETCH_USERS_SUCCESS,
  RESET_FINISHED,
  SAVE_USER_SUCCESS,
} from './types';

export default function userReducer(
  state: UserReducerType = USER_INIT_STATE,
  action: UserActionTypes,
): UserReducerType {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case USER_FAILURE:
      return state;
    case RESET_FINISHED:
      return {
        ...state,
        isFinished: false,
      };
    case CLEAN_USER:
      return USER_INIT_STATE;

    default:
      return state;
  }
}
