import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';

export const AR_REPORT_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.report.ar,
    method: 'POST',
    defaultSortKey: 'name',
    defaultSortOrder: SORT.ASC,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
  },
};

export const AR_REPORT_TABLE = (handleClick): Array<CellModel> => [
  {
    label: 'SCREEN.REPORT.COLS.STORE_NAME',
    value: 'storeName',
    alwaysShow: true,
    className: 'main',
    component: (row) => (
      <div className="link" onClick={() => handleClick(row.id)}>
        {row.storeName}
      </div>
    ),
    sortable: SORT.ASC,
    width: 400,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_TODAY',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.balanceToday)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_30',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.balance30)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_60',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.balance60)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_90',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.balance90)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.REPORT.COLS.AR_90p',
    value: 'total',
    align: 'center',
    component: (row) => <>{currency(row.balance90p)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
];
