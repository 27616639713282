import React from 'react';
import { Formik } from 'formik';
import { input as inputHelper } from '$gbusiness/helpers';
import { util } from '$fbusiness/helpers';

import { DATE_FORMATS } from '$gbusiness/enums';
import { KEYS, DATE_RANGE_TYPE } from '$fbusiness/enums/options/dateRange';
import { KEYS as REPORT_TYPE } from '$fbusiness/enums/options/reportType';
import intl from '$intl';
import { FilterWrapper } from './styles';
import { FILTER_FORM, DATE_FILTER_FORM } from './filterForm';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { datetimeToStringUtc } from '$gbusiness/helpers/date';
import { parseISO } from 'date-fns/esm';
import { useDispatch } from 'react-redux';
import { localStorageActions } from '$gbusiness/redux/localStorage';
import StoreModel from '$fbusiness/models/store';

interface ReportFilterProps {
  onSearch: Function;
  useDatetime?: boolean;
  parentFilter?: filterProps;
  onChangeStore?: Function;
  defaultRange?: DATE_RANGE_TYPE;
  placeholder?: string;
  includeReportType?: boolean;
  excludeDates?: boolean;
  stores?: Array<any>;
  dateOnly?: boolean;
}

export interface filterProps {
  query: string;
  store?: StoreModel;
  dateStart: string | null;
  dateEnd: string | null;
  datePeriod?: string;
  reportType?: string;
  forceRefresh?: boolean;
}

export const defaultFilter: filterProps = {
  query: '',
  dateStart: null,
  dateEnd: null,
  reportType: REPORT_TYPE.ACCURAL,
  forceRefresh: false,
};

const ReportFilter: React.FC<ReportFilterProps> = ({
  parentFilter,
  onSearch,
  stores = [],
  onChangeStore,
  excludeDates = false,
  defaultRange = KEYS.LAST_7_DAYS,
  dateOnly = false,
  includeReportType = false,
  placeholder,
}) => {
  const hasStores = stores.length > 0;
  const dispatch = useDispatch();
  const [startDate, endDate] = util.getDateRangeFromPeriod(defaultRange);
  const initialValues = {
    ...defaultFilter,
    datePeriod: defaultRange,
    dateStart: startDate,
    ...(hasStores && { store: parentFilter?.store }),
    dateEnd: endDate,
    ...(parentFilter?.dateStart && { dateStart: parseISO(parentFilter.dateStart) }),
    ...(parentFilter?.dateEnd && { dateEnd: parseISO(parentFilter.dateEnd) }),
    ...(parentFilter?.datePeriod && { datePeriod: parentFilter.datePeriod }),
  };
  const onSubmit = (values) => {
    const { query, datePeriod, dateStart, dateEnd, reportType } = values;
    const dateFilter = {
      datePeriod,
      dateStart: datetimeToStringUtc(dateStart, DATE_FORMATS.DATE),
      dateEnd: datetimeToStringUtc(dateEnd, DATE_FORMATS.DATE),
    };
    onSearch({
      ...(excludeDates ? {} : dateFilter),
      reportType,
      query,
    });
    dispatch(localStorageActions.setObj('dateFilter', dateFilter));
  };

  const filterForm = dateOnly
    ? DATE_FILTER_FORM()
    : FILTER_FORM({
        excludeDates,
        stores,
        onChangeStore,
        placeholder,
        includeReportType,
        onChangeReportType: onSubmit,
      });

  const validateForm = (values) => {
    return inputHelper.validateError(filterForm, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <FilterWrapper className="report-filter">
          <FormSection
            FORM={filterForm}
            formik={formik}
            lastColumn={
              <Button
                onClick={formik.handleSubmit}
                className="submit-button"
                variant="contained"
                size="large"
                color="secondary">
                {intl('BUTTON.SUBMIT')}
              </Button>
            }
          />
        </FilterWrapper>
      )}
    </Formik>
  );
};

export default ReportFilter;
