import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { deriveRawToCart } from '$fbusiness/models/cart';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { IconButton } from '@mui/material';

export const CART_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.cart.general,
    method: 'GET',
    deriveToModel: deriveRawToCart,
    defaultSortKey: 'id',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 50,
  },
  styles: {
    ...defaultStyles,
    responsive: true,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 320,
    rowHeight: 64,
    maxWidth: 800,
    headerHeight: 64,
    cellPadding: ` 8px 4px`,
    isStripped: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const CART_TABLE = (onApply, onDelete) => [
  {
    label: 'SCREEN.CART.COLS.NAME',
    value: 'name',
    className: 'main',
    alwaysShow: true,
    width: 250,
  },
  {
    label: 'SCREEN.CART.COLS.ITEMS',
    value: 'name',
    component: (row) => row.details?.length,
    align: 'center',
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.CART.COLS.PRICE',
    value: 'price',
    align: 'center',
    alwaysShow: true,
    component: (row) => <>{intl('PRICE', { amount: row.pricing?.subtotal || 0 })}</>,
    width: 180,
  },
  {
    label: '',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions, i) => {
      return (
        <>
          <Button className="add" onClick={() => onApply(row, i)}>
            {intl('BUTTON.APPLY_CART')}
          </Button>
          <IconButton onClick={() => onDelete(row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      );
    },
    width: 250,
  },
];
