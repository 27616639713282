import { IonToolbar, IonButtons, IonMenuToggle } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, AccountCircle, ShoppingCart, Folder, Info, LockOpen } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { IonHeaderWrapper, SearchBarWrapper } from './styles';
import Brand from './brand';
import { Autocomplete } from '$gcomponents/reusables';
import { configs, features } from '$configs';
import { MobileOver, Split } from '$gstyles/wrapper';
import { Flex } from '$gstyles/wrapper';
import { Link } from '$gcomponents/primitives';
import PopOver from '$gcomponents/reusables/popOver';
import AccountMenu from './accountMenu';
import intl from '$gintl';
import LoginModal from '../loginModal';
import { loadingActions } from '$gbusiness/redux/loading';
import CurrentStateModel from '$fbusiness/models/currentState';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import ItemListContainer from '../itemListContainer';
import { useHistory } from 'react-router';
import { Div, SPACE } from '$gstyles';
import Img from '$gcomponents/primitives/img';
import PATH from '$business/enums/paths';

interface MainHeaderProps {
  logo?: string;
  currentState: CurrentStateModel;
}

export const AUTO_COMPLETE = {
  method: 'POST',
  minChar: 2,
  renderSuggestion: (e) => (
    <Flex>
      {e.sku == null ? (
        <>
          <Folder style={{ color: '#F7D367' }} />
          <Div marginLeft={SPACE.SMALL}>{e.name}</Div>
        </>
      ) : (
        <div>{`${e.name} (${e.id})`}</div>
      )}
    </Flex>
  ),
  getLabel: (obj) => obj.name,
  onEnter: () => {
    console.log('ENETER');
  },
  deriveRaw: (response) => {
    return [...response.categories, ...response.items];
  },
};

const MainHeader: React.FC<MainHeaderProps> = ({ currentState, logo }) => {
  const [showLogin, setShowLogin] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const cartCount = useSelector(
    (state: any) => state.localStorage.cart?.products.filter((p) => p.isAdded).length,
  );
  const { isLoggedIn, ebrochure } = currentState;
  const onClickLogin = () => {
    dispatch(loadingActions.showLoginModal());
  };

  const SideMenuToggleIcon = (
    <Split>
      <IonMenuToggle menu="start">
        <IconButton className="link">
          <Menu />
        </IconButton>
      </IonMenuToggle>
    </Split>
  );
  const AccountMenuToggleIcon = isLoggedIn ? (
    <PopOver
      className="icon-button link"
      component={
        <Flex>
          <AccountCircle fontSize="large" />
          <MobileOver className="label">{intl('MENU.MY_ACCOUNT')}</MobileOver>
        </Flex>
      }>
      <AccountMenu isLoggedIn={isLoggedIn} />
    </PopOver>
  ) : features.hideLogin ? null : (
    <div className="link uppercase login" onClick={onClickLogin}>
      <LockOpen fontSize="large" />
      <div className="label">{intl('BUTTON.LOGIN')}</div>
    </div>
  );
  const CartMenuToggleIcon = (
    <IonMenuToggle className="icon-button link" autoHide={false} menu="end">
      <Badge badgeContent={cartCount} color="error">
        <ShoppingCart fontSize="large" />
      </Badge>
      <MobileOver className="label">{intl('COMMON.CART')}</MobileOver>
    </IonMenuToggle>
  );

  return (
    <>
      <IonHeaderWrapper id="sidemenu">
        <IonToolbar color="light">
          <IonButtons slot="start">{SideMenuToggleIcon}</IonButtons>
          <Flex className="title-section">
            <div className="logo-section">
              <Link route="/">
                <Brand logo={logo} />
              </Link>
            </div>
            <div className="call-image">
              <Img
                src={configs.display.imagePath + 'call.png' || ''}
                className="img-container"
                width="180px"
                size="contain"
                height="22px"
              />
            </div>
          </Flex>
          <IonButtons slot="end">
            <Link route={PATH.ABOUT_US} className="link uppercase aboutus">
              <Info fontSize="large" />
              <MobileOver className="label">{intl('SCREEN.ABOUT_US.TITLE')}</MobileOver>
            </Link>
            {AccountMenuToggleIcon}
            {isLoggedIn && CartMenuToggleIcon}
          </IonButtons>
          <LoginModal disableRegister={true} show={showLogin} onClose={() => setShowLogin(false)} />
        </IonToolbar>
      </IonHeaderWrapper>
      <SearchBarWrapper>
        <ItemListContainer currentState={currentState}>
          {(onAddCart, onViewItem) => (
            <Flex className="search-wrapper">
              <Autocomplete
                endpoint={configs.api.item.search}
                AC={{
                  ...AUTO_COMPLETE,
                  extraParam: { ebrochure, factoryId: getPublicFactoryId() },
                  onSelect: (value) => {
                    if (value.sku == null) {
                      history.push(PATH.MENU + '/' + value.id);
                    } else {
                      onViewItem(value.id);
                    }
                  },
                }}
                width="300px"
                height="32px"
              />
            </Flex>
          )}
        </ItemListContainer>
      </SearchBarWrapper>
    </>
  );
};

export default MainHeader;
