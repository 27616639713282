export default interface DiscountModel {
  id: number;
  name: string;
  amount: number;
  isFlat: boolean;
  settings?: any;
}

export const deriveRawToDiscount = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    amount: Number(raw.amount),
    isFlat: !!raw.isFlat,
    settings: raw.settings,
  };
};

export const initialDiscount = {
  id: 0,
  name: '',
  amount: 0,
  isFlat: false,
};
