import React from 'react';
import intl from '$intl';

import CreditModel from '$fbusiness/models/credit';
import StoreModel from '$fbusiness/models/store';

import { ModalWrapper } from './styles';
import CreditDetails from './creditDetails';

interface CreditModalProps {
  show: boolean;
  store: StoreModel;
  onClose: Function;
  onSaved: Function;
  credit: CreditModel;
}

const CreditModal: React.FC<CreditModalProps> = ({ show, store, credit, onClose, onSaved }) => {
  const titleText = intl('ITEM.' + (credit?.id ? 'EDIT' : 'NEW'), { item: intl('Credit') });

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {credit && <CreditDetails store={store} credit={credit} onClose={onClose} onSaved={onSaved} />}
    </ModalWrapper>
  );
};

export default CreditModal;
