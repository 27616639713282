export default interface TermModel {
  id: number;
  name: string;
  dueDays: number;
  discountRate: number;
  discountDays: number;
  isDisabled: boolean;
}

export const deriveRawToTerm = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    dueDays: raw.dueDays,
    discountRate: raw.discountRate,
    discountDays: raw.discountDays,
    isDisabled: raw.isDisabled,
  };
};

export const initialTerm = {
  id: 0,
  name: '',
  dueDays: 0,
  discountRate: 0,
  discountDays: 0,
  isDisabled: 0,
};
