import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { TableView2 } from '$gcomponents/widgets';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { initialCredit } from '$fbusiness/models/credit';
import StoreModel from '$fbusiness/models/store';
import { currency } from '$gbusiness/helpers/util';

import CreditModal from './creditModal';
import { CREDIT_TABLE, CREDIT_TABLE_CONFIG } from './creditTable';
import CurrentStateModel from '$fbusiness/models/currentState';

interface CreditsListProps {
  store: StoreModel;
  currentState?: CurrentStateModel;
}

const CreditsList: React.FC<CreditsListProps> = ({ store, currentState }) => {
  const { id: storeId } = store;
  const [filter, setFilter] = useState({ forceRefresh: true, query: '', storeId });
  const [credit, setCredit] = useState<any>(null);

  const updateFilter = (newFilter = {}) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const addCredit = () => {
    setCredit({
      ...initialCredit,
      storeId,
    });
  };

  const onSaveCredit = () => {
    setCredit(null);
    updateFilter();
  };

  useEffect(() => {
    setFilter({ ...filter, storeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  return (
    <>
      <Flex justifyContent="space-between">
        <h2>{intl('SCREEN.CREDIT.TITLE')}</h2>
        {currentState?.isFactory && (
          <Button onClick={addCredit} variant="outlined">
            {intl('ITEM.ADD', { item: 'Credit' })}
          </Button>
        )}
      </Flex>
      <div className="balance">
        {intl('SCREEN.CREDIT.BALANCE')}: <span className="price">{currency(store.credits || 0)}</span>
      </div>
      <TableView2 filter={filter} TABLE={CREDIT_TABLE} tableConfig={CREDIT_TABLE_CONFIG} />
      <CreditModal
        show={!!credit}
        credit={credit}
        store={store}
        onClose={() => setCredit(null)}
        onSaved={onSaveCredit}
      />
    </>
  );
};

export default CreditsList;
