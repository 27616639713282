import { toLocal } from '$gbusiness/helpers/date';
import CategoryModel from './category';

export default interface DepartmentModel {
  id: number;
  name: string;
  email?: string;
  image?: string;
  categories?: Array<CategoryModel>;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToDepartment = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    email: raw.email,
    image: raw.imageUri,
    categories: (raw.categories || []).map((c) => ({ id: c.id, name: c.name })),
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt || raw.createdAt),
  };
};

export const initialValue = {
  id: 0,
  name: '',
  email: '',
  taxPercent: 0,
};
