import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import {
  CLEAN_DAILY_REPORT,
  DailyReportActionTypes,
  DAILY_REPORT_FAILURE,
  DAILY_REPORT_UPDATE_SUCCESS,
  FETCH_DAILY_REPORT_SUCCESS,
  RESET_FINISHED,
} from './types';
import { deriveRawToDailyReport } from '../../models/dailyReport';

export function fetchDailyReport(id = 0): any {
  return async (dispatch: Dispatch) => {
    if (!id) return;

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.dailyReport + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, DAILY_REPORT_FAILURE, response, 'ERROR.SERVER');
      dispatch({ type: CLEAN_DAILY_REPORT });
      return;
    } else {
      dispatch({
        type: FETCH_DAILY_REPORT_SUCCESS,
        dailyReport: deriveRawToDailyReport(response.data),
      });
    }
  };
}

export function saveDailyReport(dailyReport): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');
    const { id } = dailyReport;

    const response = await fetchApi({
      url: configs.api.dailyReport + (id ? '/' + id : ''),
      method: id ? 'PUT' : 'POST',
      param: dailyReport,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, DAILY_REPORT_FAILURE, response, 'ERROR.SAVE', true);
    } else {
      handleApiSuccess(dispatch, DAILY_REPORT_UPDATE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteDailyReport(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.dailyReport + ('/' + id),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, DAILY_REPORT_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DAILY_REPORT_UPDATE_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function resetFinished(): DailyReportActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): DailyReportActionTypes {
  return { type: CLEAN_DAILY_REPORT };
}
