import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE, WEIGHT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  font-size: 1.1em;
  line-height: 1.7em;

  h2,
  h3 {
    padding-top: ${SPACE.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }

  h2:first-child {
    padding-top: 0;
  }

  .image {
    float: right;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 0 8px;
  }

  ${mobileStyle(`
    .image {
      width: 100% !important;
      float: initial;
    }
  `)}
`;
