import React from 'react';
import { Table } from '$gcomponents/reusables';
import CellModel from '$gbusiness/models/cell';
import { defaultStyles } from '$gbusiness/models/table';
import { COLORS } from '$gbusiness/enums';
import OrderItemModel from '$fbusiness/models/orderDetails';

interface closeOrderListProps {
  items: Array<OrderItemModel>;
  isRegenerated: boolean;
  onChangeQty: Function;
}

const TABLE = (onChangeQty, isRegenerated): Array<CellModel> => [
  {
    label: 'SCREEN.INVOICES.COLS.ITEM_SHIPPED',
    value: 'itemName',
    component: row => (
      <div>
        <div>{row.itemName}</div>
        {row.modifiers?.length > 0 && (
          <div className="mod">({row.modifiers.map(i => i.name).join(', ')})</div>
        )}
      </div>
    ),
    className: 'main',
    width: 300,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY',
    value: 'qtyOrdered',
    align: 'center',
    width: 200,
  },
  {
    isHidden: !isRegenerated,
    label: 'SCREEN.INVOICES.COLS.QTY_SENT_TOTAL',
    value: 'totalSent',
    align: 'center',
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_SENT',
    value: 'qtySent',
    align: 'center',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_RECEIVED',
    value: 'qty',
    component: (row, actions, i) => {
      return (
        <input
          type="number"
          onFocus={e => e.target.select()}
          className="qty"
          value={row.qtyReceived || ''}
          onChange={e => onChangeQty(e.target.value, i)}
        />
      );
    },
    className: 'action',
    align: 'center',
    width: 200,
  },
];

const styles = {
  ...defaultStyles,
  color: COLORS.MEDIUM,
  minWidth: 200,
  maxWidth: 800,
  cellPadding: ` 8px`,
  responsive: false,
  isStripped: false,
  headerHeight: 50,
};

const closeOrderList: React.FC<closeOrderListProps> = ({ items, onChangeQty, isRegenerated }) => {
  return <Table data={items} TABLE={TABLE(onChangeQty, isRegenerated)} styles={styles} />;
};

export default closeOrderList;
