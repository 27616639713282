const KEYS = {
  YES: 'YES',
  NO: 'NO',
};

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `YESNO.${item}`,
  value: item === KEYS.YES ? 1 : 0,
}));

export type YES_NO_TYPE = typeof LIST[number] | undefined;
