import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { currency } from '$gbusiness/helpers/util';
import { Button } from '$gcomponents/primitives';
import intl from '$intl';
import { deriveRawToInvoice } from '$fbusiness/models/invoice';
import { CLOSE_OPTIONS, KEYS } from '$fbusiness/enums/options/invoiceStatus';
import { KEYS as ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';
import { Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';

export const INVOICES_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.invoice.general,
    // mockData,
    deriveToModel: deriveRawToInvoice,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
    autoRefreshInterval: 30,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 800,
    maxWidth: 1400,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: true,
    rowHeight: 44,
    headerHeight: 64,
    setRowClass: (row) => (row.overdue ? 'overdue' : ''),
  },
};

export const INVOICES_TABLE = ({
  onCloseOrder,
  onViewInvoice,
  onViewShipping,
  onChangeStatus,
  isReceiving,
  isPickupOnly,
  hidePrice,
  isSalesman,
}): Array<CellModel> => [
  {
    label: 'SCREEN.INVOICES.COLS.CREATED',
    value: 'createdAt',
    component: (row) => <>{format(row?.createdAt, DATE_FORMATS.SLASH) || ''}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: !isSalesman,
    label: 'SCREEN.INVOICES.COLS.STORE',
    value: 'store.name',
    alwaysShow: true,
    sortKey: 'stores.name',
    sortable: SORT.ASC,
    width: 280,
  },
  {
    label: 'SCREEN.INVOICES.COLS.' + (isReceiving ? 'RECEIVING_NO' : 'INVOICE_NO'),
    value: 'invoiceNumber',
    className: 'main',
    width: 250,
  },
  {
    label: 'SCREEN.INVOICES.COLS.ORDER_ID',
    value: 'order.orderId',
    className: 'desktop',
    align: 'center',
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.' + (isPickupOnly ? 'PICKUP_DATE' : 'DELIVERY_DATE'),
    value: 'createdAt',
    component: (row) => <>{row.order ? format(row.order.deliveryDate, DATE_FORMATS.SLASH) || '' : ''}</>,
    sortable: SORT.ASC,
    width: 180,
  },
  // {
  //   label: 'SCREEN.INVOICES.COLS.DELIVERED_DATE',
  //   value: 'deliveredAt',
  //   component: row => <>{format(row.order.confirmedAt, DATE_FORMATS.SLASH) || ''}</>,
  //   sortable: SORT.ASC,
  //   width: 200,
  // },
  // {
  //   label: 'SCREEN.INVOICES.COLS.STATUS',
  //   value: 'order.status',
  //   align: 'center',
  //   width: 150,
  // },
  {
    label: 'SCREEN.INVOICES.COLS.QTY',
    value: 'order.qty',
    component: (row) => <>{row.order?.qty || 0}</>,
    className: 'desktop',
    align: 'center',
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_SENT',
    value: 'qtySent',
    className: 'desktop',
    align: 'center',
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_RECEIVED',
    value: 'qtyReceived',
    component: (row) => <>{row.qtyReceived || ''}</>,
    sortable: SORT.ASC,
    width: 150,
  },
  {
    isHidden: isPickupOnly,
    label: 'SCREEN.INVOICES.COLS.SHIPPED_TO',
    value: 'order.city',
    width: 200,
  },
  {
    isHidden: hidePrice,
    label: 'SCREEN.INVOICES.COLS.TOTAL',
    value: 'total',
    component: (row) => <>{currency(row.total)}</>,
    sortable: SORT.ASC,
    width: 170,
  },
  {
    label: 'SCREEN.INVOICES.COLS.TERM',
    value: 'paymentTerm',
    component: (row) => <>{row.term?.name || ''}</>,
    width: 200,
  },
  {
    isHidden: isReceiving,
    label: 'SCREEN.INVOICES.COLS.INVOICE_STATUS',
    value: 'status',
    className: 'no-label',
    component: (row) => {
      if (row.status === KEYS.PAID) return <div>{intl('INPUT.LABEL.INVOICE_STATUS.PAID')}</div>;
      const options = CLOSE_OPTIONS;
      return row.closedAt ? (
        <Select variant="outlined" value={row.status} onChange={(e) => onChangeStatus(row, e.target.value)}>
          {generateSelectOptions(options, row.status, true)}
        </Select>
      ) : (
        <div>{intl('INPUT.OPTION.INVOICE_STATUS.' + row.status)}</div>
      );
    },
    sortable: SORT.ASC,
    width: 270,
    minWidth: 141,
  },
  {
    label: '',
    value: '',
    align: 'center',
    component: (row) =>
      row.confirmedAt ? (
        <Button size="small" variant="text" onClick={() => onViewShipping(row)}>
          {intl('SCREEN.INVOICES.ACTIONS.SHIP_DETAILS')}
        </Button>
      ) : null,
    width: 220,
  },
  {
    label: '',
    value: '',
    align: 'center',
    className: 'action',
    component: (row) =>
      (row.order?.status === ORDER_STATUS.DELIVERED || row.order?.status === ORDER_STATUS.COMPLETED) &&
      !row.flaggedAt ? (
        <Button size="small" onClick={() => onCloseOrder(row)}>
          {intl('SCREEN.INVOICES.ACTIONS.CLOSE')}
        </Button>
      ) : isReceiving ? (
        ''
      ) : (
        <Button size="small" variant="text" onClick={() => onViewInvoice(row)}>
          {intl('SCREEN.INVOICES.ACTIONS.VIEW')}
        </Button>
      ),
    width: 200,
  },
];
