import { IonPage } from '@ionic/react';
import styled from 'styled-components';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  ion-content {
    padding: ${SPACE.LARGE};
  }
  .MuiTableRow-head {
    border-bottom: 1px solid #ccc;
  }
  .MuiTableCell-root.action button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .action {
    button {
      padding: 6px;
    }
  }
  .available {
    margin-left: ${SPACE.SMALL};
  }
  input.qty {
    width: 40px;
    padding: 4px;
    text-align: center;
  }
  button.add {
    font-size: 1.3rem;
    width: 60px;
    .MuiButton-endIcon {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
  button.added {
    min-width: 80px;
    display: none;
  }
  .cart-add-enter-active {
    button.add {
      display: none;
    }
    button.added {
      display: inline-flex;
    }
  }

  .subcategory-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: 2rem;
    margin: ${SPACE.XLARGE} 0;
  }

  ${mobileStyle(`
    ul.list li {
      .image, .image img, .image > div{
        max-height: 48px !important;
        max-width: 48px !important;
      }
      .action {
        width: 80px;
      }
    }
    button.MuiButtonBase-root.add {
      width: 80px;
    }
  `)}
`;

export const SubcategoryWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(p) => p.height + 40 || 120}px;
  min-width: ${(p) => p.height || 120}px;
  .cat-link {
    color: var(--ion-color);
  }
  .image-section {
    height: ${(p) => p.height || 80}px;
    display: flex;
    justify-content: center;
  }
  .text-section {
    height: 40px;
    text-align: center;
    font-size: ${FONT.MEDIUM};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
`;
