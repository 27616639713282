import { Modal } from '$gcomponents/reusables';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .metadata {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .memo {
      font-size: ${FONT.SMALL};
      opacity: 0.8;
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .modal-header {
    height: 195px;
  }
  .dropzone-wrapper {
    width: 100%;
    height: 90px;
  }
  .youtube-wrapper {
    background-color: #eee;
    padding: ${SPACE.MEDIUM};
    > div {
      padding: ${SPACE.SMALL};
    }
    input {
      background-color: #fff;
    }
  }

  .preview-table {
    input {
      width: 100%;
      width: 100%;
      border-radius: 4px;
      padding: 6px 8px;
      border-color: #ccc;
      border-width: 1px;
    }
  }
`;

export const StoreSelectorWrapper = styled(Div)`
  &.oneline {
    display: flex;
    height: 47px;
    align-items: center;
    padding: 0 ${SPACE.LARGE};

    .store-selector {
      min-width: 300px;
    }
    ion-item {
      --min-height: 42px;
      ion-label {
        margin-top: -10px !important;
      }
    }
    ion-select {
      padding: 4px 0;
    }
  }
`;
