import React from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';

import { Wrapper } from './styles';
import { Flex } from '$gstyles/wrapper';
import { currency } from '$gbusiness/helpers/util';
import { timestamp } from '$gbusiness/helpers/date';
import Img from '$gcomponents/primitives/img';
import { AddCircle, AddShoppingCartRounded, Search } from '@mui/icons-material';
import { Button } from '$gcomponents/primitives';
import { IconButton } from '@mui/material';

interface ItemSimpleViewProps {
  item: ItemModel;
  onAddCart: Function;
  onClick?: Function;
  isWholesale: boolean;
  hidePrice?: boolean;
  ebrochure?: boolean;
}

const ItemSimpleView: React.FC<ItemSimpleViewProps> = ({
  hidePrice = false,
  ebrochure = false,
  isWholesale,
  item,
  onAddCart,
  onClick = () => {},
}) => {
  const price = isWholesale ? item.wholesalePrice : item.retailPrice;
  const tagText = intl('SCREEN.ITEMS.' + (isWholesale ? 'WHOLESALE' : 'RETAIL') + '_TAG');
  const thumbNail = item.image?.sizes?.small;

  const handleAddCart = (e) => {
    e.stopPropagation();
    onAddCart(item);
  };
  return (
    <Wrapper>
      <div className="image-section" onClick={() => onClick(item.id)}>
        <Img
          src={thumbNail || ''}
          cache={timestamp(item.updatedAt)}
          className="flex"
          placeholder="No Image"
          width="100%"
          height="100%"
        />
      </div>
      <div className="content-section">
        <div className="pointer" onClick={() => onClick(item.id)}>
          <div className="title">{item.name}</div>
          <div className="description">
            {item.description}
            <Flex className="mis">
              <div className="sku">{item.sku}</div>
              <div className="packing">{item.settings?.packing}</div>
              <div className="skid">{item.settings?.skid}</div>
            </Flex>
          </div>
        </div>
        {ebrochure ? (
          <div className="actions">
            <Button startIcon={<Search />} size="small" color="secondary" onClick={() => onClick(item.id)}>
              {intl('BUTTON.ITEM_DETAILS')}
            </Button>
          </div>
        ) : !hidePrice ? (
          <Flex className="actions">
            <div className="price">{currency(price)}</div>
            <div className="tag-container">
              <div className="tag">{tagText}</div>
            </div>
            <div className="add">
              <IconButton color="secondary" onClick={handleAddCart}>
                <AddCircle />
              </IconButton>
            </div>
          </Flex>
        ) : (
          <div className="actions">
            <Button
              startIcon={<AddShoppingCartRounded />}
              size="small"
              color="secondary"
              onClick={handleAddCart}>
              {intl('BUTTON.ADD_CART')}
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default ItemSimpleView;
