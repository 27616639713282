import { convertToTree, flattenTreeSorted } from '../../models/tree';
import {
  CLEAN_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  categoryActionTypes,
  categoryReducerType,
  CATEGORY_INIT_STATE,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_SUCCESS,
  REORDER_CATEGORY,
  TOGGLE_EXPAND,
} from './types';

const reorderCategories = (categories, categoryId, rank) => {
  const newCategories = categories.map((d) => {
    if (d.id !== categoryId) return d;
    return {
      ...d,
      rank,
    };
  });
  newCategories.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return newCategories;
};

// const toggleExpandCategory = (tree, categoryId) => {
//   const treeItem = tree.items[categoryId];
//   return {
//     ...tree,
//     items: {
//       ...tree.items,
//       [categoryId]: {
//         ...treeItem,
//         isExpanded: !treeItem.isExpanded,
//       },
//     },
//   };
// };

const categoryReducer = function (
  state: categoryReducerType = CATEGORY_INIT_STATE,
  action: categoryActionTypes,
): categoryReducerType {
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        tree: convertToTree(action.categories, state.expands),
        flat: flattenTreeSorted(action.categories),
      };
    case UPDATE_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case REORDER_CATEGORY:
      return {
        ...state,
        categories: reorderCategories(state.categories, action.categoryId, action.rank),
      };
    case TOGGLE_EXPAND:
      const newExapndedState = state.expands[action.categoryId] === false ? true : false;
      const expands = {
        ...state.expands,
        [action.categoryId]: newExapndedState,
      };
      return {
        ...state,
        expands,
        tree: convertToTree(state.categories, expands),
      };
    case CLEAN_CATEGORY:
      return {
        ...CATEGORY_INIT_STATE,
        categories: state.categories,
        tree: state.tree,
        flat: state.flat,
        refresh: state.refresh,
      };
    default:
      return state;
  }
};

export default categoryReducer;
