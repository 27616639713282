import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';

import { Table } from '$gcomponents/reusables';
import InvoiceModel from '$fbusiness/models/invoice';

import { SHIPPING_TABLE, SHIPPING_TABLE_CONFIG } from './shippingTable';
import { Flex } from '$gstyles/wrapper';
import OrderSummary from '$fcomponents/orderSummary';
import CurrentStateModel from '$fbusiness/models/currentState';
import { derivePrice } from '$fbusiness/models/price';

interface ViewShippingDetailsProps {
  currentState: CurrentStateModel;
  invoice: InvoiceModel;
  onClose: () => void;
}

const ViewShippingDetails: React.FC<ViewShippingDetailsProps> = ({ currentState, invoice, onClose }) => {
  if (!invoice) return null;
  const { items, noteFactory } = invoice;

  const isRegenerated = !!invoice.revision;
  const { hidePrice } = currentState;

  const itemsTable = SHIPPING_TABLE({ isRegenerated, hidePrice });
  const tableProps = {
    styles: SHIPPING_TABLE_CONFIG.styles,
    TABLE: itemsTable,
    data: items,
  };

  return (
    <>
      <IonContent>
        <Div className="content-wrapper">
          <Table {...tableProps} />
          <Flex justifyContent="flex-end">
            <Div width="300px">
              <OrderSummary isHidden={hidePrice} price={derivePrice(invoice)} />
            </Div>
          </Flex>
          <Div padding={SPACE.LARGE}>
            <div className="notes-label">{intl('SCREEN.INVOICES.NOTES')}</div>
            <textarea className="notes disabled" defaultValue={noteFactory} />
          </Div>
        </Div>
      </IonContent>
      <Footer justifyContent="center">
        <Button className="half no-print" onClick={onClose}>
          {intl('BUTTON.CONFIRM')}
        </Button>
      </Footer>
    </>
  );
};

export default ViewShippingDetails;
