import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const CREDIT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.CREDIT.COLS.DATE',
        value: 'creditDate',
        input: INPUT.DATE,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'SCREEN.CREDIT.COLS.AMOUNT',
        value: 'amount',
        validation: [input.amountValidation],
        gridSize: 6,
      },
      {
        input: INPUT.TEXTAREA,
        label: 'SCREEN.CREDIT.COLS.MEMO',
        value: 'memo',
        gridSize: 12,
      },
    ],
  },
];
