import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';

import FILE_TABLE, { FILE_TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import { fileActions } from '$fbusiness/redux/file';
import StoreModel from '$fbusiness/models/store';
import { configs } from '$configs';

interface MyFilesScreenProps {
  store: StoreModel;
}

const Header =
  configs.display.layout === 'FIXED_HEADER' ? (
    <FactoryHeader title="SCREEN.FILES.TITLE" />
  ) : (
    <FixedHeader title="SCREEN.FILES.TITLE" />
  );

const MyFilesScreen: React.FC<MyFilesScreenProps> = ({ store }) => {
  const [filter] = useState<any>({ query: '', forceRefresh: true, storeId: store?.id });

  return (
    <IonPageWrapper>
      {Header}
      <TableView2 tableConfig={FILE_TABLE_CONFIG} TABLE={FILE_TABLE} filter={filter} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  store: state.factory.store,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  fetchFile: fileActions.fetchFile,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MyFilesScreen));
