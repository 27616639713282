import React from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';

import { Wrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';
import { timestamp } from '$gbusiness/helpers/date';
import Img from '$gcomponents/primitives/img';
import { Button } from '$gcomponents/primitives';
import { AddShoppingCartOutlined, Search } from '@mui/icons-material';

interface ItemFeaturedViewProps {
  item: ItemModel;
  onAddCart: Function;
  onClick?: Function;
  isWholesale: boolean;
  hidePrice?: boolean;
  ebrochure?: boolean;
  height: number;
  width: number;
}

const ItemFeaturedView: React.FC<ItemFeaturedViewProps> = ({
  isWholesale,
  item,
  hidePrice,
  ebrochure,
  onClick = () => {},
  onAddCart,
  height,
  width,
}) => {
  const price = isWholesale ? item.wholesalePrice : item.retailPrice;
  const thumbNail = item.image?.sizes?.medium;

  const handleAddCart = (e) => {
    e.stopPropagation();
    onAddCart(item);
  };
  return (
    <Wrapper height={height} width={width}>
      <div className="image-section" onClick={() => onClick(item.id)}>
        <Img
          src={thumbNail || ''}
          cache={timestamp(item.updatedAt)}
          className="flex"
          placeholder="No Image"
          width="100%"
          height="100%"
          showShadow
        />
      </div>
      <div className="title">{item.name}</div>
      <div className={`content-section ${(hidePrice || ebrochure) && 'no-price'}`}>
        {ebrochure ? (
          <Button startIcon={<Search />} size="small" color="primary" onClick={() => onClick(item.id)}>
            {intl('BUTTON.ITEM_DETAILS')}
          </Button>
        ) : (
          <>
            {!hidePrice && <div className="price">{currency(price)}</div>}
            <Button size="small" onClick={handleAddCart} icon={<AddShoppingCartOutlined />}>
              {intl('BUTTON.ADD_CART')}
            </Button>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ItemFeaturedView;
