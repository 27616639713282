import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import { emailLink, phoneLink } from '$gcomponents/utils/general';
import { deriveRawToVendor } from '$fbusiness/models/vendor';
import CellModel from '$gbusiness/models/cell';

export const VENDOR_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.vendor.general,
    method: 'GET',
    deriveToModel: deriveRawToVendor,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 600,
    maxWidth: 900,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const VENDOR_TABLE = (onClickRow): Array<CellModel> => [
  {
    label: 'SCREEN.VENDORS.COLS.NAME',
    value: 'name',
    className: 'main',
    component: row => (
      <div className="link" onClick={() => onClickRow(row)}>
        {row.name}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.VENDORS.COLS.CODE',
    value: 'settings.code',
    className: 'desktop',
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.VENDORS.COLS.PHONE_EMAIL',
    value: '',
    component: row => (
      <div>
        {row.settings.phone && <div>{phoneLink(row.settings.phone)}</div>}
        {row.settings.email && <div>{emailLink(row.settings.email)}</div>}
      </div>
    ),
    width: 300,
  },
  {
    label: 'SCREEN.VENDORS.COLS.ADDRESS',
    value: 'settings.address',
    component: row => {
      if (!row.settings?.address) return null;
      const {
        address: { street, state, city, zip },
      } = row.settings;
      return (
        <>
          {street && (
            <div>
              <div>{street}</div>
              <div>{`${city}, ${state} ${zip}`}</div>
            </div>
          )}
        </>
      );
    },
    className: 'desktop',
    width: 250,
  },
  {
    label: 'SCREEN.VENDORS.COLS.SALES',
    value: 'settings.code',
    component: row => {
      const { salesEmail, salesRep } = row.settings;
      return (
        <div>
          {salesRep && <div>{salesRep}</div>}
          {salesEmail && <div>{emailLink(salesEmail)}</div>}
        </div>
      );
    },
    className: 'desktop',
    width: 300,
  },
];
