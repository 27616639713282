import InvoiceModel from '../../models/invoice';
import InvoicePaymentModel from '../../models/invoicePayment';

export const INVOICE_FAILURE = 'INVOICE_FAILURE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const FETCH_INVOICE_PAYMENTS = 'FETCH_INVOICE_PAYMENTS';
export const ADD_INVOICE_PAYMENT = 'ADD_INVOICE_PAYMENT';
export const DELETE_INVOICE_PAYMENT = 'DELETE_INVOICE_PAYMENT';
export const UPDATE_INVOICE_PAYMENT = 'UPDATE_INVOICE_PAYMENT';
export const CLEAN_INVOICE = 'CLEAN_INVOICE';

// Action Types
export interface typeOnlyType {
  type: typeof UPDATE_INVOICE_SUCCESS | typeof CLEAN_INVOICE;
}

export interface failureType {
  type: typeof INVOICE_FAILURE;
  err: string;
}

export interface FetchInvoiceSuccessType {
  type: typeof FETCH_INVOICE_SUCCESS;
  invoice: InvoiceModel;
}

export interface AddInvoicePaymentType {
  type: typeof ADD_INVOICE_PAYMENT;
  invoicePayment: InvoicePaymentModel;
}

export interface DeleteInvoicePaymentType {
  type: typeof DELETE_INVOICE_PAYMENT;
  index: number;
}

export interface UpdateInvoicePaymentType {
  type: typeof UPDATE_INVOICE_PAYMENT;
  invoicePayment: InvoicePaymentModel;
  index: number;
}

export interface FetchInvoicePaymentsType {
  type: typeof FETCH_INVOICE_PAYMENTS;
  invoicePayments: Array<InvoicePaymentModel>;
}

export interface invoiceReducerType {
  invoice: InvoiceModel | null;
  isFinished: boolean;
  refresh: boolean;
  invoicePayments: Array<InvoicePaymentModel>;
}

export type invoiceActionTypes =
  | typeOnlyType
  | failureType
  | AddInvoicePaymentType
  | UpdateInvoicePaymentType
  | DeleteInvoicePaymentType
  | FetchInvoiceSuccessType
  | FetchInvoicePaymentsType;

export const INVOICE_INIT_STATE: invoiceReducerType = {
  invoice: null,
  isFinished: false,
  refresh: true,
  invoicePayments: [],
};
