import {
  CLEAN_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_REFUND_SUCCESS,
  DELETE_ORDER_SUCCESS,
  FETCH_ORDER_SUCCESS,
  FETCH_REFUND_SUCCESS,
  orderActionTypes,
  orderReducerType,
  ORDER_INIT_STATE,
  UPDATE_ORDER_SUCCESS,
} from './types';

export default function orderReducer(
  state: orderReducerType = ORDER_INIT_STATE,
  action: orderActionTypes,
): orderReducerType {
  switch (action.type) {
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
        isFinished: true,
      };
    case CREATE_REFUND_SUCCESS:
      return {
        ...state,
        refund: action.refund,
        isFinished: true,
      };
    case FETCH_REFUND_SUCCESS:
      return {
        ...state,
        refund: action.refund,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_ORDER:
      return {
        ...ORDER_INIT_STATE,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
