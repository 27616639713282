import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import { Header, TableView2 } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import ItemActionModel from '$gbusiness/models/itemAction';
import { dialog } from '$gcomponents/reusables';
import { dailyReportActions } from '$fbusiness/redux/dailyReport';
import { Print } from '@mui/icons-material';
import ReportFilter from '$fcomponents/filters/reportFilter';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import { updatedFilter } from '$fbusiness/helpers/util';
import {
  DAILY_REPORT_ITEM_TABLE,
  DAILY_REPORT_ITEM_TABLE_CONFIG,
} from '../dailyReportScreen/dailyReportItemTable';
import FabButton from '$fcomponents/fabButton';

export const dailyReportRoute = '/dailyreports';

interface DailyReportsScreenProps {
  history;
  isFactory: boolean;
  dateFilter: any;
  deleteReport: Function;
}

const DailyReportsScreen: React.FC<DailyReportsScreenProps> = ({
  isFactory = false,
  history,
  dateFilter,
  deleteReport,
}) => {
  // const [filter, setFilter] = useState<any>({ ...deriveDefaultDateFilter(KEYS.TODAY), forceRefresh: true });
  const [filter, setFilter] = useState<any>(dateFilter);
  const [selections, setSelections] = useState<any>([]);

  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      onClick: (row) => {
        history.push(dailyReportRoute + '/' + row.id);
      },
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteReport(row.id);
          },
        });
      },
    },
  ];

  const onPrint = () => {
    window.print();
  };

  const updateFilter = (newFilter) => {
    setFilter(updatedFilter(filter, newFilter));
  };

  const handleSelections = (arr) => {
    setSelections(arr.reduce((acc, a) => (a ? [...acc, a.id] : acc), []));
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.DAILY_REPORT.TITLE_MANY" />

      <TableView2
        tableConfig={DAILY_REPORT_ITEM_TABLE_CONFIG({ reportDate: '' })}
        TABLE={DAILY_REPORT_ITEM_TABLE({ onEditItem: () => {}, onDeleteItem: () => {}, isFactory })}
        filter={filter}
        onSelection={handleSelections}
        itemActions={itemActions}>
        <FilterSection title={null}>
          <ReportFilter parentFilter={filter} placeholder="Filter" onSearch={updateFilter} dateOnly />
        </FilterSection>
      </TableView2>
      {selections.length > 0 && <FabButton onClick={onPrint} icon={<Print />} />}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  resetOnRoute: true,
});

const mapDispatchToProps = {
  deleteReport: dailyReportActions.deleteDailyReport,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DailyReportsScreen));
