export const PATH = {
  ACCOUNT: '/account',
  HISTORY: '/history',
  CARTS: '/carts',
  INVOICES: '/invoices',
  RECEIVING: '/receiving',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  MY_FILES: '/myfiles',
  MY_VENDORS: '/myvendors',
  CREDITS: '/credits',
  TOS: '/tos',
  REPORT_AR: '/report-ar',
  DAILY_REPORT: '/dailyreport',
  DAILY_REPORTS: '/dailyreports',
};

export default PATH;
