import React from 'react';
import { connect } from 'react-redux';

import { screen } from '../../components/hoc';

import { IonPageWrapper } from './styles';
import IonContentWrapper from '../../components/ionContentWrapper';
import { PageWrapper } from '$gstyles/wrapper';
import FactoryModel from '$fbusiness/models/factory';

import defaultContent from './tos';
import { Header } from '$gcomponents/widgets';

interface TosScreenProps {
  factory: FactoryModel;
}

const TosScreen: React.FC<TosScreenProps> = ({ factory }) => {
  const tos = factory?.settings?.toc || defaultContent;

  return (
    <IonPageWrapper>
      <Header titleText="Terms and Conditions" />
      <IonContentWrapper>
        <PageWrapper>
          <div dangerouslySetInnerHTML={{ __html: tos }} />
        </PageWrapper>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  factory: state.factory.factory,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(TosScreen));
