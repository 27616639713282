export default interface ShippingModel {
  id: number;
  name: string;
  description?: string;
  deliveryCost?: number;
  days: number;
}

export const deriveRawToShipping = raw => {
  return {
    id: raw.id,
    name: raw.name,
    description: raw.description,
    deliveryCost: raw.deliveryCost,
    days: raw.days,
  };
};

export const initialShipping = {
  id: 0,
  name: '',
  description: '',
  days: 3,
};
