import React from 'react';

import intl from '$intl';
import { ScreenTitle } from '$styles/general';
import { SPACE, WEIGHT } from '$gstyles';
interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  [x: string]: any;
}

const Header: React.FC<HeaderProps> = ({ title, titleText, titleParam = {}, ...rest }) => {
  const derivedTitle = intl(title, titleParam) || titleText || '';
  const titleComponent = derivedTitle || intl('COMMON.APP_TITLE');

  return (
    <ScreenTitle
      padding={SPACE.LARGE}
      fontSize="24px"
      textTransform="uppercase"
      fontWeight={WEIGHT.SEMI_BOLD}
      {...rest}>
      {titleComponent}
    </ScreenTitle>
  );
};

export default Header;
