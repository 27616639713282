import { toLocal } from '$gbusiness/helpers/date';

export default interface FileModel {
  id: number;
  name: string;
  description: string;
  isMain?: boolean;
  fileName: string;
  fileType: string;
  fileSize?: number;
  fileUrl: string;
  storeIds?: Array<number>;
  sizes?: {
    original: string;
    medium: string;
    small: string;
  };
  youtubeLink?: string;
  createdAt?: string;
}

export const deriveRawToFile = (raw) => {
  return {
    id: raw.id,
    isMain: raw.isMain,
    name: raw.name,
    description: raw.description,
    fileName: raw.fileName,
    fileType: raw.fileType,
    fileSize: raw.fileSize,
    fileUrl: raw.fileUrl,
    youtubeLink: raw.youtubeLink,
    ...(raw.storeIds && { storeIds: raw.storeIds }),
    sizes: raw.sizes,
    createdAt: toLocal(raw.createdAt),
  };
};

export const initialFile: FileModel = {
  id: 0,
  name: '',
  description: '',
  fileName: '',
  fileSize: 0,
  fileType: '',
  fileUrl: '',
  youtubeLink: '',
};
