import React, { useEffect, useState, useRef } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, SPACE } from '$gstyles';
import { input } from '$gbusiness/helpers';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';
import CloseOrderList from './closeOrderList';

import InvoiceModel from '$fbusiness/models/invoice';
import { useDispatch } from 'react-redux';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { Formik } from 'formik';
import getCloseForm from './closeForm';
import { FormSection } from '$gcomponents/reusables';

interface CloseOrderDetailsProps {
  invoice: InvoiceModel;
  onClose: () => void;
  onCloseInvoice: () => void;
}

const CloseOrderDetails: React.FC<CloseOrderDetailsProps> = ({ invoice, onClose, onCloseInvoice }) => {
  const [formItems, setFormItems] = useState<any>([]);
  const [isFlagged, setIsFlagged] = useState(false);
  const formRef = useRef<any>();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!invoice) return;
    setFormItems(
      invoice.items.map((i) => ({
        id: i.id,
        modifiers: i.modifiers,
        itemName: i.itemName,
        qtyOrdered: i.qtyOrdered,
        originalReceived: i.totalReceived || 0,
        totalSent: i.totalSent || 0,
        totalReceived: (i.totalReceived || 0) + (i.qtySent || 0),
        qtySent: i.qtySent,
        qtyReceived: i.qtySent,
      })),
    );
  }, [invoice]);

  useEffect(() => {
    const items = formItems.filter((i) => parseInt(i.qtyReceived) === i.qtySent);
    const flagged = items.length !== formItems.length;
    setIsFlagged(flagged);
    if (formRef?.current) {
      formRef.current.setFieldTouched('noteStore', true);
      setTimeout(() => {
        formRef.current.validateForm(formRef.current.values);
        formRef.current.validateField('noteStore');
      }, 100);
    }
  }, [formItems]);

  if (!invoice) return null;
  const { id } = invoice;

  const onChangeQty = (qty, index) => {
    const newQty = Number(qty);
    setFormItems(
      formItems.map((item, i) => {
        if (i !== index) return item;
        return {
          ...item,
          qtyReceived: newQty,
          totalReceived: item.originalReceived + newQty,
        };
      }),
    );
  };

  const onSubmit = async (values) => {
    const items = formItems.map((i) => ({
      id: i.id,
      qtyReceived: parseInt(i.qtyReceived),
      totalReceived: i.totalReceived,
    }));

    await dispatch(invoiceActions.closeInvoice(id, items, isFlagged, values.noteStore));
    onCloseInvoice();
  };

  const CLOSE_FORM = getCloseForm(isFlagged);
  const validateForm = (values) => input.validateError(CLOSE_FORM, values);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{ noteStore: '' }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <Div className="content-wrapper">
              <CloseOrderList
                items={formItems}
                onChangeQty={onChangeQty}
                isRegenerated={!!invoice.revision}
              />
              <Div padding={SPACE.LARGE}>
                {invoice?.noteFactory && (
                  <div className="flag-message">
                    <div>{intl('SCREEN.INVOICES.NOTE_FROM_FACTORY')}</div>
                    <div className="">"{invoice?.noteFactory}"</div>
                  </div>
                )}
                <FormSection FORM={CLOSE_FORM} formik={formik} />
                {/* <div className="notes-label">{intl('SCREEN.INVOICES.NOTES')}</div>
                  <textarea
                    onInput={onChangeNotes}
                    className="notes close"
                    placeholder={intl('INPUT.PLACEHOLDER.NOTES')}
                  /> */}
              </Div>
            </Div>
          </IonContent>
          <Footer justifyContent="space-around">
            <Button className="no-print third" variant="outlined" onClick={onClose}>
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button
              className="no-print third"
              disabled={!formik.isValid || (isFlagged && !formik.values.noteStore)}
              onClick={formik.handleSubmit}>
              {intl('BUTTON.CONFIRM')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default CloseOrderDetails;
