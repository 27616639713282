import styled from 'styled-components';
import { IonMenu } from '@ionic/react';

import { configs } from '$configs';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { ellipsis } from '$gstyles/mixins';

export const Wrapper = styled(IonMenu)`
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: 300px;
    --side-min-width: 300px;
  }

  border-width: 0px;

  ion-content {
    --background: #fff;
  }

  ion-title {
    color: var(--ion-color-tertiary-contrast-bw);
    text-transform: uppercase;
  }

  .cart-close {
    color: var(--ion-color-tertiary-contrast);
  }

  .cart-list {
    .cart-item {
      padding: ${SPACE.MEDIUM};
    }
  }

  .user-name {
    margin-left: ${SPACE.SMALL};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .item-count {
    height: 44px;
    margin-left: ${SPACE.MEDIUM};
    font-size: ${FONT.SMALL};
    /* color: var(--ion-color-secondary);
    background-color: var(--ion-color-light); */
  }

  .cancel-button {
    background-color: #f6f6f6;
  }

  .clear-cart {
    font-size: ${FONT.XSMALL};
    color: var(--ion-color-secondary);
    .MuiSvgIcon-root {
      font-size: ${FONT.LARGE};
    }
  }

  ion-footer {
    padding: ${SPACE.LARGE};
    background-color: #e6f7ff;
    color: var(--ion-color-primary);
    text-transform: uppercase;
    font-weight: ${WEIGHT.SEMI_BOLD};
    button {
      font-size: ${FONT.MEDIUM};
      text-transform: uppercase !important;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    button:not(.checkout-button) {
      margin-bottom: ${SPACE.MEDIUM};
    }
    .total-section {
      justify-content: space-between;
      font-size: ${FONT.LARGE};
      .label {
        opacity: 0.7;
      }
      .price {
        color: var(--ion-color-primary);
      }
      margin-bottom: ${SPACE.LARGE};
    }
  }

  .instruction {
    width: 144px;
  }
`;

export const CartListWrapper = styled(Div)`
  padding: ${SPACE.LARGE};

  .cart-item {
    justify-content: space-between;
    align-items: flex-start;
    min-height: 44px;
    margin-bottom: ${SPACE.MEDIUM};

    &.header {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    &.with-mod {
      min-height: 50px;
    }
    .item-text {
      line-height: 14px;
      padding-right: ${SPACE.SMALL};
    }
    .instruction {
      ${ellipsis}
      font-size: 0.85em;
      opacity: 0.8;
    }
    .name {
      font-size: 1em;
      color: #000;
    }
    .mod {
      opacity: 0.8;
      font-size: 0.8em;
    }
    .price {
      opacity: 0.8;
      font-size: 0.85em;
    }
    .quantity {
      min-width: 44px;
      text-align: right;
    }
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;

export const OrderSummaryWrapper = styled(Div)`
  padding: ${SPACE.LARGE};

  .title {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.MEDIUM};
    padding: ${SPACE.MEDIUM} ${SPACE.MEDIUM};
  }
  .box {
    padding: ${SPACE.MEDIUM};
    background-color: #f1f1f1;
    .line {
      display: flex;
      justify-content: space-between;
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: ${FONT.SMALL};
      height: 24px;
    }
    .line.total {
      font-size: 1.1em;
      color: var(--ion-color-primary);
    }
  }
`;
