import React from 'react';
import intl from '$intl';

import { ModalWrapper } from '../styles';
import InvoiceModel from '$fbusiness/models/invoice';
import CloseOrderDetails from './closeOrderDetails';

interface CloseOrderModalProps {
  show: boolean;
  invoice: InvoiceModel;
  onClose: () => void;
  onCloseInvoice: () => void;
}

const CloseOrderModal: React.FC<CloseOrderModalProps> = ({ show, invoice, onClose, onCloseInvoice }) => {
  return (
    <ModalWrapper
      titleText={intl('SCREEN.INVOICES.TITLE_CLOSE', { orderId: invoice?.orderId || '0000' })}
      width="700px"
      show={show}
      onClose={() => onClose()}
      useCustom>
      {invoice && <CloseOrderDetails invoice={invoice} onClose={onClose} onCloseInvoice={onCloseInvoice} />}
    </ModalWrapper>
  );
};

export default CloseOrderModal;
