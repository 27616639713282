import { Modal } from '$gcomponents/reusables';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .metadata {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .memo {
      font-size: ${FONT.SMALL};
      opacity: 0.8;
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .list {
    max-width: 500px;
    margin: auto;
    font-size: ${FONT.MEDIUM};
  }
`;

export const StoreSelectorWrapper = styled(Div)`
  &.oneline {
    display: flex;
    height: 47px;
    align-items: flex-start;
    padding: 0 ${SPACE.LARGE};

    .store-selector {
      min-width: 300px;
    }
    ion-item {
      --min-height: 42px;
      ion-label {
        margin-top: -10px !important;
      }
    }
    ion-select {
      padding: 4px 0;
    }
  }
`;
