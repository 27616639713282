import StoreModel, { deriveRawToStore } from './store';

export default interface DailyReportItemModel {
  id: number;
  userId: number;
  author: string;
  regionId: number;
  regionName: string;
  storeId: number;
  customer: string;
  store?: StoreModel;
  reportDate: string;
  yes?: 0 | 1;
  checkNo: string;
  checkAmount: number;
  cashAmount: number;
  total: number;
  invoiceNo: string;
  note: string;
  settings?: any;
}

export const deriveRawToDailyReportItem = (raw) => {
  return {
    id: raw.id,
    userId: raw.userId,
    author: raw.author,
    regionId: raw.regionId,
    regionName: raw.region,
    storeId: raw.storeId,
    customer: raw.customer,
    reportDate: raw.reportDate,
    yes: raw.yes,
    checkNo: raw.checkNo,
    checkAmount: raw.checkAmount,
    cashAmount: raw.cashAmount,
    total: raw.total,
    invoiceNo: raw.invoiceNo,
    note: raw.note,
    ...(raw.store && { store: deriveRawToStore(raw.store) }),
    settings: {
      ...(raw.settings || {}),
    },
  };
};

export const initialDailyReportItem: DailyReportItemModel = {
  id: 0,
  regionId: 0,
  userId: 0,
  storeId: 0,
  regionName: '',
  author: '',
  customer: '',
  reportDate: '',
  yes: 0,
  checkNo: '',
  checkAmount: 0,
  cashAmount: 0,
  total: 0,
  invoiceNo: '',
  note: '',
};
