import React from 'react';
import intl from '$intl';

import { LegendWrapper } from './styles';
import { Flex } from '$gstyles/wrapper';

interface LegendProps {
  title?: string;
  legends: Array<string>;
  width?: string;
  align?: string;
  borderColor?: string;
  scale?: number;
  backgroundColor?: string;
}

const Legend: React.FC<LegendProps> = ({
  legends,
  scale,
  title,
  align,
  width,
  borderColor,
  backgroundColor,
}) => {
  const options = legends.map(key => ({
    label: intl('INPUT.LEGEND.LABEL.' + key),
    value: intl('INPUT.LEGEND.VALUE.' + key),
  }));

  return (
    <LegendWrapper
      align={align}
      scale={scale}
      borderColor={borderColor}
      width={width}
      backgroundColor={backgroundColor}>
      {title && <div className="title">{intl(title)}</div>}
      {options.map((leg, i) => (
        <Flex className="legend-item" key={i}>
          <div className="label">{leg.label}</div>
          <div className="value">{leg.value}</div>
        </Flex>
      ))}
    </LegendWrapper>
  );
};

export default Legend;
