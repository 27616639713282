import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import Header from '$fcomponents/header';

import { IonPageWrapper } from './styles';
import { PageWrapper } from '$gstyles/wrapper';
import Img from '$gcomponents/primitives/img';
import { configs } from '$configs';
import IonContentWrapper from '$fcomponents/ionContentWrapper';

interface AboutUsScreenProps {
  footer: any;
}

const AboutUsScreen: React.FC<AboutUsScreenProps> = () => {
  return (
    <IonPageWrapper>
      <Header title="SCREEN.ABOUT_US.TITLE" />
      <IonContentWrapper>
        <PageWrapper maxWidth="900px">
          <h2>Overview</h2>
          <p>
            <Img
              className="image"
              width="500px"
              height="220px"
              src={configs.display.imagePath + 'aboutus.jpeg'}
            />
            Lucky Supply Inc, located in the heart of metro NYC, is a distribution hub of paper/plastic
            disposables and supplies.&nbsp; Established in 2002, Lucky Supply Inc. provides a wide variety of
            business essential supplies to meet the demanding needs of retail chains as well as small
            businesses alike.&nbsp; Lucky Supply has been a leading&nbsp; importer of restaurant, deli,
            grocery and food service &amp; retail products from all over the world and a large selection of
            locally sourced products at a very competitive prices.
          </p>
          <p>
            Our trucking fleet provides daily delivery service to local retailers.&nbsp; Contact us to see if
            your store is in our service area.
          </p>
          <h2>Goal:</h2>
          <p>
            The goal at Lucky Supply Inc. is to provide our customers with good quality products at a
            reasonable price.&nbsp; We&rsquo;re constantly on the look out for innovative products that our
            customers want at a price that won&rsquo;t break the bank.&nbsp;
          </p>
          <h3>Locations:</h3>
          <p>
            Maspeth, NY off exit 18 on I-495.&nbsp;
            <br />
            59-50 54th Ave
            <br />
            Maspeth, NY 11378
            <br />
            (718) 898-8979
            <br />
            (718) 898-6017 (Fax)
          </p>
          <p>
            Korea office:
            <br />
            3rd Floor. 51-13
            <br />
            ​463-Gil Ilsandong-ku
            <br />
            Seoul, South Korea
            <br />
            (T) 82-031-912-6593
            <br />
            (M) 82-010-3243 6593
            <br />
            Email: <a href="mailto:intertradekr@gmail.com">intertradekr@gmail.com</a>
          </p>
          <h3>Directions:</h3>
          <p>West bound on I-495:</p>
          <ul>
            <li>Take exit 18 (Maurice Ave)</li>
            <li>Rt on Maurice Ave</li>
            <li>Lt on 54th&nbsp; Ave</li>
            <li>The warehouse is on the left</li>
          </ul>
          <p>East bound on I-495:</p>
          <ul>
            <li>Take exit 18 (Maurice Ave)</li>
            <li>Lt on Maurice Ave</li>
            <li>Lt on 54th&nbsp; Ave</li>
            <li>The warehouse is on the left</li>
          </ul>
          <p>&nbsp;</p>
        </PageWrapper>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const connected = connect(null, null);

export default connected(screen(AboutUsScreen));
