import React, { Component } from 'react';
import { withIonLifeCycle } from '@ionic/react';

import { routerActions } from '$gbusiness/redux/router';
import store from '$business/redux/'; // PRODUCT DEPENDENCY
import { clog } from '$gbusiness/helpers';
import Header from '$gcomponents/widgets/header';
import FactoryModel from '$fbusiness/models/factory';

interface AsScreenViewProps {
  onHydrate?: Function;
  onDehydrate?: Function;
  factory?: FactoryModel;
  feature?: string;
  history?: any;
  match?: any;
  resetOnRoute?: false;
}

export const emptyComponent = <Header title="SCREEN.EMPTY.TITLE" />;

const screen = (ScreenView): any => {
  class AsScreenView extends Component<AsScreenViewProps> {
    static defaultProps: AsScreenViewProps = {
      onHydrate: () => {},
      onDehydrate: () => {},
    };

    state = {
      isDestroyed: false,
      shouldAccess: false,
    };

    ionViewWillEnter() {
      const { match, resetOnRoute } = this.props;

      if (resetOnRoute) this.setState({ isDestroyed: false });
      if (match && match.path) {
        store.dispatch(routerActions.setRoute(match.url, match?.params));
      }
      if (this.props.onHydrate) this.props.onHydrate(match?.params);
    }

    ionViewWillLeave() {
      const { onDehydrate, resetOnRoute } = this.props;
      if (onDehydrate) onDehydrate();
      if (resetOnRoute) this.setState({ isDestroyed: true });
    }

    render() {
      const { factory, feature = '' } = this.props;
      if (this.state.isDestroyed) {
        clog('DESTROYED', this.props.match?.url);
        return null;
      }

      if (feature && factory && factory.settings && !factory.settings[feature]) {
        return emptyComponent;
      }

      return <ScreenView {...this.props} />;
    }
  }

  return withIonLifeCycle(AsScreenView);
};

export default screen;
