import _ from 'lodash';
import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { FastField, Field } from 'formik';

import { MASKS, MODES, TYPES, InputModeType } from '$gbusiness/enums/input';
import intl from '$gintl';
import { configs } from '$configs';
import { mask } from '$gbusiness/helpers';
import { IonIcon } from '@ionic/react';

import { BSLabel, Img, FormControlWrapper } from './styles';
import { getCardPath } from '$gbusiness/helpers/util';

interface TextInputProps {
  icon?: string;
  label?: string;
  labelText?: string;
  prefix?: string;
  postfix?: string;
  name: string;
  readonly?: boolean;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  maskType?: string | undefined;
  decimalPlaces?: number;
  multiline?: boolean;
  maxAmount?: number;
  rowsMax?: number;
  rows?: number;
  type?: string;
  maxLength?: number | undefined;
  inputMode?: InputModeType;
  formik?: any;
  size?: string;
  shrink?: boolean;
  enterToSubmit?: boolean;
  handleChange?: (e) => void;
  handleBlur?: Function;
  disableFastField?: boolean;
  handleKeyPress?: Function;
}

const defaultProps: TextInputProps = {
  icon: '',
  label: '',
  labelText: '',
  prefix: '',
  postfix: '',
  name: '',
  className: '',
  readonly: false,
  disabled: false,
  placeholder: '',
  multiline: false,
  decimalPlaces: 2,
  rowsMax: 40,
  maxAmount: 999999999,
  rows: 2,
  shrink: false,
  inputMode: 'text',
  position: undefined,
  formik: {},
  size: configs.display.inputSize,
  enterToSubmit: false,
  type: TYPES.TEXT,
  handleChange: () => {},
  handleBlur: () => {},
  disableFastField: false,
  handleKeyPress: () => {},
};

const getType = (inputType) => {
  return inputType || 'text';
};

const TextInput: React.FC<TextInputProps> = ({
  icon,
  label,
  labelText,
  prefix,
  className,
  postfix,
  name,
  readonly,
  disabled,
  placeholder,
  shrink,
  decimalPlaces,
  position,
  inputMode,
  type,
  size,
  multiline,
  rowsMax,
  rows,
  maxAmount,
  maxLength,
  maskType,
  formik,
  enterToSubmit,
  handleChange,
  handleBlur,
  handleKeyPress,
  disableFastField,
  ...props
}) => {
  const maskInput = (value) => {
    switch (maskType) {
      case MASKS.PHONE:
        formik.setFieldValue(name, mask.phone(value));
        break;
      case MASKS.SSN:
        formik.setFieldValue(name, mask.ssn(value));
        break;
      case MASKS.CARD_NUMBER:
        formik.setFieldValue(name, mask.cardNumber(value));
        break;
      case MASKS.EXPIRY:
        formik.setFieldValue(name, mask.expiry(value));
        break;
      // case MASKS.CURRENCY:
      //   formik.setFieldValue(name, value);
      default:
        break;
    }
  };

  const onKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (enterToSubmit && charCode === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
    if (type === TYPES.NUMBER || inputMode === MODES.NUMERIC) {
      if (
        (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
        (e.ctrlKey === true || e.metaKey === true)
      )
        return;
      if (
        type !== TYPES.NUMBER &&
        charCode !== 46 &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 96 || charCode > 105)
      ) {
        e.preventDefault();
      }
    }
    if (handleKeyPress) handleKeyPress(e);
    return true;
  };

  const onKeyUp = (e) => {
    maskInput(e.target.value);
  };

  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const onBlur = (e) => {
    // any blue handling goes here
    if (formik.handleBlur) formik.handleBlur(e);
    if (handleBlur) handleBlur(e);

    if (maskType === MASKS.CURRENCY) formik.setFieldValue(name, e.target.value);
  };

  let derivedMaxLength = maxLength;
  if (maskType === MASKS.PHONE) derivedMaxLength = 12;
  else if (maskType === MASKS.SSN) derivedMaxLength = 11;

  let pattern;
  if (inputMode === MODES.NUMERIC) pattern = '[0-9]*';

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' ? 'small' : 'medium';
  const labelProps =
    position !== undefined
      ? {
          label: intl(label),
          InputLabelProps: {
            ...(shrink && { shrink }),
          },
        }
      : {};
  const variant = position || 'outlined';

  let startAdornment =
    icon !== '' ? (
      <InputAdornment position="start">
        <IonIcon slot="start" icon={icon} />
      </InputAdornment>
    ) : prefix !== '' ? (
      <InputAdornment position="start">{intl(prefix)}</InputAdornment>
    ) : undefined;
  let endAdornment =
    postfix !== '' ? <InputAdornment position="end">{intl(postfix)}</InputAdornment> : undefined;

  const FormField = disableFastField ? Field : FastField;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          const value =
            typeof _.get(form.values, name) === 'number'
              ? _.get(form.values, name)
              : _.get(form.values, name) || '';
          if (maskType === MASKS.CARD_NUMBER) {
            const imagePath = getCardPath(value);
            endAdornment = (
              <InputAdornment position="end">
                <Img src={imagePath} />
              </InputAdornment>
            );
          }

          if (maskType === MASKS.CURRENCY) {
            return (
              <FormControlWrapper className={className} size={inputSize} variant={variant} fullWidth>
                {position === undefined && <BSLabel className="bs-label">{labelText || intl(label)}</BSLabel>}
                <CurrencyTextField
                  {...labelProps}
                  className={`${readonly && 'readonly currency'} ${!postfix && 'left'}`}
                  inputProps={{
                    pattern: pattern,
                    maxLength: derivedMaxLength,
                  }}
                  InputProps={{
                    endAdornment,
                  }}
                  {...props}
                  type="text"
                  maximumValue={maxAmount?.toString()}
                  decimalPlaces={decimalPlaces}
                  name={name}
                  modifyValueOnWheel={false}
                  disabled={disabled}
                  placeholder={intl(placeholder) || undefined}
                  onKeyDown={onKeyPress}
                  onKeyUp={onKeyUp}
                  size={inputSize}
                  //onChange={onChange}
                  onBlur={onChange}
                  value={value}
                  error={hasError}
                  variant={variant}
                  helperText={errorMsg}
                  inputMode="text"
                  currencySymbol={intl(postfix === 'CURRENCY.PERCENT' ? '' : 'CURRENCY.SYMBOL')}
                />
              </FormControlWrapper>
            );
          }

          return (
            <FormControlWrapper className={className} size={inputSize} variant={variant} fullWidth>
              {position === undefined && <BSLabel className="bs-label">{intl(label)}</BSLabel>}
              <TextField
                {...labelProps}
                inputProps={{
                  pattern: pattern,
                  maxLength: derivedMaxLength,
                }}
                InputProps={{
                  startAdornment,
                  endAdornment,
                }}
                {...props}
                className={`${readonly && 'readonly'}`}
                multiline={multiline}
                maxRows={multiline ? rowsMax : undefined}
                rows={multiline ? rows : undefined}
                type={getType(type)}
                name={name}
                disabled={disabled}
                onKeyDown={onKeyPress}
                onKeyUp={onKeyUp}
                placeholder={intl(placeholder) || undefined}
                size={inputSize}
                onBlur={onBlur}
                onInput={onChange}
                value={value}
                error={hasError}
                variant={variant}
                helperText={errorMsg}
              />
            </FormControlWrapper>
          );
        }}
      </FormField>
    </>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
