import React from 'react';
import intl from '$intl';

import { ModalWrapper } from '../styles';
import InvoiceModel from '$fbusiness/models/invoice';

import CurrentStateModel from '$fbusiness/models/currentState';
import ViewShippingDetails from './viewShippingDetails';

interface ViewShippingModalProps {
  show: boolean;
  currentState: CurrentStateModel;
  invoice: InvoiceModel;
  onClose: () => void;
}

const ViewShippingModal: React.FC<ViewShippingModalProps> = ({ show, currentState, invoice, onClose }) => {
  return (
    <ModalWrapper
      titleText={intl('SCREEN.INVOICES.TITLE_SHIPPING', { orderId: invoice?.order?.orderId })}
      show={show}
      onClose={() => onClose()}
      useCustom>
      {invoice && <ViewShippingDetails invoice={invoice} currentState={currentState} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default ViewShippingModal;
