import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .header-title {
    margin: 0 !important;
    padding-top: ${SPACE.LARGE} !important;
  }
  .actions {
    button {
      border-radius: 0%;
      font-size: ${FONT.MEDIUM};
      text-transform: uppercase;
      padding: ${SPACE.LARGE};
    }
    .order-button {
      min-width: 200px;
    }
  }
  .split-container {
    flex-direction: row-reverse;

    .order-summary {
      flex: 1;
      min-width: 300px;
    }
    .form-section {
      flex: 2;
    }
  }

  ${mobileStyle(`
    .split-container {
      .order-summary {
        flex: none;
        width: 100%;
      }
      .form-section {
        flex: none;
        width: 100%;
      }
    }
  `)}
`;
