import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToUser, emptyUser } from '$gbusiness/models/user';
import {
  UserActionTypes,
  FETCH_USER_SUCCESS,
  CLEAN_USER,
  SAVE_USER_SUCCESS,
  USER_FAILURE,
  FETCH_USERS_SUCCESS,
  RESET_FINISHED,
} from './types';
import { LOADING } from '$gbusiness/redux/loading/types';
import { generateGetParam } from '$gbusiness/helpers/util';

export function fetchUser(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_USER_SUCCESS,
        user: emptyUser,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.users.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, USER_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_USER_SUCCESS,
      user: deriveRawToUser(response.data),
    });
  };
}

export function fetchUsers(param: any): any {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
    });

    const newParam = generateGetParam(param);

    const response = await fetchApi({
      url: configs.api.users.general + (newParam && `?${newParam}`),
      param,
      method: 'GET',
    });

    if (!response || !response.list) {
      return;
    }

    dispatch({
      type: FETCH_USERS_SUCCESS,
      users: response.list.map(deriveRawToUser),
    });
  };
}

export function saveUser(userId, user): any {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SAVING',
    });

    const response = await fetchApi({
      url: configs.api.users.general + (userId ? '/' + userId : ''),
      param: {
        ...user,
        ...(user.firstName && { first_name: user.firstName }),
        ...(user.lastName && { last_name: user.lastName }),
      },
      method: userId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, USER_FAILURE, response, 'MESSAGE.SAVE_FAIL', true);
      return;
    } else {
      handleApiSuccess(dispatch, SAVE_USER_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteUser(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.users + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, USER_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(null, null, 'MESSAGE.DELETE_SUCCESS', 'small');
    }
  };
}

export function resetFinished(): UserActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): UserActionTypes {
  return { type: CLEAN_USER };
}
