import { today, toLocal } from '$gbusiness/helpers/date';
import { roundDecimal } from '$gbusiness/helpers/util';
import UserModel, { deriveRawToUser } from '$gbusiness/models/user';
import { KEYS } from '../enums/options/orderStatus';
import { reconcileItems } from '../redux/cart/utils';
import InvoiceItemModel, { deriveRawToInvoiceItem } from './invoiceItem';
import InvoicePaymentModel, { deriveRawToinvoicePayment } from './invoicePayment';
import OrderModel, { deriveRawToOrder } from './order';
import OrderInvoice, { deriveRawToOrderInvoice } from './orderInvoice';
import { recalculateItemPrice } from './orderInvoiceItem';
import { deriveRawToPrice } from './price';
import RefundModel, { deriveRawToRefund } from './refund';
import StoreModel, { deriveRawToStore } from './store';
import TermModel from './term';

export default interface InvoiceModel extends OrderInvoice {
  orderId: number;
  refund?: RefundModel;
  uuid: string;
  invoiceNumber?: string;
  revision: number;
  qtyReceived: number;
  qtySent: number;
  termId: number;
  term?: TermModel;
  isClosed: boolean;
  noteFactory: string;
  noteStore: string;
  pastDue: boolean;
  dueDate?: string;
  discountDate?: string;
  detailedData?: any;
  overdue: boolean;
  paidAmount: number;
  creditAmount: number;
  refundAmount: number;
  paymentDiscount: number;
  balance?: number;
  paymentMemo: string;
  payments: Array<InvoicePaymentModel>;
  sentAt?: string;
  paidAt?: string;
  clawBack?: boolean;
  flaggedAt?: string;
  confirmedAt?: string;
  closedAt?: string;
  createdAt?: string;
  order?: OrderModel;
  store?: StoreModel;
  user?: UserModel;
  items: Array<InvoiceItemModel>;
}

const derivePastDue = (raw) => {
  const { pastDue, dueDate } = raw;
  if (pastDue) return pastDue;
  if (!dueDate || today() <= dueDate) return 'NO';
  return 'YES';
};

const deriveInvoiceStatus = (raw) => {
  return raw.status;
  // if (raw.statusFactory) return raw.statusFactory;
  // else return raw.status;
};

const deriveOverdue = (raw) => {
  return today() > raw.dueDate;
};

export const deriveRawToInvoice = (raw) => {
  return {
    id: raw.id,
    storeId: raw.storeId,
    uuid: raw.uuid,
    ...deriveRawToOrderInvoice(raw),
    ...(raw.refundId && { refundId: raw.refundId, refund: deriveRawToRefund(raw.refund) }),
    qty: Number(raw.qty),
    qtyReceived: Number(raw.qtyReceived),
    qtySent: Number(raw.qtySent),
    invoiceNumber: raw.invoiceNumber,
    revision: raw.revision,
    overdue: deriveOverdue(raw),
    noteFactory: raw.noteFactory,
    noteStore: raw.noteStore,
    isClosed: raw.status === KEYS.CLOSED,
    status: deriveInvoiceStatus(raw),
    pastDue: derivePastDue(raw),
    dueDate: raw.dueDate,
    discountDate: raw.discountDate,
    paymentDiscount: raw.paymentDiscount || 0,
    ...(raw.sentAt && { sentAt: toLocal(raw.sentAt) }),
    paidAmount: raw.paidAmount || 0,
    payments: (raw.payments || []).map(deriveRawToinvoicePayment),
    refundAmount: raw.refundAmount || 0,
    creditAmount: raw.creditAmount || 0,
    balance: raw.balance || 0,
    paymentMemo: raw.paymentMemo || '',
    termId: raw.termId,
    ...(raw.term && { term: raw.term }),
    clawBack: raw.clawBack ? 1 : 0,
    createdAt: toLocal(raw.createdAt),
    shippedAt: toLocal(raw.createdAt),
    confirmedAt: toLocal(raw.confirmedAt),
    paidAt: toLocal(raw.paidAt),
    flaggedAt: toLocal(raw.flaggedAt),
    closedAt: toLocal(raw.closedAt),
    ...(raw.order && { order: deriveRawToOrder(raw.order) }),
    store: deriveRawToStore(raw.store),
    user: deriveRawToUser(raw.user),
    detailedData: raw.detailedData || {},
    items: (raw.items || []).map((item) => deriveRawToInvoiceItem(item, true)),
  };
};

export const recalculateInvoice = (
  invoice,
  { fixedItems = [], fixedQty = false, mutable = false, noTax = false },
) => {
  const {
    otherDiscount,
    paymentDiscount = 0,
    shipping,
    taxRate: originalTaxRate,
    delivery,
    refund,
    tip,
  } = invoice;
  const taxRate = noTax ? 0 : originalTaxRate;
  const items = fixedItems.length ? fixedItems : reconcileItems(invoice.items, 'qtySent');
  const price = items.reduce(
    (acc, item) => {
      const modifiedItem = fixedItems.length ? item : recalculateItemPrice(item, null, fixedQty, noTax);
      acc.items.push(modifiedItem);
      const { subtotal, taxable, tax, discount, total, qty, qtySent, qtyReceived, totalSent, totalReceived } =
        modifiedItem;
      acc.subtotal = acc.subtotal + subtotal;
      acc.discount = acc.discount + discount;
      acc.taxable = acc.taxable + taxable;
      acc.tax = acc.tax + tax;
      acc.total = acc.total + total;
      acc.qty = acc.qty + qty;
      acc.qytSent = acc.qtySent + qtySent;
      acc.qtyReceived = acc.qtyReceived + qtyReceived;
      acc.totalSent = acc.totalSent + totalSent;
      acc.totalReceived = acc.totalReceived + totalReceived;
      return acc;
    },
    {
      items: [],
      subtotal: 0,
      discount: 0,
      taxable: 0,
      tax: 0,
      total: 0,
      qty: 0,
      qtySent: 0,
      qtyReceived: 0,
      totalReceived: 0,
      totalSent: 0,
    },
  );
  const totalOtherDiscount = otherDiscount + paymentDiscount;
  const adjustedOtherDiscount = Math.min(totalOtherDiscount, price.taxable);
  const totalDiscount = adjustedOtherDiscount + price.discount;
  const taxable = price.taxable - adjustedOtherDiscount + shipping;
  const shippingTax = Math.round(shipping * taxRate) / 100;
  const otherDiscountTax = Math.round(adjustedOtherDiscount * taxRate) / 100;
  const tax = shippingTax - otherDiscountTax + price.tax;
  const total = taxable + tax + delivery + tip;
  const refundAmt = Math.min(refund?.balance || 0, total);
  const savedRate = price.subtotal ? roundDecimal((totalDiscount * 100) / price.subtotal) : 0;
  return {
    ...invoice,
    ...(mutable && { items: price.items }),
    subtotal: price.subtotal,
    itemTaxable: price.taxable,
    itemTax: price.tax,
    itemTotal: price.taxable + price.tax,
    itemDiscount: price.discount,
    totalDiscount,
    taxable,
    refundAmt,
    tax: taxable === 0 || tax < 0 ? 0 : tax,
    total,
    savedRate,
  };
};

export function calculatePriceFromInvoice(invoice) {
  return {
    ...deriveRawToPrice(invoice),
    itemDiscount: invoice.subtotal - invoice.itemTaxable || 0,
  };
}

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 63,
//   list: [
//     '{{repeat(5, 20)}}',
//     {
//       id: "{{index() + 1}}",
//       orderId: "{{index() + 1}}",
//       storeId: 1,
//       factoryId: 1,
//       uuid: "{{guid()}}",
//       status: "{{random('PENDING', 'PAID', 'SENT')}}",
//       invoiceNumber: "{{integer(1000000, 9999999)}}",
//       totalAmount: "{{floating(100, 5100, 2)}}",
//       noteFactory: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       noteStore: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       dueDate: "{{date(new Date(), new Date(2021, 10, 10), 'YYYY-MM-dd')}}",
//       createdAt: "{{date(new Date(2021, 7, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       sentAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       paidAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
