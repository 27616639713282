import styled from 'styled-components';
import Div from './div';

export const Triangle = styled(Div)`
  width: 0;
  height: 0;
  border-left: ${p => p.size}px solid transparent;
  border-right: ${p => p.size}px solid transparent;
  border-bottom: ${p => p.size}px solid ${p => p.color};
  ${p => (p.direction === 'right' ? 'transform: rotate(90deg);' : '')}
  ${p => (p.direction === 'down' ? 'transform: rotate(180deg);' : '')}
  ${p => (p.direction === 'left' ? 'transform: rotate(270deg);' : '')}
`;
