import React from 'react';
import intl from '$intl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

interface DateFilterProps {
  value: any;
  onChange: Function;
}

const DateFilter: React.FC<DateFilterProps> = ({ value, onChange }) => {
  return (
    <div className="report-date-picker">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={intl('SCREEN.DAILY_REPORT.ITEM.DATE')}
          value={value}
          maxDate={new Date()}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateFilter;
