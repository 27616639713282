import React from 'react';
import { COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

// import Img from '$gcomponents/primitives/img';
import CellModel from '$gbusiness/models/cell';
import { currency } from '$gbusiness/helpers/util';

export const SHIPPING_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 4px 8px`,
    responsive: false,
    isStripped: false,
    rowHeight: 44,
    headerHeight: 44,
  },
};

export const SHIPPING_TABLE = ({ isRegenerated, hidePrice }): Array<CellModel> => [
  // {
  //   label: '',
  //   value: 'itemId',
  //   className: 'image',
  //   align: 'center',
  //   component: row =>
  //     row.image && <Img src={row.image} cache={timestamp(row.updatedAt)} width="32" alt="item img" />,
  //   alwaysShow: true,
  //   width: 100,
  // },
  {
    label: 'SCREEN.INVOICES.COLS.YOUR_ORDER',
    value: 'itemName',
    className: 'main',
    component: (row) => (
      <div>
        <div>{row.itemName}</div>
        {row.modifiers?.length > 0 && (
          <div className="mod">({row.modifiers.map((i) => i.name).join(', ')})</div>
        )}
      </div>
    ),
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY',
    value: 'qtyOrdered',
    align: 'center',
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.QTY_SENT_TOTAL',
    value: 'totalSent',
    align: 'center',
    alwaysShow: true,
    width: 120,
  },
  {
    label: 'SCREEN.INVOICES.COLS.ITEM_SHIPPED',
    value: 'qtySent',
    align: 'center',
    className: 'desktop',
    width: 120,
  },
  {
    isHidden: !isRegenerated || hidePrice,
    label: 'SCREEN.INVOICES.COLS.SUBTOTAL',
    value: 'subtotal',
    align: 'right',
    component: (row) => <div>{currency(row.subtotal)}</div>,
    className: 'desktop',
    width: 120,
  },
];
