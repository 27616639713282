import React from 'react';
import { connect } from 'react-redux';
import { screen } from '$gcomponents/hoc';
import { SPACE, Wrapper } from '$gstyles';

import { IonPageWrapper, SideMenuWrapper } from './styles';
import { defaultDataSource } from '$gbusiness/models/table';
import { configs } from '$configs';
import { ApiList } from '$gcomponents/reusables';
import ItemSimpleView from '$fcomponents/itemSimpleView';
import ItemModel, { deriveRawToItem } from '$fbusiness/models/item';
import FeaturedCarousel from '$components/featuredCarousel';
import ItemListContainer from '$components/itemListContainer';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';
import { Flex } from '$gstyles/wrapper';
import { SplitOver } from '$gstyles';
import CategoryMenu from '$components/sideMenu/categoryMenu';
import CategoryModel from '$fbusiness/models/category';
import HomeSlider from './homeSlider';
import IonContentWrapper from '$fcomponents/ionContentWrapper';

interface HomeScreenProps {
  currentState: CurrentStateModel;
  categories: Array<CategoryModel>;
  items;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ categories, currentState }) => {
  const dataSource = {
    ...defaultDataSource,
    endpoint: configs.api.item.general,
    deriveToModel: deriveRawToItem,
    method: 'GET',
    pageSize: 30,
  };

  const { hidePrice, ebrochure } = currentState;

  return (
    <IonPageWrapper>
      <IonContentWrapper>
        <HomeSlider />
        <Flex alignItems="start" marginTop={SPACE.XXLARGE}>
          {false && (
            <SplitOver>
              <SideMenuWrapper>
                <CategoryMenu categories={categories} currentRoute="/home" disableToggle />
              </SideMenuWrapper>
            </SplitOver>
          )}
          <Wrapper className="main-section">
            <ItemListContainer currentState={currentState}>
              {(onAddCart, onViewItem, isWholesale) => (
                <>
                  <FeaturedCarousel
                    hidePrice={hidePrice}
                    ebrochure={ebrochure}
                    isWholesale={isWholesale}
                    onAddCart={onAddCart}
                    onClickItem={onViewItem}
                  />
                  <ApiList dataSource={dataSource} filters={{ factoryId: getPublicFactoryId() }}>
                    {(item: ItemModel, index) => (
                      <ItemSimpleView
                        item={item}
                        key={index}
                        onAddCart={onAddCart}
                        onClick={onViewItem}
                        isWholesale={isWholesale}
                        hidePrice={hidePrice}
                        ebrochure={ebrochure}
                      />
                    )}
                  </ApiList>
                </>
              )}
            </ItemListContainer>
          </Wrapper>
        </Flex>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  resetOnRoute: true,
  categories: state.category.categories,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(HomeScreen));
