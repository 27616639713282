import { initialValue } from './category';
import { TreeItem } from '@atlaskit/tree';

export default interface TreeModel {
  rootId: number;
  items: Array<TreeItemModel>;
}

export interface TreeItemModel extends TreeItem {}

export const flattenTree = (children, parentId) =>
  Array.prototype.concat.apply(
    children.map(x => ({ ...x, parentId: parentId || 0, children: x.children.map(c => c.id) })),
    children.map(x => flattenTree(x.children || [], x.id)),
  );

export function flattenTreeSorted(
  categories,
  idAttr = 'id',
  parentAttr = 'parentId',
  childrenAttr = 'children',
  levelAttr = 'level',
) {
  function flattenChild(childObj, parentId, level) {
    let array: Array<any> = [];
    const childCopy = { ...childObj };
    childCopy[levelAttr] = level;
    childCopy[parentAttr] = parentId;
    childCopy.hasChildren = childCopy[childrenAttr].length > 0;
    delete childCopy[childrenAttr];
    array.push(childCopy);
    return array.concat(processChildren(childObj, level));
  }

  function processChildren(category, level = -1) {
    let array: Array<any> = [];
    (category[childrenAttr] || []).forEach(function(childObj) {
      array = array.concat(flattenChild(childObj, category[idAttr], level + 1));
    });
    return array;
  }

  return processChildren({ id: 0, children: categories });
}

export const getTreeNode = (categories, id) => {
  if (id === 0)
    return {
      ...initialValue,
      children: categories,
    };
  return categories.reduce((acc, cat) => {
    if (acc) return acc;
    if (cat.id === id) return cat;
    if (cat.children && cat.children.length) return getTreeNode(cat.children, id);
    return acc;
  }, null);
};

export const convertToTree = (categories, expands): TreeModel => {
  const newCategories = flattenTree(categories, 0);

  newCategories.unshift({
    ...initialValue,
    children: [],
  });

  newCategories[0].children = newCategories.filter(c => c.parentId === 0).map(c => c.id);

  return {
    rootId: 0,
    items: newCategories.reduce((acc, cat) => {
      acc[cat.id] = {
        id: cat.id,
        children: cat.children || [],
        hasChildren: cat.children && cat.children.length > 0,
        isExpanded: expands[cat.id] === undefined ? true : expands[cat.id],
        data: {
          ...cat,
          children: undefined,
        },
      };
      return acc;
    }, {}),
  };
};
