import React from 'react';

import { InputWrapper } from './styles';
import { IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

interface QuantityInputProps {
  value?: number;
  minValue?: number;
  maxValue?: number;
  onChange: Function;
  size?: number;
  color?: string;
}

const QuantityInput: React.FC<QuantityInputProps> = ({
  value = 1,
  minValue = 0,
  maxValue = 100,
  onChange,
  color = 'transparent',
  size = 16,
}) => {
  const changeQuantity = (num) => {
    const newValue = value + num;
    if (newValue < minValue || newValue > maxValue) return;
    onChange(newValue);
  };

  return (
    <InputWrapper size={size} color={color}>
      <IconButton
        onClick={() => {
          changeQuantity(-1);
        }}>
        <Remove />
      </IconButton>
      <div className="display">{value}</div>
      <IconButton
        onClick={() => {
          changeQuantity(1);
        }}>
        <Add />
      </IconButton>
    </InputWrapper>
  );
};

export default QuantityInput;
