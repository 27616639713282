import { FONT, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .metadata {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .memo {
      font-size: ${FONT.SMALL};
      opacity: 0.8;
    }
  }

  @media print {
    .report-date-picker,
    ion-buttons,
    ion-fab {
      display: none;
    }
    .ion-buttons,
    .MuiTable-root {
      .MuiTableRow-root {
        display: none !important;
        &.checked-row {
          display: flex !important;
        }
      }
    }
  }
`;
