import { calculateCartPrice } from '../redux/cart/utils';
import CartItemModel from './cartItem';
import DiscountModel, { initialDiscount } from './discount';
import { initialPrice, PriceModel } from './price';

export default interface CartModel {
  id: number;
  products: Array<CartItemModel>;
  factoryId?: number;
  price: {
    subtotal: number;
    total: number;
    taxable: number;
    tax: number;
    delivery: number;
    discount: number;
    shipping: number;
    tip: number;
    qty?: number;
  };
  pricing: {
    refund: PriceModel;
    po: PriceModel;
    final: PriceModel;
    taxRate: number;
    discountRate: number;
    otherDiscount: number;
    delivery: number;
    shipping: number;
    tip: number;
    discount: DiscountModel;
    refundDiscount: DiscountModel;
  };
  hasError?: boolean;
  stock: any;
  pointerIndex: number;
  settings?: any;
}

export const defaultCart: CartModel = {
  id: 0,
  products: [],
  factoryId: 0,
  price: {
    subtotal: 0,
    total: 0,
    taxable: 0,
    delivery: 0,
    shipping: 0,
    tax: 0,
    discount: 0,
    tip: 0,
    qty: 0,
  },
  pricing: {
    refund: { ...initialPrice },
    po: { ...initialPrice },
    final: { ...initialPrice },
    taxRate: 0,
    discountRate: 0,
    otherDiscount: 0,
    delivery: 0,
    shipping: 0,
    tip: 0,
    discount: { ...initialDiscount },
    refundDiscount: { ...initialDiscount },
  },
  stock: {},
  hasError: false,
  pointerIndex: 0,
  settings: {},
};

export const deriveRawToCart = (raw) => {
  const newCart = calculateCartPrice({ ...defaultCart, products: raw.cartDetails });
  return {
    id: raw.id,
    name: raw.name,
    qty: raw.cartDetails.length,
    pricing: newCart.pricing.po,
    shipping: raw.shipping,
    details: raw.cartDetails,
  };
};
