import styled from 'styled-components';
import { Div, SPACE, WEIGHT } from '$gstyles';

export const ScreenTitle = styled(Div)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: 2rem;
  padding: ${SPACE.MEDIUM} 0;
`;

export const ContentWrapper = styled(Div)`
  margin: auto;
  padding: ${SPACE.LARGE};
`;
