import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .add-button-wrapper {
    justify-content: flex-end;
    display: flex;
    align-items: flex-start;
    padding-top: ${SPACE.LARGE};
  }

  @media print {
    .report-date-picker,
    ion-buttons,
    ion-fab {
      display: none;
    }
    .ion-buttons,
    .MuiTable-root {
      .MuiTableRow-root {
        display: none !important;
        &.checked-row {
          display: flex !important;
        }
      }
    }
  }
`;
