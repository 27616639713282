import styled from 'styled-components';
import { InputLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const AutoCompleteWrapper = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-right: 16px !important;
  }
`;

export const BSLabel = styled(InputLabel)`
  &&.MuiFormLabel-root {
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;

export const TextFieldWrapper = styled(TextField)`
  &&.small {
    .MuiInputBase-root {
      padding: 0;
    }
    input {
      padding: 10.5px 14px !important;
    }
  }
`;
