import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import Slider from 'react-slick';

import { SideMenuStyle } from '$components/sideMenu/styles';
import { splitOverStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .main-section {
    overflow: hidden;
  }
`;

export const SliderWrapper = styled(Slider)`
  .banner-wrapper {
    height: calc(100vw / 3.1);
    min-height: 40px;
    background-color: transparent;
    background-position-y: 0%;
  }
  .slick-dots {
    button:before {
      font-size: 8px;
    }
  }

  ${splitOverStyle(`
    .banner-wrapper {
      height: calc((min(1400px, 100vw) - 240px) / 3.11);
    }
  `)}
`;

export const SideMenuWrapper = styled.div`
  ${SideMenuStyle}
`;
