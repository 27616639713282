import {
  CLEAN_FACTORY,
  CREATE_FACTORY_SUCCESS,
  DELETE_FACTORY_SUCCESS,
  FactoryActionTypes,
  FactoryReducerType,
  FACTORY_INIT_STATE,
  FETCH_FACTORY_SUCCESS,
  FETCH_SHIPPINGS_SUCCESS,
  FETCH_MY_FACTORY_SUCCESS,
  FETCH_STORE_SUCCESS,
  FETCH_TAXES_SUCCESS,
  FETCH_VENDORS_SUCCESS,
  RESET_FINISHED,
  UPDATE_FACTORY_SUCCESS,
  FETCH_FACTORIES_SUCCESS,
  FETCH_DISCOUNTS_SUCCESS,
  FETCH_COMMRULES_SUCCESS,
  FETCH_REGIONS_SUCCESS,
  FETCH_BANKS_SUCCESS,
  FETCH_TERMS_SUCCESS,
  FETCH_PERIODS_SUCCESS,
  UPDATE_FACTORY_SETTING_SUCCESS,
  FETCH_COMMPAY_SUCCESS,
} from './types';

export default function factoryReducer(
  state: FactoryReducerType = FACTORY_INIT_STATE,
  action: FactoryActionTypes,
): FactoryReducerType {
  switch (action.type) {
    case FETCH_FACTORY_SUCCESS:
      return {
        ...state,
        hisFactory: action.factory,
      };
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        store: action.store,
      };
    case FETCH_FACTORIES_SUCCESS:
      return {
        ...state,
        factories: action.factories,
      };
    case FETCH_MY_FACTORY_SUCCESS:
      return {
        ...state,
        factory: action.factory,
      };
    case UPDATE_FACTORY_SUCCESS:
    case CREATE_FACTORY_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case UPDATE_FACTORY_SETTING_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          settings: action.settings,
        },
      };
    case DELETE_FACTORY_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case FETCH_TAXES_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          taxes: action.taxes,
        },
      };
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          vendors: action.vendors,
        },
      };
    case FETCH_SHIPPINGS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          shippings: action.shippings,
        },
      };
    case FETCH_DISCOUNTS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          discounts: action.discounts,
        },
      };
    case FETCH_COMMRULES_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          commRules: action.commRules,
        },
      };
    case FETCH_PERIODS_SUCCESS:
      return {
        ...state,
        periods: action.periods,
      };
    case FETCH_COMMPAY_SUCCESS:
      return {
        ...state,
        commPay: action.commPay,
      };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          regions: action.regions,
        },
      };
    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          banks: action.banks,
        },
      };
    case FETCH_TERMS_SUCCESS:
      return {
        ...state,
        factory: {
          ...state.factory,
          terms: action.terms,
        },
      };
    case RESET_FINISHED:
      return {
        ...state,
        isFinished: false,
      };
    case CLEAN_FACTORY:
      return {
        ...FACTORY_INIT_STATE,
        factory: state.factory,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
