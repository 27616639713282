import OrderInvoiceItemModel, { deriveRawToOrderInvoiceItem } from './orderInvoiceItem';

export default interface RefundDetailsModel extends OrderInvoiceItemModel {
  refundId: number;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToRefundDetails = (raw) => {
  return {
    ...deriveRawToOrderInvoiceItem(raw),
    id: raw.id,
    refundId: raw.refundId,
  };
};
