import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import intl from 'react-intl-universal';
import 'intl/locale-data/jsonp/en.js';

import { setupIonicReact } from '@ionic/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { configs } from '$configs';
import store from '$business/redux';

import AppInit from './appInit';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '$gstyles/global.scss';
import '$factory/styles/common.scss';
import '$factory/styles/common.scss';
import '$styles/general.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RootApp from '$gbox/theme';
import { ToasterManager } from '$gcomponents/widgets';
import { clog } from '$gbusiness/helpers/util';

setupIonicReact({});

const locales = {
  'en-US': require('$gintl/en.json'),
};

const { theme: colors } = configs;
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.danger,
    },
  },
  typography: {
    htmlFontSize: configs.theme.fontSize,
    fontFamily: configs.theme.fontFamily,
  },
});

const App: React.FC = () => {
  clog('ENV', process.env);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    intl
      .init({
        currentLocale: 'en-US',
        locales,
      })
      .then(() => {
        setInitDone(true);
      });

    intl.load({ 'en-US': require('$factory/intl/en.json') });
    intl.load({ 'en-US': require('$intl/en.json') });
  }, []);

  if (!initDone) return null;

  return (
    <Provider store={store}>
      <RootApp id="root-app">
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppInit />
          </LocalizationProvider>
        </ThemeProvider>
      </RootApp>
      <ToasterManager />
    </Provider>
  );
};

export default App;
