import React, { useState } from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';

import { IonContentWrapper, IonFooterWrapper } from './styles';
import { Button, Counter } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';
import CurrentStateModel, { initialCurrentState } from '$fbusiness/models/currentState';
import Img from '$gcomponents/primitives/img';
import { timestamp } from '$gbusiness/helpers/date';
import { currency } from '$gbusiness/helpers/util';
import { Div } from '$gstyles';

interface ItemDetailsViewProps {
  item: ItemModel;
  currentState?: CurrentStateModel;
  onAddCart: Function;
  onClose: Function;
}

const IMAGE_SIZE = '100%';
const THUMB_SIZE = 64;

const ItemDetailsView: React.FC<ItemDetailsViewProps> = ({
  item,
  currentState = initialCurrentState,
  onAddCart,
  onClose,
}) => {
  const [quantity, setQuantity] = useState(1);
  const { ebrochure, hidePrice, isWholesale } = currentState;
  const { files, name, sku, description, retailPrice, wholesalePrice, settings } = item;
  const images = files || [];
  const hasMultipleImages = images.length > 1;
  const [imageIndex, setImageIndex] = useState(images.findIndex((img) => img.isMain) || 0);

  const price = isWholesale ? wholesalePrice : retailPrice;
  const tagText = intl('SCREEN.ITEMS.' + (isWholesale ? 'WHOLESALE' : 'RETAIL') + '_TAG');
  const currentImage = images[imageIndex] || '';

  return (
    <>
      <IonContentWrapper imageSize={IMAGE_SIZE} thumbSize={THUMB_SIZE}>
        <Flex className="item-container">
          <div className="image-section">
            {hasMultipleImages && (
              <div className="left">
                {hasMultipleImages &&
                  (files || []).map((img, i) => {
                    return (
                      <Img
                        src={img?.sizes?.small || ''}
                        key={i}
                        cache={timestamp(img.createdAt)}
                        className={`thumb link ${imageIndex === i && 'selected'}`}
                        size="cover"
                        width={THUMB_SIZE + 'px'}
                        height={THUMB_SIZE + 'px'}
                        onClick={() => setImageIndex(i)}
                      />
                    );
                  })}
              </div>
            )}
            <Img
              src={currentImage?.sizes?.medium || ''}
              cache={timestamp(currentImage?.createdAt)}
              className="flex main-image"
              size="contain"
              placeholder="No Image"
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
            />
          </div>
          <div className="content-section">
            <div className="item-name">{name}</div>
            {!!(description || sku) && (
              <div className="description">
                {description && (
                  <Div flex={1} className="value">
                    {description}
                  </Div>
                )}
                {!!sku && (
                  <div className="sku">
                    <span>CODE:</span> {sku}
                  </div>
                )}
                {!!settings?.packing && (
                  <div className="packing">
                    <span>PACKING:</span> {settings?.packing}
                  </div>
                )}
                {!!settings?.skid && (
                  <div className="skid">
                    <span>SKID:</span> {settings?.skid}
                  </div>
                )}
                {!!settings?.lotNo && (
                  <div className="lotNo">
                    <span>LOT NO:</span> {settings?.lotNo}
                  </div>
                )}
                {!!settings?.binNo && (
                  <div className="binNo">
                    <span>BIN NO:</span> {settings?.binNo}
                  </div>
                )}
              </div>
            )}
            {!ebrochure && (
              <>
                <Flex className="quantity">
                  <div className="label">QTY: </div>
                  <Counter value={quantity} size={18} onChange={(qty) => setQuantity(qty)} />
                </Flex>

                {!hidePrice && (
                  <div className="price-section">
                    <div className="price">{currency(price * quantity)}</div>
                    <div className="tag">{tagText}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </Flex>
      </IonContentWrapper>
      <IonFooterWrapper justifyContent="space-around">
        {ebrochure ? (
          <Button className="third" onClick={() => onClose()}>
            {intl('BUTTON.CLOSE')}
          </Button>
        ) : (
          <>
            {/* <QuantityEdit
              label={intl('INPUT.LABEL.QUANTITY')}
              value={quantity}
              onChange={q => setQuantity(q)}
              name="quantity"
            /> */}
            <Button className="third" variant="outlined" onClick={() => onClose()}>
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button className="half" onClick={() => onAddCart(quantity)}>
              {intl('BUTTON.ADD_CART')}
            </Button>
          </>
        )}
      </IonFooterWrapper>
    </>
  );
};

export default ItemDetailsView;
