import styled from 'styled-components';
import { FONT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const CarouselWrapper = styled.div`
  align-items: start;
  display: flex;

  .featured-header {
    position: relative;
    border-radius: 10px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast-bw);
    padding: 8px;
    font-size: ${FONT.LARGE};
    width: ${p => p.fheaderWidth || 0}px;
    height: ${p => p.fheight}px;
    display: flex;
    align-items: center;
    .featured-title {
      font-family: impact;
      font-size: 1.1em;
    }
  }
  .pointer {
    position: absolute;
    right: -12px;
  }
  .slider-wrapper {
    flex: 1;
    width: calc(100% - ${p => p.fheaderWidth || 0}px);
    display: block;
    padding: 0 32px 48px 32px;
    .featured-items {
      padding: 0px;
    }
    .slick-arrow:before,
    .slick-arrow:before {
      color: var(--ion-color-secondary);
    }
    .slider-item > div {
      /* height: 200px; */
    }
  }

  .slick-track {
    /* margin-left: 50px; */
  }
  ${mobileStyle(`
    .featured-header {
      display: none;
    }
    .slider-wrapper {
      width: 100%;
    } 
  `)}
`;
