import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import Modal from '$gcomponents/reusables/modal';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  // .table-tabs {
  //   .MuiTab-root {
  //     /* border-radius: 0; */
  //   }
  //   .MuiTab-root:not(.Mui-selected) {
  //     background-color: #eee;
  //     border-color: #fff;
  //   }
  // }
  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
    .MuiTableRow-head {
      height: 60px;
    }
    .MuiTableCell-root {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: inherit;
    }
  }
  .MuiTable-root {
    .MuiSelect-select {
      padding-top: ${SPACE.MEDIUM};
      padding-bottom: ${SPACE.MEDIUM};
      font-size: ${FONT.SMALL};
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .content-wrapper {
    padding: ${SPACE.XLARGE};
  }
  ${mobileStyle(`
    .content-wrapper {
      padding: ${SPACE.MEDIUM};
    }
  `)}
  .qty {
    width: 60px;
    text-align: center;
    border-radius: 4px;
    border-width: 1px;
  }
  .notes-label {
    font-weight: ${WEIGHT.SEMI_BOLD};
    margin: ${SPACE.LARGE} 0 ${SPACE.MEDIUM} ${SPACE.SMALL};
  }
  textarea {
    resize: none;
    width: 100%;
    height: 120px;
    padding: ${SPACE.MEDIUM};
  }
`;
