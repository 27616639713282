import styled from 'styled-components';

import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { ellipsis } from '$gstyles/mixins';

export const CartListWrapper = styled(Div)`
  padding: ${SPACE.MEDIUM} 0;

  .cart-item {
    padding: ${SPACE.MEDIUM} ${SPACE.LARGE};
    justify-content: space-between;
    align-items: flex-start;
    min-height: 44px;

    &.header {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    &.with-mod {
      min-height: 50px;
    }

    .text-section {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 64px;
      padding: 0;
      padding-left: ${SPACE.MEDIUM};
    }
    .item-text {
      line-height: 1em;
      padding: 0 ${SPACE.SMALL} 0 0;
      flex: 1;
      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
      }
    }
    .cancel-icon {
      width: 28px;
      height: 28px;
      padding: 0 0 8px 8px;
      color: var(--ion-color-tertiary);
      cursor: pointer;
    }

    .price-section {
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .instruction {
      ${ellipsis}
      font-size: 0.85em;
      opacity: 0.8;
    }
    .name {
      font-size: 1em;
      color: #000;
    }
    .sku,
    .packing {
      opacity: 0.5;
      font-size: 0.9em;
      font-weight: ${WEIGHT.SEMI_BOLD};
      display: inline-block;
    }
    .packing {
      margin-left: ${SPACE.MEDIUM};
    }
    .mod {
      opacity: 0.8;
      font-size: 0.8em;
    }
    .price {
      opacity: 0.8;
      font-size: 1.3em;
      font-weight: ${WEIGHT.SEMI_BOLD};
      color: var(--ion-color-primary);
    }
    .quantity {
      min-width: 44px;
    }
    .qty-label {
      width: 35px;
      font-size: 1em;
    }
    .qty-item {
      font-weight: ${WEIGHT.SEMI_BOLD};
      display: flex;
      align-items: center;
      input,
      .MuiOutlinedInput-input {
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 1em;
      }
    }
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .cart-item {
    background-color: transparent;
  }
  .cart-item.highlight {
    animation: blinker ${p => p.highlightDuration || 0}ms linear;
  }
  @keyframes blinker {
    0% {
      background-color: ${p => p.highlightColor || 'transparent'};
    }
    100% {
      background-color: ${p => p.highlightColor || 'transparent'};
    }
  }
  .item-enter {
    opacity: 1;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity ${p => p.fadeDuration || 0}ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }

  .item-exit-active {
    opacity: 0;
    transition: opacity ${p => p.fadeDuration || 0}ms ease-in;
  }
  .qty-enter {
    background-color: transparent;
  }
  .qty-enter-active {
    background-color: ${p => p.highlightColor || 'transparent'};
  }
`;

export const OrderSummaryWrapper = styled(Div)`
  padding: ${SPACE.LARGE};

  .title {
    font-weight: ${WEIGHT.SEMI_BOLD};
    font-size: ${FONT.MEDIUM};
    padding: ${SPACE.MEDIUM} ${SPACE.MEDIUM};
  }
  .box {
    padding: ${SPACE.MEDIUM};
    background-color: #f1f1f1;
    .line {
      display: flex;
      justify-content: space-between;
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: ${FONT.SMALL};
      height: 24px;
    }
    .line.total {
      font-size: 1.1em;
      color: var(--ion-color-primary);
    }
  }
`;
