export const KEYS = {
  INVOICE: 'INVOICE',
  PICKUP: 'PICKUP',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `PAYMENT_METHOD.${item}`,
  value: item,
}));

export type PAYMENT_METHOD_TYPE = typeof LIST[number] | undefined;
