import React from 'react';
import intl from '$intl';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import BreadcrumbModal from '$fbusiness/models/breadcrumb';
import { Div } from '$gstyles';
import { Link } from '$gcomponents/primitives';

interface BreadcrumbsProps {
  breadcrumbs?: Array<BreadcrumbModal>;
  current?: BreadcrumbModal;
  noLink?: boolean;
  separator?: any;
  excludeHome?: boolean;
  path?: string;
  homePath?: string;
  [x: string]: any;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  excludeHome,
  separator,
  noLink,
  homePath = '/',
  path = '',
  current,
  ...rest
}) => {
  const El = noLink ? Div : Link;
  return (
    <Div {...rest}>
      <MuiBreadcrumbs separator={separator}>
        {!excludeHome && (
          <El route={homePath || '/'} className="crumb">
            {intl('COMMON.HOME')}
          </El>
        )}
        {(breadcrumbs || []).map((crumb) => (
          <El key={crumb.id} route={`${path || ''}/${crumb.id}`} className="crumb">
            {crumb.name}
          </El>
        ))}
        {current && <div className="crumb current">{current?.name}</div>}
      </MuiBreadcrumbs>
    </Div>
  );
};

export default Breadcrumbs;
