export const KEYS = {
  PREPARING: 'PENDING',
  PROCESSED: 'PROCESSED',
  CANCELLED: 'CANCELLED',
};

const convertToOption = (item) => ({
  label: `REFUND_STATUS.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type REFUND_STATUS = typeof LIST[number] | undefined;
