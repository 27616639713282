import React from 'react';

import { ModalWrapper } from './styles';
import ItemModel from '$fbusiness/models/item';
import { ItemDetailsView } from '..';
import CurrentStateModel from '$fbusiness/models/currentState';

interface ItemDetailsModalProps {
  show: boolean;
  onClose: Function;
  onAddCart: Function;
  item: ItemModel;
  currentState?: CurrentStateModel;
}

const ItemDetailsModal: React.FC<ItemDetailsModalProps> = ({
  item,
  currentState,
  onAddCart,
  show,
  onClose,
}) => {
  const handleAddCart = (quantity) => {
    onAddCart(item, quantity);
  };

  return (
    <ModalWrapper hasClose show={show} onClose={onClose} useCustom>
      {item && (
        <ItemDetailsView
          onClose={onClose}
          currentState={currentState}
          item={item}
          onAddCart={handleAddCart}
        />
      )}
    </ModalWrapper>
  );
};

export default ItemDetailsModal;
