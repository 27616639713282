import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import {
  FactoryActionTypes,
  FETCH_FACTORY_SUCCESS,
  CLEAN_FACTORY,
  UPDATE_FACTORY_SUCCESS,
  FACTORY_FAILURE,
  DELETE_FACTORY_SUCCESS,
  FETCH_TAXES_SUCCESS,
  FETCH_VENDORS_SUCCESS,
  RESET_FINISHED,
  FETCH_SHIPPINGS_SUCCESS,
  FETCH_STORE_SUCCESS,
  FETCH_MY_FACTORY_SUCCESS,
  FETCH_DISCOUNTS_SUCCESS,
  FETCH_COMMRULES_SUCCESS,
  FETCH_REGIONS_SUCCESS,
  FETCH_BANKS_SUCCESS,
  FETCH_TERMS_SUCCESS,
  FETCH_PERIODS_SUCCESS,
  UPDATE_FACTORY_SETTING_SUCCESS,
  FETCH_COMMPAY_SUCCESS,
} from './types';
import { deriveRawToFactory, initialFactory } from '../../models/factory';
import { deriveRawToTax } from '../../models/tax';
import { deriveRawToVendor } from '../../models/vendor';
import { deriveRawToShipping } from '../../models/shipping';
import { deriveRawToStore } from '../../models/store';
import loader from '$gcomponents/reusables/loader';
import intl from '$gintl';
import { sendEmailByChunk, splitList } from './utils';
import { sleep } from '$gbusiness/helpers/util';
import { deriveRawToDiscount } from '../../models/discount';
import { deriveRawToCommRule } from '../../models/commRule';
import { deriveRawToRegion } from '../../models/region';
import { deriveRawToBank } from '../../models/bank';
import { deriveRawToTerm } from '../../models/term';
import { SET_SCREEN_STATE } from '$gbusiness/redux/router/types';
import { deriveRawToCommPeriod } from '../../models/commPeriod';
import { deriveRawToCommPay } from '../../models/commPay';

export function fetchMyFactory(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.factory.general + '/0',
      method: 'GET',
    });
    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_MY_FACTORY_SUCCESS,
      factory: deriveRawToFactory(response.data),
    });
  };
}

export function fetchFactory(factoryId, me = false): any {
  return async (dispatch: Dispatch) => {
    if (!factoryId || isNaN(factoryId)) {
      dispatch({
        type: FETCH_FACTORY_SUCCESS,
        factory: initialFactory,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.factory.general + '/' + factoryId,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: me ? FETCH_MY_FACTORY_SUCCESS : FETCH_FACTORY_SUCCESS,
      factory: deriveRawToFactory(response.data),
    });
  };
}

export function fetchStore(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_STORE_SUCCESS,
        factory: initialFactory,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.store.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_STORE_SUCCESS,
      store: deriveRawToStore(response.data),
    });
  };
}

export function saveFactory(factoryId, factory, updateSetting = false): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.factory.general + (factoryId ? '/' + factoryId : ''),
      param: {
        ...(factoryId && { ...factoryId }),
        ...factory,
      },
      method: factoryId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      if (updateSetting && response.data?.settings) {
        dispatch({
          type: UPDATE_FACTORY_SETTING_SUCCESS,
          settings: response.data?.settings,
        });
      }
    }
  };
}

export function deleteFactory(factoryId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.factory.general + (factoryId ? '/' + factoryId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

/*************** TAX AND VENDORS AND SHIPPING ****************/
export async function fetchTaxes(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.tax,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_TAXES_SUCCESS,
    taxes: response.list.map((d) => deriveRawToTax(d)),
  });
}
export async function fetchVendors(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.vendor,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_VENDORS_SUCCESS,
    vendors: response.list.map((d) => deriveRawToVendor(d)),
  });
}
export async function fetchShippings(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.shipping,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_SHIPPINGS_SUCCESS,
    shippings: response.list.map((d) => deriveRawToShipping(d)),
  });
}
export function saveTax(taxId, tax): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.tax + (taxId ? '/' + taxId : ''),
      param: {
        ...(taxId && { ...taxId }),
        ...tax,
      },
      method: taxId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
    await fetchTaxes(dispatch);
  };
}
export function saveVendor(vendorId, vendor): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.vendor + (vendorId ? '/' + vendorId : ''),
      param: {
        ...(vendorId && { ...vendorId }),
        ...vendor,
      },
      method: vendorId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      await fetchVendors(dispatch);
    }
  };
}
export function saveShipping(id, shipping): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.shipping + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...shipping,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
    await fetchShippings(dispatch);
  };
}
export function deleteTax(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.tax + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchTaxes(dispatch);
    }
  };
}
export function deleteVendor(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.vendor + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchVendors(dispatch);
    }
  };
}
export function deleteShipping(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.shipping + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchShippings(dispatch);
    }
  };
}

/************* EMAIL NOTIFICATION *************/

export function emailNotification(request): any {
  return async (dispatch: Dispatch) => {
    const { stores } = request;
    const thisLoader = loader({ message: 'PROGRESS.LONG_TIME' });

    const splittedList = splitList(stores, 1, 5);
    let current = 0;
    const max = stores.length;

    let numResult = [0, 0];
    for (const chunk of splittedList) {
      if (thisLoader) {
        thisLoader.message = intl('PROGRESS.EMAIL', { current: current + 1, max });
      }

      const response = await sendEmailByChunk(chunk, request);
      numResult[0] += response[0];
      numResult[1] += response[1];
      current += chunk.length;
    }
    if (thisLoader) {
      thisLoader.message = intl('PROGRESS.EMAIL', { current: numResult[1], max });
      sleep(500);
      thisLoader.dismiss();
    }
    handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, intl('MESSAGE.EMAIL_SENT'), 'medium');
  };
}

/************* DISCOUNTS *************/
export async function fetchDiscountsF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.discount,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_DISCOUNTS_SUCCESS,
    discounts: response.list.map(deriveRawToDiscount),
  });
}
export function fetchDiscounts(): any {
  return async (dispatch: Dispatch) => {
    await fetchDiscountsF(dispatch);
  };
}
export function saveDiscount(id, discount): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.discount + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...discount,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
    await fetchDiscountsF(dispatch);
  };
}
export function deleteDiscount(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.discount + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchDiscountsF(dispatch);
    }
  };
}

/************* COMMISSION RULES *************/
export async function fetchCommRulesF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.commRule,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_COMMRULES_SUCCESS,
    commRules: response.list.map(deriveRawToCommRule),
  });
}
export function fetchCommRules(): any {
  return async (dispatch: Dispatch) => {
    await fetchCommRulesF(dispatch);
  };
}
export function saveCommRule(id, commRule): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.commRule + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...commRule,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
    await fetchCommRulesF(dispatch);
  };
}
export function deleteCommRule(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.commRule + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchCommRulesF(dispatch);
    }
  };
}

/************* REGION FETCH *************/
export async function fetchRegionsF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.region,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_REGIONS_SUCCESS,
    regions: response.list.map(deriveRawToRegion),
  });
}
export function fetchRegions(): any {
  return async (dispatch: Dispatch) => {
    await fetchRegionsF(dispatch);
  };
}

/************* COMMISSION PAYMENT FETCH *************/
export async function fetchCommPaymentF(dispatch: Dispatch, periodId, userId) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.commission.byPeriodUser,
    param: {
      userId,
      periodId,
    },
    method: 'POST',
  });

  if (!response || !response?.data) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_COMMPAY_SUCCESS,
    commPay: deriveRawToCommPay(response.data),
  });
}
export function fetchCommPayment(periodId, userId): any {
  return async (dispatch: Dispatch) => {
    await fetchCommPaymentF(dispatch, periodId, userId);
  };
}

/************* PERIODS FETCH *************/
export async function fetchPeriodsF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.commission.period,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_PERIODS_SUCCESS,
    periods: response.list.map(deriveRawToCommPeriod),
  });
}
export function fetchPeriods(): any {
  return async (dispatch: Dispatch) => {
    await fetchPeriodsF(dispatch);
  };
}

/************* BANK FETCH *************/
export async function fetchBankF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.bank,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_BANKS_SUCCESS,
    banks: response.list.map(deriveRawToBank),
  });
}

/************* TERM FETCH *************/
export async function fetchTermsF(dispatch: Dispatch) {
  dispatchLoading(dispatch);
  const response = await fetchApi({
    url: configs.api.term,
    method: 'GET',
  });

  if (!response || !response?.list) {
    handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SERVER', true);
    return;
  }
  dispatch({
    type: FETCH_TERMS_SUCCESS,
    terms: response.list.map(deriveRawToTerm),
  });
}
export function fetchTerms(): any {
  return async (dispatch: Dispatch) => {
    await fetchTermsF(dispatch);
  };
}

/************* OBJ SAVE AND DELETE *************/
export function saveObj(id, obj, path, fetchList: Function = () => {}): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: path + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...obj,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FACTORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      await fetchList(dispatch);
    }
  };
}
export function deleteObj(id, path, fetchList: Function = () => {}): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: path + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FACTORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FACTORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      await fetchList(dispatch);
    }
  };
}

/************* FETCH NAME *************/
export function fetchName({ storeId, itemId, userId }): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.report.name,
      param: {
        storeId,
        itemId,
        userId,
      },
      method: 'POST',
    });

    if (!response || !response?.data) {
      return;
    }
    const arr = Object.values(response.data).filter((d) => d);
    dispatch({
      type: SET_SCREEN_STATE,
      payload: arr.join(),
    });
  };
}

/************* CLEAN AND RESET *************/
export function resetFinished(): FactoryActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): FactoryActionTypes {
  return { type: CLEAN_FACTORY };
}
