import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

export const CLOSE_FORM = (isFlagged): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.INVOICES.NOTES',
        value: 'noteStore',
        gridSize: 12,
        input: INPUT.TEXTAREA,
        ...(isFlagged && {
          validation: [
            {
              condition: v => v === '' || v === null || v === undefined,
              message: 'FLAGGED',
            },
          ],
        }),
      },
    ],
  },
];

export default CLOSE_FORM;
