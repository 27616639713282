import { format as dateFormat, getUnixTime, parseISO } from 'date-fns';
import { DATE_FORMATS } from '../enums';

export const toLocal = (dateStr) => {
  if (!dateStr) return '';
  if (dateStr[dateStr.length - 1] === 'Z') return dateStr;
  return dateStr + 'Z';
};

export const today = (formatStr = DATE_FORMATS.DATE) => {
  return dateFormat(new Date(), formatStr);
};

export const timestamp = (dateStr) => {
  return getUnixTime(parseISO(dateStr));
};

export const format = (dateStr, formatStr = DATE_FORMATS.DATE) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatTime = (dateStr, formatStr = DATE_FORMATS.TIME) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatDatetime = (dateStr, formatStr = DATE_FORMATS.DATETIME) => {
  if (!dateStr) return '';
  return dateFormat(parseISO(dateStr), formatStr);
};

export const dateToString = (dateObj, formatStr = DATE_FORMATS.DATE) => {
  if (!dateObj) return '';
  return dateFormat(dateObj, formatStr);
};

export const datetimeToString = (dateObj, formatStr = DATE_FORMATS.DATETIME) => {
  if (!dateObj) return '';
  return dateFormat(dateObj, formatStr);
};

export const datetimeToStringUtc = (dateObj, formatStr = DATE_FORMATS.DATETIME) => {
  if (!dateObj) return '';
  var utc = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
  return dateFormat(utc, formatStr);
};

export const toDayOfMonth = (d) => {
  if (d > 3 && d < 21) return d + 'th';
  switch (d % 10) {
    case 1:
      return d + 'st';
    case 2:
      return d + 'nd';
    case 3:
      return d + 'rd';
    default:
      return d + 'th';
  }
};
