export default interface CommRuleModel {
  id: number;
  name: string;
  rate: number;
  discountRate: number;
  settings?: any;
}

export const deriveRawToCommRule = raw => {
  return {
    id: raw.id,
    name: raw.name,
    rate: raw.rate,
    discountRate: raw.discountRate,
    settings: {
      ...raw.settings,
    },
  };
};

export const initialCommRule = {
  id: 0,
  name: '',
  rate: 0,
  discountRate: 0,
};
