import React from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import Header from '../../../components/header';
import { TableView2 } from '$gcomponents/widgets';

import USER_TABLE, { USER_TABLE_CONFIG } from './table';
import PATH from '$gbusiness/enums/paths';

import { IonPageWrapper } from './styles';
import FabButton from '$fcomponents/fabButton';

const UsersScreen: React.FC = () => {
  return (
    <IonPageWrapper>
      <Header title="SCREEN.USERS.TITLE" />
      <TableView2 TABLE={USER_TABLE} tableConfig={USER_TABLE_CONFIG} />
      <FabButton route={PATH.USERS + '/0'} />
    </IonPageWrapper>
  );
};

const mapStateToProps = () => ({
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(UsersScreen));
