import { Dispatch } from 'redux';
import * as UpChunk from '@mux/upchunk';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToFile, initialFile } from '../../models/file';
import {
  FileActionTypes,
  CLEAN_FILE,
  UPDATE_FILE_SUCCESS,
  FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  RESET_FINISHED,
  FETCH_FILE_SUCCESS,
} from './types';
import loader from '$gcomponents/reusables/loader';
import intl from '$gintl';

const CHUNK_THRESHOLD = 5120;
const CHUNK_SIZE = 2048;

export function fetchFile(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_FILE_SUCCESS,
        file: initialFile,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.file.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_FILE_SUCCESS,
      file: deriveRawToFile(response.data),
    });
  };
}

export function addFiles(files): any {
  return async (dispatch: Dispatch, getState) => {
    let current = 1;
    const max = files.length;
    const thisLoader = loader({ message: 'PROGRESS.FILE_UPLOAD', key: { current, max } });

    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.file.general}`;
    const store = getState();
    const globalHeaders = store.init.headers;
    const { accessToken, userId } = store.localStorage;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(userId && { userId: userId?.toString() }),
      ...globalHeaders,
    };

    for (const fileData of files) {
      if (thisLoader) {
        thisLoader.message = intl('PROGRESS.FILE_UPLOAD', { current, max });
      }

      const formData = new FormData();

      const file: File = fileData.file;
      if (file && file.size > CHUNK_THRESHOLD * 1024) {
        await uploadLargeFile({
          file,
          data: {
            name: fileData.name,
            description: fileData.description,
            storeIds: fileData.storeIds ? JSON.stringify(fileData.storeIds) : null,
          },
          headers,
          thisLoader,
          current,
          max,
        });
      } else {
        if (file) formData.append('file', file);
        else formData.append('youtubeLink', fileData.youtubeLink);
        formData.append('name', fileData.name || '');
        formData.append('description', fileData.description);
        if (fileData.storeIds) formData.append('storeIds', JSON.stringify(fileData.storeIds));

        await fetch(url, {
          method: 'POST',
          body: formData,
          referrer: '',
          headers,
        });
      }

      current++;
    }
    if (thisLoader) thisLoader.dismiss();
  };
}

async function uploadLargeFile({ file, headers, thisLoader, data, current, max }) {
  return new Promise(function (resolve, reject) {
    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.file.chunk}`;
    const upload = UpChunk.createUpload({
      method: 'POST',
      endpoint: url,
      headers,
      data,
      file,
      chunkSize: CHUNK_SIZE, // Uploads the file in ~1 MB chunks
    });

    upload.on('error', (err) => {
      //console.error('💥 🙀', err.detail);
      reject('failed');
    });

    upload.on('progress', (progress) => {
      thisLoader.message = intl('PROGRESS.FILE_UPLOAD_CHUNK', {
        current,
        max,
        progress: parseFloat(progress.detail || 0).toFixed(1),
      });
      //console.log(`So far we've uploaded ${progress.detail}% of this file.`);
    });

    upload.on('success', () => {
      //console.log("Wrap it up, we're done here. 👋");
      resolve('worked');
    });
  });
}

export function updateFile(file): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.file.general + '/' + file.id,
      method: 'PUT',
      param: {
        name: file.name,
        description: file.description,
        storeIds: JSON.stringify(file.storeIds),
      },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FILE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteFile(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.file.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FILE_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function resetFinished(): FileActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): FileActionTypes {
  return { type: CLEAN_FILE };
}
