import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import { ContentWrapper } from '$styles/general';
import { ApiList } from '$gcomponents/reusables';
import ItemModel from '$fbusiness/models/item';

import { IonPageWrapper } from './styles';
import { dataSource } from './categoryList';
import ItemSimpleView from '$fcomponents/itemSimpleView';
import CategoryModel from '$fbusiness/models/category';
import ItemListContainer from '$components/itemListContainer';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';
import { categoryActions } from '$fbusiness/redux/category';
import Header from '$fcomponents/header';
import Breadcrumbs from '$fcomponents/breadcrumbs';
import { SPACE } from '$gstyles';
import Subcategory from './subcategory';
import IonContentWrapper from '$fcomponents/ionContentWrapper';
import PATH from '$business/enums/paths';
interface MenuScreenProps {
  match: any;
  category: CategoryModel;
  item: ItemModel;
  currentState: CurrentStateModel;
  addToCart: Function;
  onHydrate;
  onDehydrate;
  cleanItem;
  fetchItem;
}

const MenuScreen: React.FC<MenuScreenProps> = ({ onHydrate, category, match, currentState }) => {
  const { categoryId: catId } = match.params;
  const categoryId = parseInt(catId) || 0;
  const [filter, setFilter] = useState<any>({
    categoryId,
    factoryId: getPublicFactoryId(),
  });
  const { hidePrice, ebrochure } = currentState;
  const hasChildren = category && category?.children?.length ? true : false;

  const fetchCategory = async (id) => {
    await onHydrate();
    setFilter({
      categoryId: id,
      factoryId: getPublicFactoryId(),
    });
  };

  useEffect(() => {
    if (!categoryId) return;
    fetchCategory(categoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  if (category?.id !== categoryId) return <IonPageWrapper />;

  return (
    <IonPageWrapper>
      <IonContentWrapper>
        <Breadcrumbs
          margin={`${SPACE.LARGE} 0 -${SPACE.SMALL} ${SPACE.LARGE}`}
          breadcrumbs={category?.breadcrumbs}
          path={PATH.MENU}
          current={{ id: category?.id, name: category?.name }}
        />
        <Header titleText={category?.name} />
        <ContentWrapper>
          {hasChildren && (
            <div className="subcategory-section">
              {(category?.children || []).map((cat) => (
                <Subcategory key={cat.id} category={cat} />
              ))}
            </div>
          )}
          {category && (
            <ItemListContainer currentState={currentState}>
              {(onAddCart, onViewItem, isWholesale) => (
                <ApiList dataSource={dataSource} filters={filter}>
                  {(item: ItemModel, index) => (
                    <ItemSimpleView
                      key={index}
                      item={item}
                      hidePrice={hidePrice}
                      ebrochure={ebrochure}
                      onClick={onViewItem}
                      onAddCart={onAddCart}
                      isWholesale={isWholesale}
                    />
                  )}
                </ApiList>
              )}
            </ItemListContainer>
          )}
        </ContentWrapper>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  category: state.category.category,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: (param) => categoryActions.fetchCategoryDetails(parseInt(param?.categoryId)),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MenuScreen));
