import React from 'react';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { screen } from '$gcomponents/hoc';
import { orderActions } from '$fbusiness/redux/order';

import { IonPageWrapper } from './styles';
import OrderModel from '$fbusiness/models/order';
import { Div, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import intl from '$intl';
import CartList from '$components/cartList';
import { OrderSummary } from '$fcomponents';
import CurrentStateModel from '$fbusiness/models/currentState';
import IonContentWrapper from '$fcomponents/ionContentWrapper';

interface ThankyouScreenProps {
  onHydrate;
  onDehydrate;
  currentState: CurrentStateModel;
  order: OrderModel;
}

const ThankyouScreen: React.FC<ThankyouScreenProps> = ({ order, currentState }) => {
  if (!order) return <IonPageWrapper></IonPageWrapper>;

  const { items, subtotal, total, tax } = order;
  const { hidePrice } = currentState;
  const price = {
    subtotal,
    total,
    tax,
    delivery: 0, // TODO this should come from order
  };
  return (
    <IonPageWrapper>
      <IonContentWrapper>
        <Div padding={SPACE.XLARGE} margin="auto" maxWidth="600px">
          <Flex className="title">
            <CheckCircleIcon />
            <div>{intl('SCREEN.THANKYOU.TITLE')}</div>
          </Flex>
          <div className="subtitle">{intl('SCREEN.CHECKOUT.ORDER_SUMMARY.TITLE')}</div>
          <CartList includeHeader items={items} hidePrice={hidePrice} />
          <Flex justifyContent="flex-end">
            <div className="summary-wrapper">{!hidePrice && <OrderSummary price={price} />}</div>
          </Flex>
        </Div>
      </IonContentWrapper>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  order: state.order.order,
});

const mapDispatchToProps = {
  onHydrate: (param) => orderActions.fetchOrder(parseInt(param.orderId)),
  onDehydrate: orderActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ThankyouScreen));
