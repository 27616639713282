import { Dispatch } from 'redux';
import {
  changeField,
  changeModifierField,
  calculateItemPriceFromList,
  mergeObject,
  calculateCartPrice,
  getModifiersFromCart,
  itemToCartItem,
  checkItemStock,
} from './utils';

import { setStorageObj } from '$gbusiness/services/local.storage';
import { defaultCart } from '../../models/cart';
import CartItemModel from '../../models/cartItem';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { configs } from '$configs';
import intl from '$intl';
import { CLEAN_CART, UPDATE_CART_SUCCESS } from './types';
import { initialStore } from '../../models/store';
import { initialDiscount } from '../../models/discount';
import { toastDanger } from '$gbusiness/redux/toaster/actions';

export function handleOOS(dispatch, qty) {
  let text;
  if (qty === 0) text = intl('MESSAGE.OUT_OF_STOCK');
  else if (qty === 1) text = intl('MESSAGE.OUT_OF_STOCK1');
  else text = intl('MESSAGE.OUT_OF_STOCK2', { items: qty });
  dispatch(
    toastDanger({
      text,
      className: 'medium',
    }),
  );
}

// PRODUCTS
export function addProduct(cartItem, qty = 1): any {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const cart = state.localStorage.cart;
    const store = state.factory.store;
    const factory = state.factory.factory;
    const products = cart.products.filter((p) => p.isAdded);

    const newCartItem: CartItemModel = itemToCartItem(cartItem, qty, store || initialStore);
    const newCart = {
      ...cart,
      stock: {
        ...cart.stock,
        [cartItem.id]: cartItem.quantity,
      },
      ...(!products.length && { factoryId: factory?.factoryId }),
      products: [...products, newCartItem],
      pointerIndex: products.length,
    };
    if (!checkItemStock(newCart, cartItem.id)) {
      handleOOS(dispatch, cartItem.quantity);
      return;
    }

    await setStorageObj('cart', calculateCartPrice(newCart, getState), 'ADD_PRODUCT');
  };
}

export function refreshCart(newCart = null): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = newCart || getState().localStorage.cart;
    setStorageObj('cart', calculateCartPrice(cart, getState), 'REFRESH_CART');
  };
}

export function removeProducts(indexes): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newProducts = cart.products.filter((p, i) => !indexes.includes(i));

    const newCart = {
      ...cart,
      pointerIndex: -1,
      products: newProducts,
    };

    setStorageObj('cart', calculateCartPrice(newCart, getState), 'REMOVE_PRODUCT');
  };
}

export function removeProduct(productIndex): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newProducts = cart.products.filter((p, i) => i !== productIndex);

    const newCart = {
      ...cart,
      pointerIndex: -1,
      products: newProducts,
    };

    setStorageObj('cart', calculateCartPrice(newCart, getState), 'REMOVE_PRODUCT');
  };
}

export function setDiscount(discount, isRefund = false): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      pricing: {
        ...cart.pricing,
        ...(isRefund && { refundDiscount: discount }),
        ...(!isRefund && { discount }),
      },
    };

    setStorageObj('cart', calculateCartPrice(newCart, getState), 'SET_DISCOUNT');
  };
}

export function setCartSettings(settings): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      settings: {
        ...cart.settings,
        settings,
      },
    };

    setStorageObj('cart', calculateCartPrice(newCart, getState), 'SET_SETTINGS');
  };
}

export function clearDiscount(isRefund = false): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      pricing: {
        ...cart.pricing,
        ...(isRefund && { refundDiscount: { ...initialDiscount } }),
        ...(!isRefund && { discount: { ...initialDiscount } }),
      },
    };

    setStorageObj('cart', calculateCartPrice(newCart, getState), 'CLEAR_DISCOUNT');
  };
}

export function updateProducts(indexes, item, settings = {}): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = cart.products.map((p, i) => {
      if (!indexes.includes(i)) return p;
      return {
        ...p,
        ...item,
        settings: {
          ...p.settings,
          ...settings,
        },
      };
    });
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'UPDATE_PRODUCTS');
  };
}

export function updateProduct(productIndex = -1, item, settings = {}, bypassError = false): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = cart.products.map((p, i) => {
      if (i !== productIndex) return p;
      return {
        ...p,
        ...item,
        settings: {
          ...p.settings,
          ...settings,
        },
      };
    });
    const newCart = {
      ...cart,
      products,
    };
    const targetItem = products[productIndex];
    if (!bypassError && targetItem && !checkItemStock(newCart, targetItem.id)) {
      handleOOS(dispatch, newCart?.stock[targetItem.id] || 0);
      return;
    }
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'UPDATE_PRODUCT');
  };
}

export function finishProduct(productIndex = -1, instruction): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const pricedItem = calculateItemPriceFromList(cart.products);

    const newCart = {
      ...cart,
      products: mergeObject(
        cart.products,
        {
          ...pricedItem,
          isAdded: true,
        },
        productIndex,
      ),
    };

    await setStorageObj('cart', calculateCartPrice(newCart, getState), 'FINISH_PRODUCT');
  };
}

export function changeProductQuantity(index, qty, item = null): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const item = cart.products[index];
    const products = changeField(cart.products, index, 'qty', qty);
    const newCart = {
      ...cart,
      products,
    };
    if (!checkItemStock(newCart, item.id)) {
      handleOOS(dispatch, newCart?.stock[item.id] || 0);
      return;
    }
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'CHANGE_PRODUCT_QUANTITY');
  };
}

export function changeInstruction(index, text): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = changeField(cart.products, index, 'instruction', text);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CHANGE_PRODUCT_INSTRUCTION');
  };
}

export function updateShipping(delivery): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      price: {
        ...cart.price,
        delivery,
      },
    };
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'CHANGE_PRODUCT_INSTRUCTION');
  };
}

export function cleanProducts(): any {
  return (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const products = cart.products.filter((p) => p.isAdded);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CLEAN_PRODUCT');
  };
}

// MODIFIER
export function addModifiers(
  modifiers,
  productIndex,
  modifierSetIndex,
  clearOthers = false,
  split: any = {},
): any {
  return async (dispatch: Dispatch, getState) => {
    const hasSplit = split.splits?.length;
    const cart = getState().localStorage.cart;
    const pindex = productIndex >= 0 ? productIndex : cart.products.length - 1;
    const newModifiers = modifiers.map((m) => ({
      modifierSetIndex,
      modifierIndex: m.index,
      name: m.name,
      label: m.label,
      qty: 1,
      ...(hasSplit > 0 && { splits: split.splits }),
      price: {
        original: m.price,
        ...(m.price2 && { price2: m.price2 }),
        ...(m.price2 && { price3: m.price3 }),
      },
    }));
    const products = cart.products.map((p, i) => {
      if (i !== pindex) return p;
      const existingModifiers = clearOthers
        ? p.modifiers.filter((m) => !(m.modifierSetIndex === modifierSetIndex))
        : p.modifiers;
      return {
        ...p,
        modifiers: [...existingModifiers, ...newModifiers],
      };
    });
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'ADD_MODIIFER');
  };
}

export function removeModifierSet(productIndex, modifierSetIndex): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = modifiers.filter((m) => !(m.modifierSetIndex === modifierSetIndex));
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'REMOVE_MODIFIER_SET');
  };
}

export function removeModifier(productIndex, modifierSetIndex, modifierIndex): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = modifiers.filter(
      (m) => !(m.modifierSetIndex === modifierSetIndex && m.modifierIndex === modifierIndex),
    );
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'REMOVE_MODIFIER');
  };
}

export function changeModifierQuantity(qty, productIndex, modifierSetIndex, modifierIndex): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const modifiers = getModifiersFromCart(cart, productIndex);
    const newModifiers = changeModifierField(modifiers, modifierSetIndex, modifierIndex, 'qty', qty);
    const products = changeField(cart.products, productIndex, 'modifiers', newModifiers);
    const newCart = {
      ...cart,
      products,
    };
    setStorageObj('cart', newCart, 'CHANGE_MODIFIER_QUANTITY');
  };
}

export function changeOrder(param): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      order: {
        ...cart.order,
        ...param,
      },
    };

    await setStorageObj('cart', newCart, 'CHANGE_ORDER');
  };
}

export function changeTip(tip): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      price: {
        ...cart.price,
        tip,
      },
    };
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'REMOVE_PRODUCT');
  };
}

export function changeDelivery(delivery): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      price: {
        ...cart.price,
        delivery,
      },
    };
    setStorageObj('cart', calculateCartPrice(newCart, getState), 'REMOVE_PRODUCT');
  };
}

export function resetCart(): any {
  return async (dispatch: Dispatch, getState) => {
    await setStorageObj('cart', defaultCart, 'RESET_CART');
  };
}

export function loadSavedCart(products): any {
  return async (dispatch: Dispatch, getState) => {
    const cart = getState().localStorage.cart;
    const newCart = {
      ...cart,
      products,
    };
    await setStorageObj('cart', calculateCartPrice(newCart, getState), 'LOAD_SAVED_CART');
    handleApiSuccess(dispatch, null, intl('MESSAGE.CART_APPLIED'), 'medium');
  };
}

export function saveCart(cartDetails, id, name): any {
  return async (dispatch: Dispatch, getState) => {
    dispatchLoading(dispatch);

    const response = await fetchApi({
      url: configs.api.cart.general + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        cartDetails,
        name,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, null, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_CART_SUCCESS, intl('MESSAGE.CART_SAVED'), 'medium');
    }
  };
}

export function deleteCart(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.cart.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (response?.err) {
      handleApiFail(dispatch, null, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_CART_SUCCESS, intl('MESSAGE.CART_SAVED'), 'medium');
    }
  };
}

export function cleanCart() {
  return {
    type: CLEAN_CART,
  };
}
