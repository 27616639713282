import OrderModel from '../../models/order';
import RefundModel from '../../models/refund';

export const ORDER_FAILURE = 'ORDER_FAILURE';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_REFUND_SUCCESS = 'FETCH_REFUND_SUCCESS';
export const CREATE_REFUND_SUCCESS = 'CREATE_REFUND_SUCCESS';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CLEAN_ORDER = 'CLEAN_ORDER';

// Action Types
export interface typeOnlyType {
  type: typeof UPDATE_ORDER_SUCCESS | typeof CLEAN_ORDER | typeof DELETE_ORDER_SUCCESS;
}

export interface failureType {
  type: typeof ORDER_FAILURE;
  err: string;
}

export interface FetchOrderSuccessType {
  type: typeof FETCH_ORDER_SUCCESS | typeof CREATE_ORDER_SUCCESS;
  order: OrderModel;
}

export interface CreateRefundSuccessType {
  type: typeof CREATE_REFUND_SUCCESS | typeof FETCH_REFUND_SUCCESS;
  refund: RefundModel;
}

export interface orderReducerType {
  order: OrderModel | null;
  refund: RefundModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type orderActionTypes = typeOnlyType | failureType | FetchOrderSuccessType | CreateRefundSuccessType;

export const ORDER_INIT_STATE: orderReducerType = {
  order: null,
  refund: null,
  isFinished: false,
  refresh: true,
};
