import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import CreditsList from '$fcomponents/creditList';
import { PageWrapper } from '$gstyles/wrapper';
import CurrentStateModel from '$fbusiness/models/currentState';
import StoreFilter from '$fcomponents/storeFilter';
import { updatedFilter } from '$fbusiness/helpers/util';

interface CreditsScreenProps {
  onHydrate;
  onDehydrate;
  stores;
  currentState: CurrentStateModel;
}

const CreditsScreen: React.FC<CreditsScreenProps> = ({ currentState, stores }) => {
  const [filter, setFilter] = useState({ forceRefresh: true, store: stores[0] });
  const onStoreChange = (store) => {
    setFilter(updatedFilter(filter, { store: store || null }));
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.CREDIT.TITLE" />
      <IonContent>
        <PageWrapper maxWidth="900px">
          <StoreFilter stores={stores} store={filter.store} onChange={onStoreChange} />
          {filter.store && <CreditsList store={filter.store} currentState={currentState} />}
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  stores: state.store.stores,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUser,
  onDehydrate: userActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CreditsScreen));
