import { initialStore } from '../../models/store';
import {
  CLEAN_STORE,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_SUCCESS,
  StoreActionTypes,
  StoreReducerType,
  STORE_INIT_STATE,
  FETCH_STORE_SUCCESS,
  RESET_FINISHED,
  UPDATE_STORE_SUCCESS,
  FETCH_STORES_SUCCESS,
  UPDATE_CREDIT_SUCCESS,
} from './types';

export default function storeReducer(
  state: StoreReducerType = STORE_INIT_STATE,
  action: StoreActionTypes,
): StoreReducerType {
  switch (action.type) {
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        store: action.store,
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case UPDATE_CREDIT_SUCCESS:
      return {
        ...state,
        store: {
          ...(state.store || initialStore),
          credits: action.credits,
        },
        isFinished: true,
      };
    case UPDATE_STORE_SUCCESS:
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case RESET_FINISHED:
      return {
        ...state,
        isFinished: false,
      };
    case CLEAN_STORE:
      return {
        ...STORE_INIT_STATE,
      };
    default:
      return state;
  }
}
