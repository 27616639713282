import React from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import OptionModel from '$gbusiness/models/option';

import CommonScreen from './common';
import CurrentStateModel from '$fbusiness/models/currentState';

interface InvoicesScreenProps {
  currentState: CurrentStateModel;
}

const TTABS: Array<OptionModel> = [
  { label: 'SCREEN.INVOICES.TABS.OPEN', value: 'OPEN' },
  { label: 'SCREEN.INVOICES.TABS.PAID', value: 'PAID' },
  { label: 'SCREEN.INVOICES.TABS.VOID', value: 'VOID' },
  { label: 'SCREEN.INVOICES.TABS.CANCELLED', value: 'CANCELLED' },
];

const InvoicesScreen: React.FC<InvoicesScreenProps> = ({ currentState }) => {
  return (
    <CommonScreen
      currentState={currentState}
      ttabs={TTABS}
      defaultFilter={{
        query: '',
        tableTabIndex: 0,
        pickupOnly: currentState.settings?.pickupOnly,
        // confirmedAt: true,
        storeFlag: 1,
        status: TTABS[0].value,
        received: 1,
        forceRefresh: false,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(InvoicesScreen));
