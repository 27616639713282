import CartItemModel from '../../models/cartItem';

export const CART_FAILURE = 'CART_FAILURE';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CARTS_SUCCESS = 'FETCH_CARTS_SUCCESS';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const CLEAN_CART = 'CLEAN_CART';

// Action Types
export interface typeOnlyType {
  type: typeof CLEAN_CART | typeof UPDATE_CART_SUCCESS;
}

export interface failureType {
  type: typeof CART_FAILURE;
  err: string;
}

export interface FetchCartSuccessType {
  type: typeof FETCH_CART_SUCCESS;
  cart: Array<CartItemModel>;
}

export interface FetchCartsSuccessType {
  type: typeof FETCH_CARTS_SUCCESS;
  carts: Array<Array<CartItemModel>>;
}

export interface CartReducerType {
  carts: Array<Array<CartItemModel>>;
  cart: Array<CartItemModel>;
  isFinished: boolean;
  refresh: boolean;
}

export type CartActionTypes = typeOnlyType | failureType | FetchCartSuccessType | FetchCartsSuccessType;

export const CART_INIT_STATE: CartReducerType = {
  carts: [],
  cart: [],
  isFinished: false,
  refresh: true,
};
