import { INVOICE_PAYMENT_TYPE, CASH } from '../enums/invoicePayment';

export default interface InvoicePaymentModel {
  id: number;
  fromName: string;
  type: INVOICE_PAYMENT_TYPE;
  amount: number;
  bankId: number;
  paymentDate: string | null;
  settings?: any;
}

export const deriveRawToinvoicePayment = (raw) => {
  return {
    id: raw.id,
    fromName: raw.fromName,
    type: raw.type,
    amount: raw.amount,
    bankId: raw.bankId || 0,
    paymentDate: raw.paymentDate,
    settings: {
      ...raw.settings,
    },
  };
};

export const initialInvoicePayment: InvoicePaymentModel = {
  id: 0,
  fromName: '',
  type: CASH,
  amount: 0,
  bankId: 0,
  paymentDate: null,
};
