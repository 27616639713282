import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .title {
    .MuiSvgIcon-root {
      font-size: 18rem;
      margin-right: ${SPACE.LARGE};
    }
    color: #63995b;
    font-size: ${FONT.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
    flex-direction: column;
    margin-bottom: 64px;
  }

  .subtitle {
    margin-top: ${SPACE.XLARGE};
    font-size: ${FONT.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }

  .cart-item {
    .item-text {
    }
  }

  .summary-wrapper {
    width: 300px;
    font-size: 1.4em;
    line-height: 1em;
    .box {
      padding: ${SPACE.LARGE};
      .line {
        height: initial;
      }
    }
    .line.total {
      font-size: 1.1em;
      margin-top: 10px;
      font-weight: ${WEIGHT.BOLD};
    }
  }
`;
