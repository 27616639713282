import styled from 'styled-components';
import { Div, FONT, Mixins, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  .image-section {
    ${Mixins.background};
    background-size: contain;
    width: 90px;
    min-width: 90px;
    height: 90px;
    cursor: pointer;
  }
  .content-section {
    flex: 1;
    height: 100%;
    width: calc(100% - 90px);
    padding: 0 ${SPACE.MEDIUM};
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pointer > * {
      ${Mixins.ellipsis};
    }
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
      line-height: 15px;
    }
    .description {
      width: 100%;
      font-size: 0.85em;
      line-height: 1.2em;
      padding-top: 4px;
    }
    .mis {
      > * {
        margin-right: 10px;
      }
      .sku,
      .packing {
        font-weight: ${WEIGHT.SEMI_BOLD};
        opacity: 0.8;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      max-height: 36px;
      .price {
        font-weight: ${WEIGHT.SEMI_BOLD};
        font-size: 1.2em;
        width: 76px;
      }
      .tag-container {
        display: flex;
        flex-shrink: 1;
        width: 105px;
      }
      .add {
        flex: 1;
        text-align: center;
        width: 40px;
        svg {
          font-size: ${FONT.XLARGE};
        }
      }
      button {
        border-radius: 999px;
        padding: 4px 10px;
      }
    }
  }
`;
