import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import MenuBox from './menuBox';
import { Col, Row, SPACE } from '$gstyles';
import { IonPageWrapper } from './styles';
import { ROUTES } from '$fbusiness/enums';
import { ROUTES as PROUTES } from '$business/enums';
import FactoryHeader from '$fcomponents/header';
import { Header as FixedHeader } from '$gcomponents/widgets';
import { accessString } from '$gbusiness/helpers/util';
import { configs } from '$configs';

interface AccountProps {
  history;
}

const AccountScreen: React.FC<AccountProps> = ({ history }) => {
  const defaultList = [
    ROUTES.HISTORY_ROUTE,
    ROUTES.RECEIVING_ROUTE,
    ROUTES.INVOICES_ROUTE,
    ROUTES.CARTS_ROUTE,
    ROUTES.MY_FILES_ROUTES,
    ROUTES.MY_VENDORS_ROUTES,
    ROUTES.USERS_ROUTE,
    ROUTES.SETTINGS_ROUTE,
    ROUTES.PROFILE_ROUTE,
  ];

  const menuList = PROUTES['ACCOUNT_LIST'] || defaultList;

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.ACCOUNT.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.ACCOUNT.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <Row padding={SPACE.LARGE} className="row" maxWidth="800px">
          {menuList.map((menu, i) => {
            return (
              <Col gridSize={6} key={i} className={accessString(menu.accessLevel, menu.feature)}>
                <MenuBox
                  route={menu.path}
                  Icon={menu.icon}
                  title={menu.title || ''}
                  subtitle={menu.subtitle || ''}
                />
              </Col>
            );
          })}
        </Row>
      </IonContent>
    </IonPageWrapper>
  );
};

const connected = connect(null, null);

export default connected(screen(AccountScreen));
