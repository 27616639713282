import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';

import { VENDOR_TABLE, VENDOR_TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import StoreModel from '$fbusiness/models/store';
import FactoryModel from '$fbusiness/models/factory';
import VendorDetailsModal from './vendorDetailsModal';
import { configs } from '$configs';

interface MyVendorsScreenProps {
  store: StoreModel;
  factory: FactoryModel;
}

const MyVendorsScreen: React.FC<MyVendorsScreenProps> = ({ store }) => {
  const [vendor, setVendor] = useState<any>(null);
  const [filter] = useState<any>({ query: '', forceRefresh: true });

  const onClickRow = (row) => {
    setVendor(row);
  };

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.VENDORS.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.VENDORS.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <TableView2 tableConfig={VENDOR_TABLE_CONFIG} TABLE={VENDOR_TABLE(onClickRow)} filter={filter} />
      <VendorDetailsModal vendor={vendor} show={!!vendor} onClose={() => setVendor(null)} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.factory.store,
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(MyVendorsScreen));
