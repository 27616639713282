export default interface ModifierModel {
  id: number;
  name: string;
  description?: string;
  itemId?: number;
  price?: number;
  taxPercent?: number;
  quantity?: number;
  maxQuantity?: number;
  hide?: boolean;
  deleted?: boolean;
  updated?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToModifier = raw => {
  return {
    id: raw.id,
    name: raw.name,
    description: raw.description,
    itemId: raw.itemId,
    price: raw.price,
    maxQuantity: 1,
    taxPercent: raw.taxPercent,
    hide: raw.hide,
    quantity: raw.quantity,
    // createdAt: toLocal(raw.created_at),
    // updatedAt: toLocal(raw.updated_at),
  };
};

export const initialModifer = {
  id: 0,
  name: '',
  price: 0,
  taxPercent: 0,
  quantity: 0,
  maxQuantity: 1,
};
