import React, { useState, useEffect } from 'react';
import intl from '$gintl';
import { InputAdornment, InputLabel, MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import { FormControlWrapper } from './styles';

interface QuantityEditProps {
  name: string;
  value: number;
  label?: string;
  inputSize?: TextFieldProps['size'];
  onChange?: Function;
  className?: string;
  x?: boolean;
}

const QuantityEdit: React.FC<QuantityEditProps> = ({
  value,
  inputSize = 'medium',
  label = '',
  name,
  className,
  onChange,
  x = true,
  ...rest
}) => {
  const [tempValue, setTempValue] = useState(value);
  const [textMode, setTextMode] = useState(value > 9);
  useEffect(() => {
    setTempValue(value);
  }, [value]);
  const oOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const options = oOptions.map((i) => {
    return (
      <MenuItem value={i} key={i}>
        {i === 0 ? `0 (${intl('BUTTON.DELETE')})` : i === 10 ? '10+' : i}
      </MenuItem>
    );
  });

  const onChangePickerValue = (e) => {
    const val = e.target.value;
    if (val > 9) setTextMode(true);
    setTempValue(val);
    if (onChange) onChange(val);
  };
  const onKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 13) {
      e.preventDefault();
      if (onChange) onChange(tempValue);
    }
    if (
      (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true)
    )
      return;
    if (
      charCode !== 46 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      e.preventDefault();
    }
    return true;
  };

  const onBlur = () => {
    if (tempValue < 10) {
      if (typeof tempValue === 'string' && tempValue === '0') {
        setTempValue(0);
        if (onChange) onChange(0);
      }
      setTextMode(false);
    }
  };

  const startAdornment = x && <InputAdornment position="start">&times;</InputAdornment>;

  return (
    <FormControlWrapper className={className} variant="outlined" size={inputSize}>
      {label && (
        <InputLabel id={`label_${name}`} shrink>
          {label}
        </InputLabel>
      )}
      {!textMode && tempValue < 10 ? (
        <Select
          {...rest}
          labelId={`label_${name}`}
          label={label || undefined}
          name={name}
          onChange={onChangePickerValue}
          displayEmpty
          startAdornment={startAdornment}
          value={tempValue}>
          {options}
        </Select>
      ) : (
        <TextField
          {...rest}
          InputLabelProps={{ shrink: true }}
          size={inputSize}
          variant="outlined"
          label={label}
          InputProps={{
            startAdornment,
          }}
          onBlur={onBlur}
          name={name}
          value={tempValue}
          onInput={onChangePickerValue}
          onKeyDown={onKeyPress}
        />
      )}
    </FormControlWrapper>
  );
};

export default QuantityEdit;
