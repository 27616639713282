import { toLocal } from '$gbusiness/helpers/date';

export default interface RegionModel {
  id: number;
  name: string;
  stores?: Array<any>;
  settings?: any;
  createdAt?: string;
}

export const deriveRawToRegion = (raw) => {
  if (!raw) return null;

  return {
    id: raw.id,
    name: raw.name,
    ...(raw?.stores && { stores: raw.stores }),
    ...(raw?.settings && { settings: raw.settings }),
    createdAt: toLocal(raw.createdAt),
  };
};

export const initialRegion = {
  id: 0,
  name: '',
  numStores: 0,
};
