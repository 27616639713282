import DepartmentModel from '../../models/department';

export const DEPARTMENT_FAILURE = 'DEPARTMENT_FAILURE';
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARMENTS_SUCCESS';
export const CREATE_DEPARTMENT_SUCCESS = 'SAVE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_SUCCESS = 'SAVE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const REORDER_DEPARTMENT = 'REORDER_DEPARTMENT';
export const CLEAN_DEPARTMENT = 'CLEAN_DEPARTMENT';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_DEPARTMENT_SUCCESS
    | typeof UPDATE_DEPARTMENT_SUCCESS
    | typeof CLEAN_DEPARTMENT
    | typeof DELETE_DEPARTMENT_SUCCESS;
}

export interface failureType {
  type: typeof DEPARTMENT_FAILURE;
  err: string;
}

export interface FetchDepartmentSuccessType {
  type: typeof FETCH_DEPARTMENT_SUCCESS;
  department: DepartmentModel;
}

export interface FetchDepartmentsSuccessType {
  type: typeof FETCH_DEPARTMENTS_SUCCESS;
  departments: Array<DepartmentModel>;
}

export interface ReorderDepartmentType {
  type: typeof REORDER_DEPARTMENT;
  deptId: number;
  rank: number;
}

export interface DepartmentReducerType {
  departments: Array<DepartmentModel>;
  department: DepartmentModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type DepartmentActionTypes =
  | typeOnlyType
  | failureType
  | ReorderDepartmentType
  | FetchDepartmentSuccessType
  | FetchDepartmentsSuccessType;

export const DEPARTMENT_INIT_STATE: DepartmentReducerType = {
  departments: [],
  department: null,
  isFinished: false,
  refresh: true,
};
