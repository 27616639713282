import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { mobileStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
    .MuiTableRow-head {
    }
    .MuiTableCell-root {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: inherit;
    }
  }
  .MuiTableRow-root.overdue {
    background-color: #ffe3e3;
  }
  .MuiTable-root {
    .MuiSelect-select {
      padding-top: ${SPACE.MEDIUM};
      padding-bottom: ${SPACE.MEDIUM};
      font-size: ${FONT.SMALL};
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .MuiTableRow-head {
    .MuiTableCell-head {
      background-color: #eee;
    }
  }
  .content-wrapper {
    padding: ${SPACE.XLARGE};
  }
  ${mobileStyle(`
    .content-wrapper {
      padding: ${SPACE.MEDIUM};
    }
  `)}
  .qty {
    width: 60px;
    text-align: center;
    border-radius: 4px;
    border-width: 1px;
  }
  .notes-label {
    font-weight: ${WEIGHT.SEMI_BOLD};
    margin: ${SPACE.LARGE} 0 ${SPACE.MEDIUM} ${SPACE.SMALL};
  }
  textarea {
    resize: none;
    width: 100%;
    height: 120px;
    font-size: ${FONT.MEDIUM};
    border-width: 0px;
    border-radius: 4px;
    padding: ${SPACE.LARGE};
  }
  textarea.close {
    background-color: initial;
    border-width: 1px;
    border-color: #ccc;
  }
  .MuiOutlinedInput-root textarea {
    padding: 0;
  }

  .info-section {
    margin: ${SPACE.LARGE} 0;
    .label {
      background-color: #eee;
      min-height: 44px;
      display: flex;
      align-items: center;
      font-weight: ${WEIGHT.SEMI_BOLD};
      padding: ${SPACE.MEDIUM};
    }
    .value {
      padding: ${SPACE.MEDIUM};
    }
  }
`;
