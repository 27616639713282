import { TAX_UNIT_TYPE } from '../enums/options/taxUnit';

export default interface TaxModel {
  id: number;
  name: string;
  taxType: string;
  amount?: number;
  unit?: TAX_UNIT_TYPE;
}

const deriveTaxType = taxType => {
  if (!taxType) return 'SALES_TAX';
  return taxType;
};

export const deriveRawToTax = raw => {
  return {
    id: raw.id,
    name: raw.name,
    taxType: deriveTaxType(raw.taxType),
    amount: raw.amount,
    unit: raw.flatFee || 'PERCENT',
  };
};

export const getTaxOptions = taxes => {
  return [
    {
      labelText: 'No Tax - 0%',
      value: '',
    },
    ...taxes.map(t => ({
      labelText: t.name,
      value: t.id,
    })),
  ];
};

export const initialTax = {
  id: 0,
  name: '',
  taxType: '',
  //amount: 0,
  //unit: 'PERCENT',
};
