import React, { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

import { Desktop, Div, Mobile, SPACE } from '$gstyles';

import { Modal } from '$gcomponents/reusables';
import intl from '$intl';
import { Button } from '@mui/material';
import { FilterWrapper } from './styles';

interface FilterSectionProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
}

const FilterSection: React.FC<FilterSectionProps> = ({ title, children }) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const childrenCount = React.Children.toArray(children).length;

  const filterButton = childrenCount ? (
    <Mobile className="flex">
      <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() => setShowFilterModal(true)}>
        {intl('BUTTON.FILTER')}
      </Button>
    </Mobile>
  ) : null;

  const titleComponent =
    typeof title === 'string' || childrenCount ? (
      <div className="title-section">
        {typeof title === 'string' && <div className="screen-title">{title}</div>}
        {filterButton}
      </div>
    ) : (
      title || null
    );

  const el = childrenCount !== 1 ? null : (children as React.ReactElement);
  const cloned = el
    ? React.cloneElement(
        el,
        {
          ...el.props,
          ...(el.props.onSearch && {
            onSearch: (e) => {
              el.props.onSearch(e);
              setShowFilterModal(false);
            },
          }),
        },
        null,
      )
    : null;

  return (
    <FilterWrapper>
      {titleComponent}
      <Desktop>
        <div className="filter-section">{cloned}</div>
      </Desktop>
      <Modal show={showFilterModal} onClose={() => setShowFilterModal(false)} title="TABLE.FILTER.TITLE">
        <Div padding={SPACE.LARGE}>{cloned}</Div>
      </Modal>
    </FilterWrapper>
  );
};

export default FilterSection;
