import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$gbusiness/helpers';
import { AppModel } from '$business/redux';
import Footer from '$gcomponents/widgets/footer';
import CreditModel from '$fbusiness/models/credit';
import { storeActions } from '$fbusiness/redux/store';
import { datetimeToStringUtc } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import StoreModel from '$fbusiness/models/store';

import { CREDIT_FORM } from './creditForm';

interface CreditDetailsProps {
  onClose: Function;
  onSaved: Function;
  store: StoreModel;
  credit: CreditModel;
}

const CreditDetails: React.FC<CreditDetailsProps> = ({ credit, store, onClose, onSaved }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.store.isFinished);

  useEffect(() => {
    if (!isFinished) return;
    dispatch(storeActions.resetFinished());
    onSaved();
  }, [isFinished, onSaved, dispatch]);

  const onSubmit = async (values) => {
    const param = {
      ...values,
      creditDate: datetimeToStringUtc(values.creditDate, DATE_FORMATS.DATE),
      storeId: store.id,
      balance: store.credits,
    };
    await dispatch(storeActions.createCredit(param));
  };

  const validateForm = (values) => input.validateError(CREDIT_FORM, values);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...credit,
        creditDate: new Date(),
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={CREDIT_FORM} formik={formik} />
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="center">
            <Button
              className="half"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default CreditDetails;
