import { KEYS } from '../enums/options/refundStatus';
import OrderInvoice, { deriveRawToOrderInvoice } from './orderInvoice';
import { deriveRawToRefundDetails } from './refundDetails';

export default interface RefundModel extends OrderInvoice {
  qty: number;
  note: string;
  balance: number;
  paidAmount: number;
  requestDate: string;
  items: Array<any>;
}

const deriveStatus = (raw) => {
  if (raw.confirmedAt) return KEYS.CANCELLED;
  if (raw.refundedAt) return KEYS.PROCESSED;
  return KEYS.PREPARING;
};

export const deriveRawToRefund = (raw) => {
  if (!raw) return null;

  return {
    ...deriveRawToOrderInvoice(raw),
    status: deriveStatus(raw),
    note: raw.note,
    requestDate: raw.requestDate,
    balance: raw.balance || 0,
    paidAmount: raw.paidAmount || 0,
    items: (raw.items || []).map((i) => deriveRawToRefundDetails(i)),
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 55,
//   list: [
//     '{{repeat(5, 20)}}',
//     {
//       id: "{{index() + 1}}",
//       orderNumber: "{{integer(10000, 99999)}}",
//       storeId: 1,
//       storeName: "Cloudhood Bakery",
//       factoryId: 1,
//       transactionId: 0,
//       orderTypeId: 1,
//       status: "{{random('SHIPPED', 'PREPARING')}}",
//       subtotal: "{{floating(100, 5100, 2)}}",
//       tax: function (t) { return Number((this.subtotal * 0.6).toFixed(2)); },
//       total: function (t) { return Number((this.subtotal + this.tax).toFixed(2)); },
//       qty: "{{integer(4, 200)}}",
//       qtySent: function (t) { return this.qty - t.integer(0, 2); },
//       qtyReceived: function (t) { return this.qtySent - t.integer(0, 2); },
//       discount: 0,
//       note: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       userName: "{{firstName() + ' ' + surname()}}",
//       paymentType: "{{random('CREDIT_CARD', 'ACH')}}",
//       shipping: "{{random('OVERNIGHT', 'STANDARD')}}",
//       deliveryAddress: "{{integer(100, 9999)}} {{street()}}, {{city()}}, {{state()}} {{integer(10000, 99999)}}",
//       createdAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       updatedAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
