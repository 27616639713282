import CategoryModel from '../../models/category';
import TreeModel from '../../models/tree';

export const CATEGORY_FAILURE = 'CATEGORY_FAILURE';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const CREATE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const TOGGLE_EXPAND = 'CATEGORY_TOGGLE_EXPAND';
export const TOGGLE_PROP = 'CATEGORY_TOGGLE_PROP';
export const REORDER_CATEGORY = 'REORDER_CATEGORY';
export const CLEAN_CATEGORY = 'CLEAN_CATEGORY';

// Action Types
export interface typeOnlyType {
  type:
    | typeof CREATE_CATEGORY_SUCCESS
    | typeof UPDATE_CATEGORY_SUCCESS
    | typeof CLEAN_CATEGORY
    | typeof DELETE_CATEGORY_SUCCESS;
}

export interface failureType {
  type: typeof CATEGORY_FAILURE;
  err: string;
}

export interface FetchCategorySuccessType {
  type: typeof FETCH_CATEGORY_SUCCESS;
  category: CategoryModel;
}

export interface FetchCategoriesSuccessType {
  type: typeof FETCH_CATEGORIES_SUCCESS;
  categories: Array<CategoryModel>;
}

export interface ReorderDepartmentType {
  type: typeof REORDER_CATEGORY;
  categoryId: number;
  rank: number;
}
export interface TogglePropType {
  type: typeof TOGGLE_PROP;
  id: number;
  value: any;
}
export interface ToggleExapndType {
  type: typeof TOGGLE_EXPAND;
  categoryId: number;
}

export interface categoryReducerType {
  categories: Array<CategoryModel>;
  category: CategoryModel | null;
  tree: TreeModel;
  flat: Array<CategoryModel>;
  breadcrumb?: Array<any>;
  expands: any;
  isFinished: boolean;
  refresh: boolean;
}

export type categoryActionTypes =
  | typeOnlyType
  | failureType
  | FetchCategorySuccessType
  | ReorderDepartmentType
  | ToggleExapndType
  | FetchCategoriesSuccessType;

export const CATEGORY_INIT_STATE: categoryReducerType = {
  categories: [],
  category: null,
  expands: {},
  flat: [],
  tree: {
    rootId: 0,
    items: [],
  },
  isFinished: false,
  refresh: true,
};
