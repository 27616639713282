import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';

import { INIT_SUCCESS } from './types';
import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser, deriveRawToUser, LoginModel } from '$gbusiness/models/user';
// import { handleApiFail } from '$gbusiness/services/api';

import { AppModel } from '../';
import { FETCH_ITEMS_SUCCESS } from '$fbusiness/redux/item/types';
import { deriveRawToItem } from '$fbusiness/models/item';
import { defaultCart } from '$fbusiness/models/cart';
import { deriveRawToDepartment } from '$fbusiness/models/department';
import { FETCH_DEPARTMENTS_SUCCESS } from '$fbusiness/redux/department/types';
import { FETCH_MY_FACTORY_SUCCESS, FETCH_STORE_SUCCESS } from '$fbusiness/redux/factory/types';
import { deriveRawToStore } from '$fbusiness/models/store';
import { deriveRawToFactory, initialFactory } from '$fbusiness/models/factory';
import { FETCH_CATEGORIES_SUCCESS } from '$fbusiness/redux/category/types';
import { deriveRawToCategory } from '$fbusiness/models/category';
import { factoryActions } from '$fbusiness/redux/factory';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import { categoryActions } from '$fbusiness/redux/category';
import { AuthActionTypes, LOGGING_IN, LOGIN_SUCCESS } from '$gbusiness/redux/auth/types';
import { handleFail, storeAuth } from '$gbusiness/redux/auth/actions';
import { LOAD_CARDS_SUCCESS } from '$gbusiness/redux/card/types';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();
  const cart = storageData.cart ? JSON.parse(storageData.cart) : defaultCart;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      cart: {
        ...cart,
        pointerIndex: -1,
      },
    },
  });
}

async function updateCart(dispatch, localStorage, items) {
  //const cart = syncCart(localStorage.cart, items);

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...localStorage,
      //cart,
    },
  });
}

async function authToken(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.token,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user?.id) {
    localStorage.clearAuth();
    return;
  }

  const user: UserModel = deriveRawToUser(response.user) || defaultUser;

  dispatch({
    type: SET_CUSER,
    user,
  });
}

async function getFactory(dispatch) {
  const publicFactoryId = getPublicFactoryId();
  await dispatch(factoryActions.fetchFactory(publicFactoryId, true));
  await dispatch(categoryActions.fetchCategories(publicFactoryId));
}

const loadStoreDetails = async (dispatch, { categories, departments, items, store, factory }) => {
  const myFactory = deriveRawToFactory(factory) || initialFactory;

  dispatch({
    type: FETCH_STORE_SUCCESS,
    store: deriveRawToStore(store),
  });
  dispatch({
    type: FETCH_MY_FACTORY_SUCCESS,
    factory: myFactory,
  });
  dispatch({
    type: FETCH_DEPARTMENTS_SUCCESS,
    departments: (departments || []).map(deriveRawToDepartment),
  });
  dispatch({
    type: FETCH_CATEGORIES_SUCCESS,
    categories: (categories || []).map(deriveRawToCategory),
  });
  if (factory.settings.productSize <= 300) {
    dispatch({
      type: FETCH_ITEMS_SUCCESS,
      items: (items || []).map(deriveRawToItem),
    });
  }

  /* get features */
  for (const key in myFactory.settings) {
    const feature = myFactory.settings[key];
    if (typeof feature === 'boolean' && feature) {
      document.body.classList.add('body_' + key || '');
    }
  }
};

async function getMyStore(dispatch, factory) {
  if (factory.items) return;

  const response = await apiService.fetchApi({
    url: configs.api.mystore,
    param: {},
    method: 'GET',
    factoryId: 2,
    // mockData,
  });

  if (!response || !response.data) {
    await getFactory(dispatch);
    // handleApiFail(dispatch, INIT_FAILURE, response, 'ERROR.SERVER');
    return;
  }

  loadStoreDetails(dispatch, response.data);
}

export function fetchMyStore(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    const { factory } = getState();
    await getMyStore(dispatch, factory);
  };
}

export function loadApp(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    await loadStorage(dispatch);

    // Load Theme
    const { localStorage, factory } = getState();

    restoreTheme(localStorage.theme);

    await authToken(dispatch);
    await getMyStore(dispatch, factory);

    const { item } = getState();

    await updateCart(dispatch, localStorage, item.items);

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}

export function login(param: LoginModel): any {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await apiService.fetchApi({
      url: configs.api.login,
      param: {
        ...param,
        factoryId: getPublicFactoryId(),
      },
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.user || !response.accessToken) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    loadStoreDetails(dispatch, response.data);

    dispatch({
      type: LOGIN_SUCCESS,
      accessToken: response.accessToken,
      user,
    });

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.cards,
    });
  };
}
