import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, H2, SPACE, WEIGHT } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import OrderModel from '$fbusiness/models/order';
import Footer from '$gcomponents/widgets/footer';

import { ModalWrapper } from './styles';

import FactoryModel from '$fbusiness/models/factory';
import OrderDetails from '$fcomponents/orderDetails';
import CurrentStateModel from '$fbusiness/models/currentState';

interface OrderDetailsModalProps {
  currentState: CurrentStateModel;
  show: boolean;
  order: OrderModel;
  factory: FactoryModel;
  onClose: () => void;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({
  currentState,
  show,
  factory,
  order,
  onClose,
}) => {
  const onPrint = () => window.print();

  const renderOrder = (order) => {
    return (
      <>
        <IonContent>
          <Div padding={SPACE.XLARGE}>
            <H2 padding={`${SPACE.LARGE} 0 ${SPACE.MEDIUM} 0`} fontWeight={WEIGHT.BOLD}>
              <div>{intl('SCREEN.ORDER.TITLE', { orderId: order?.orderId })}</div>
            </H2>
            <OrderDetails currentState={currentState} factory={factory} order={order} />
          </Div>
        </IonContent>
        <Footer justifyContent="space-around" className="no-print">
          <Button className="third" variant="text" onClick={onClose}>
            {intl('BUTTON.CLOSE')}
          </Button>
          {currentState.isFactory && (
            <Button className="third" onClick={onPrint}>
              {intl('BUTTON.PRINT')}
            </Button>
          )}
        </Footer>
      </>
    );
  };

  return (
    <ModalWrapper show={show} width="720px" onClose={() => onClose()} useCustom>
      {order && renderOrder(order)}
    </ModalWrapper>
  );
};

export default OrderDetailsModal;
