import { Modal } from '$gcomponents/reusables';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }
  .form-group {
    margin: 0 ${SPACE.SMALL};
  }
  .col {
    padding: 8px 8px !important;
  }
  .negative-row {
    .MuiTableCell-root {
      background-color: #ffdddd;
    }
  }
  .balance {
    font-size: ${FONT.MEDIUM};
    .price {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: ${FONT.LARGE};
      color: var(--ion-color-success);
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .MuiTableHead-root {
    border-bottom: 1px solid var(--ion-border-color);
  }
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
`;
