import { Box, SPACE, WEIGHT } from '$gstyles';
import styled from 'styled-components';

export const LegendWrapper = styled(Box.MEDIUM)`
  border: 1px solid ${p => p.borderColor || 'var(--ion-border-color)'};
  background-color: ${p => p.backgroundColor || '#f6f6f6'};
  ${p => p.width && `max-width: ${p.width};`}
  ${p => p.scale && `transform: scale(${p.scale});`}
  text-align: left;
  ${p => (p.align === 'center' ? 'margin: auto;' : 'margin: 0;')}
  font-size: 0.95em;

  .title {
    font-size: 1.2em;
    margin-bottom: ${SPACE.LARGE};
  }
  .legend-item {
    align-items: flex-start;
    margin: ${SPACE.SMALL} 0;
    .label {
      font-weight: ${WEIGHT.SEMI_BOLD};
      flex: 1 0 100px;
    }
    .value {
      flex: 1 0 250px;
    }
  }
`;
