import React from 'react';

import { configs, features } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button, Select } from '@mui/material';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import {
  DELIVERED_OPTIONS,
  DELIVERED_OPTIONS2,
  KEYS as ORDER_STATUS,
} from '$fbusiness/enums/options/orderStatus';

import { deriveRawToOrder } from '$fbusiness/models/order';
import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';
import { generateSelectOptions } from '$gcomponents/utils/input';

export const ORDER_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.order.general,
    deriveToModel: deriveRawToOrder,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
    autoRefreshInterval: 30,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 400,
    maxWidth: 1100,
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: true,
    rowHeight: 44,
    headerHeight: 64,
  },
};

export const generateConfig = (onClickRow) => {
  return {
    ...ORDER_TABLE_CONFIG,
    dataSource: {
      ...ORDER_TABLE_CONFIG.dataSource,
      onClickRow,
    },
  };
};

export const ORDER_TABLE = ({
  onDetails,
  onChangeStatus,
  onReceiving,
  isSalesman,
  settings,
  hidePrice = false,
}): Array<CellModel> => [
  // {
  //   label: 'SCREEN.ORDERS.COLS.STORE',
  //   value: 'storeName',
  //   alwaysShow: true,
  //   sortable: SORT.ASC,
  //   width: 300,
  // },
  {
    label: 'SCREEN.ORDERS.COLS.ID',
    value: 'orderId',
    className: 'main',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.ORDERS.COLS.ORDER_DATE',
    value: 'createdAt',
    component: (row) => <>{format(row.createdAt, DATE_FORMATS.SLASH) || ''}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.ORDERS.COLS.DELIVERY_DATE',
    value: 'createdAt',
    component: (row) => <>{format(row.deliveryDate, DATE_FORMATS.SLASH) || ''}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: !isSalesman,
    label: 'SCREEN.INVOICES.COLS.STORE',
    value: 'storeName',
    className: 'desktop',
    alwaysShow: true,
    width: 300,
  },
  {
    label: 'SCREEN.ORDERS.COLS.STATUS',
    value: 'status',
    className: 'no-label',
    component: (row) => {
      const options =
        settings?.pickupOnly && row.status === ORDER_STATUS.READY
          ? DELIVERED_OPTIONS2
          : row.status === ORDER_STATUS.SHIPPED
          ? DELIVERED_OPTIONS
          : null;
      return options ? (
        <Select
          disabled={!!features.disableStatus}
          variant="outlined"
          value={row.status}
          onChange={(e) => onChangeStatus(row, e.target.value)}>
          {generateSelectOptions(options, row.status)}
        </Select>
      ) : (
        <div>{intl('INPUT.OPTION.ORDER_STATUS.' + row.status)}</div>
      );
    },
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.ORDERS.COLS.ITEMS',
    value: 'qty',
    className: 'center',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    isHidden: hidePrice,
    label: 'SCREEN.ORDERS.COLS.TOTAL',
    value: 'total',
    className: 'center',
    component: (row) => <>{currency(row.total)}</>,
    sortable: SORT.ASC,
    width: 150,
  },
  // {
  //   label: 'SCREEN.ORDERS.COLS.DETAIL',
  //   align: 'center',
  //   className: 'action',
  //   value: '',
  //   component: row => <ReceiptIcon className="pointer" onClick={() => onDetails(row)} />,
  //   width: 150,
  // },
  {
    label: '',
    value: '',
    className: 'action',
    component: (row) => (
      <Button size="small" variant="text" color="primary" onClick={() => onDetails(row)}>
        {intl('SCREEN.ORDERS.COLS.DETAIL')}
      </Button>
    ),
    width: 200,
  },
  {
    label: '',
    value: '',
    className: 'action',
    component: (row) =>
      row.status === ORDER_STATUS.DELIVERED || row.status === ORDER_STATUS.COMPLETED ? (
        <Button size="small" variant="text" color="primary" onClick={() => onReceiving(row)}>
          {intl('SCREEN.ORDERS.COLS.RECEIVING')}
        </Button>
      ) : null,
    width: 200,
  },
];
