import React from 'react';
import { BrandWrapper } from './styles';
import Img from '$gcomponents/primitives/img';

interface BrandProps {
  logo?: string;
}

const Brand: React.FC<BrandProps> = React.memo(
  ({ logo }) => {
    console.log('LOGO', logo);
    return (
      <BrandWrapper className="brand-wrapper">
        <Img className="logo" src={logo || ''} size="contain" position="left" />
      </BrandWrapper>
    );
  },
  (pp, np) => pp.logo === np.logo,
);

export default Brand;
