export const KEYS = {
  OPEN: 'OPEN',
  CANCELLED: 'CANCELLED',
  VOID: 'VOID',
  PAYMENT_SENT: 'PAYMENT_SENT',
  PAID: 'PAID',
};

const convertToOption = (item) => ({
  label: `INVOICE_STATUS.${item}`,
  value: item,
  disabled: item === KEYS.PAID,
});

export const CLOSE_KEYS = [KEYS.OPEN, KEYS.VOID, KEYS.PAYMENT_SENT, KEYS.CANCELLED, KEYS.PAID];
export const CLOSE_OPTIONS = CLOSE_KEYS.map(convertToOption);

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type INVOICE_STATUS = typeof LIST[number] | undefined;
