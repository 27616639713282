export default interface BankModel {
  id: number;
  name: string;
  settings?: any;
}

export const deriveRawToBank = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    settings: {
      ...raw.settings,
    },
  };
};

export const initialBank = {
  id: 0,
  name: '',
};
