import React from 'react';
import {
  IonHeader,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonIcon,
} from '@ionic/react';

import { configs } from '$configs';
import intl from '$gintl';

import { Toolbar } from './styles';
import { arrowBack } from 'ionicons/icons';

interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  rightButton?: React.ReactNode;
  disableMenu?: boolean;
  excludeHeader?: boolean;
  backRoute?: string;
  backText?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
  titleText,
  titleParam = {},
  rightButton,
  disableMenu = false,
  excludeHeader = false,
  backRoute,
  backText = '',
}) => {
  if (configs.display.layout === 'FIXED_HEADER') return null;

  const derivedTitle = intl(title, titleParam) || titleText || '';
  const el = (
    <Toolbar color={configs.theme.headerColor || undefined}>
      <IonButtons slot="start">
        {backRoute ? (
          <>
            <IonButton routerLink={backRoute} routerDirection="back">
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
            {backText}
          </>
        ) : (
          <IonBackButton />
        )}
      </IonButtons>
      {!disableMenu && !backRoute && (
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
      )}
      <IonTitle>{derivedTitle}</IonTitle>
      <IonButtons slot="end">{rightButton && rightButton}</IonButtons>
    </Toolbar>
  );

  return excludeHeader ? <>{el}</> : <IonHeader>{el}</IonHeader>;
};

export default Header;
