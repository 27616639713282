import ModifierModel, { deriveRawToModifier } from './modifier';

export default interface ModifierGroupModel {
  id: number;
  itemId: number;
  itemName?: string;
  name: string;
  multiple: boolean;
  minQuantity: number;
  maxQuantity: number;
  freeQuantity: number;
  displayOrder?: number;
  setting?: any;
  deleted?: boolean;
  updated?: boolean;
  createdAt?: string;
  updatedAt?: string;
  modifiers: Array<ModifierModel>;
}

export const deriveRawToModifierGroup = raw => {
  return {
    id: raw.id,
    itemId: raw.itemId,
    itemName: raw.itemName,
    name: raw.name,
    multiple: raw.multiple,
    minQuantity: raw.minQuantity,
    maxQuantity: raw.maxQuantity,
    freeQuantity: raw.freeQuantity,
    displayOrder: raw.displayOrder,
    // createdAt: toLocal(raw.createdAt),
    // updatedAt: toLocal(raw.updatedAt),
    modifiers: (raw.modifiers || []).map(m => deriveRawToModifier(m)),
  };
};

export const initialModifierGroup = {
  id: 0,
  itemId: 0,
  name: '',
  multiple: true,
  minQuantity: 0,
  maxQuantity: 0,
  freeQuantity: 0,
  displayOrder: 1,
  modifiers: [],
};
