import React from 'react';
import FactoryModel from '$fbusiness/models/factory';

import { ModalWrapper } from './styles';
import InvoiceModel from '$fbusiness/models/invoice';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoiceDetails from './invoiceDetails';

interface InvoiceDetailsModalProps {
  show: boolean;
  invoice?: InvoiceModel;
  currentState: CurrentStateModel;
  factory: FactoryModel;
  onClose: Function;
}

const InvoiceDetailsModal: React.FC<InvoiceDetailsModalProps> = ({
  currentState,
  show,
  factory,
  invoice,
  onClose,
}) => {
  return (
    <ModalWrapper show={show} width="800px" onClose={() => onClose()} useCustom>
      {invoice && (
        <InvoiceDetails currentState={currentState} invoice={invoice} factory={factory} onClose={onClose} />
      )}
    </ModalWrapper>
  );
};

export default InvoiceDetailsModal;
