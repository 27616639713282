import React from 'react';
import { IonButton, IonContent, IonFooter } from '@ionic/react';
import { Formik, Form } from 'formik';

import { Text } from '$gintl';
import userModel from '$gbusiness/models/user';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$gbusiness/helpers';
import { Wrapper } from '$gstyles';

import { USER_FORM } from './userForm';
import Legend from '$gcomponents/utils/legend';

interface FormViewProps {
  user: userModel;
  isMe: boolean;
  onSubmit: Function;
}

const FormView: React.FC<FormViewProps> = ({ user, isMe, onSubmit }) => {
  if (!user) return null;

  const userForm = USER_FORM(isMe);

  const initialValues = input.deriveFormInitialValues(userForm, user);

  const validateForm = (values) => {
    return input.validateError(userForm, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(user?.userId, values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <Wrapper maxWidth="800px">
              <Form>
                <FormSection FORM={userForm} formik={formik} />
              </Form>
              <Legend
                title="INPUT.LEGEND.USER_LEVELS"
                width="440px"
                scale={1}
                legends={['ADMIN', 'MANAGER', 'EMPLOYEE']}
              />
            </Wrapper>
          </IonContent>
          <IonFooter>
            <IonButton
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={!formik.isValid || !formik.dirty}
              expand="block">
              <Text k="SCREEN.USER.SAVE_BUTTON" />
            </IonButton>
          </IonFooter>
        </>
      )}
    </Formik>
  );
};

export default FormView;
