import { SPACE, WEIGHT } from '$gstyles';
import styled from 'styled-components';

export const Table2Wrapper = styled.div`
  padding: ${SPACE.LARGE};
  height: 100%;
  overflow-y: auto;

  .main-table-wrapper {
    position: relative;
    ${(p) => p.maxWidth && `max-width: ${p.maxWidth}px;`};
  }
  .col-settings {
    position: absolute;
    top: -26px;
    right: 0;
    opacity: 0.7;
  }

  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .screen-title {
      padding: ${SPACE.MEDIUM} 0;
      font-size: 24px;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .flex {
      align-items: center;
    }
  }
`;

export const FilterWrapper = styled.div`
  margin-bottom: ${SPACE.LARGE};
`;
